import React, { useContext, useEffect, useState } from "react";
import Layout from "../../shared/components/Layout";
import API from "../../shared/API";
import { Button, Dialog, Divider, Input } from "antd-mobile";
import { isMobile } from "../../shared/SharedUtils";
import {
  fetchStored,
  removeStored,
  setStored,
} from "../../shared/AsyncStorage";
import { useAuth } from "../../shared/providers/AuthProvider";
import { toast } from "../../shared/SharedUtils";
import Select2 from "../../shared/components/Select2";
import { Categories } from "../../shared/SharedTypes";
import Stat from "../../shared/components/Stat";
import { Loading } from "../../shared/components/Loading";

type statTimeframe = "weekly" | "monthly" | "yearly";

interface Stats {
  total_jobs: number;
  completed_jobs: number;
  cancelled_jobs: number;
  avg_response: number;
  completion_rate: number;
  cancellation_rate: number;
}

const Settings: React.FC = (props: any) => {
  const [password, setPassword] = useState<{
    current: string;
    a: string;
    b: string;
  }>({
    current: "",
    a: "",
    b: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const { session } = useAuth();
  const [defaultCategory, setDefaultCategory] = useState<number>(0);

  const [statTimeFrame, setStatTimeFrame] = useState<statTimeframe>("weekly");
  const [statsLoading, setStatsLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<Stats>({} as Stats);

  const [debug, setDebug] = useState<boolean>(false);
  const [debugData, setDebugData] = useState<{ [key: string]: any }>({});

  const { logout } = useAuth();

  useEffect(() => {
    fetchStored("defaultCategory").then((res) => {
      setDefaultCategory(res || 0);
    });

    loadStats();
  }, []);

  const loadStats = (timeframe?: string) => {
    setStatsLoading(true);
    let api = API.getInstance();
    let ts = timeframe || statTimeFrame;
    api
      .fetchMyStats(ts)
      .then((res) => {
        if (res.status === 200) {
          setStats(res.data);
        }
      })
      .catch((err: Error) => {
        toast("Error loading stats", { type: "error" }, err);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  };

  const savePassword = () => {
    let api = API.getInstance();
    setLoading(true);
    api
      .changePassword(password.current, password.a)
      .then((res) => {
        if (res.status === 200) {
          setPassword({ current: "", a: "", b: "" });
          Dialog.alert({
            title: "Success",
            content: "Password changed successfully",
            confirmText: "OK",
            onConfirm: () => {
              removeStored("savedSession").then(() => {
                window.location.href = "/";
              });
            },
            onClose: () => {
              removeStored("savedSession").then(() => {
                window.location.href = "/";
              });
            },
          });
        }
      })
      .catch((err) => {
        toast("Error changing password", { type: "error" }, err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveLayoutSettings = () => {
    setLoading(true);
    setStored("defaultCategory", defaultCategory).then(() => {
      toast("Settings saved", { type: "success" });
      setLoading(false);
    });
  };

  const deleteAccount = () => {
    Dialog.confirm({
      title: "Are you sure?",
      content: "This action is irreversible",
      confirmText: "Yes, delete my account",
      cancelText: "No, keep my account",
      onConfirm: () => {
        let api = API.getInstance();
        api
          .deleteAccount()
          .then((res) => {
            if (res.status === 200) {
              logout();
            }
          })
          .catch((err) => {
            toast("Error deleting account", { type: "error" }, err);
          });
      },
    });
  };
  const categories = [
    { label: "Any", value: -1 },
    ...Categories.map((d, index) => {
      return { label: d, value: index };
    }),
  ];
  return (
    <Layout title="Settings">
      <Divider contentPosition={isMobile() ? "center" : "left"}>
        Personal Stats
      </Divider>
      <div>
        <div className="z-tabs">
          <div className="z-tabs-wrapper">
            <ul>
              <li
                onClick={() => {
                  setStatTimeFrame("weekly");
                  loadStats("weekly");
                }}
                className={statTimeFrame === "weekly" ? "active" : ""}
              >
                Weekly
              </li>
              <li
                onClick={() => {
                  setStatTimeFrame("monthly");
                  loadStats("monthly");
                }}
                className={statTimeFrame === "monthly" ? "active" : ""}
              >
                Monthly
              </li>
              <li
                onClick={() => {
                  setStatTimeFrame("yearly");
                  loadStats("yearly");
                }}
                className={statTimeFrame === "yearly" ? "active" : ""}
              >
                Yearly
              </li>
            </ul>
            {statsLoading ? (
              <Loading loading={statsLoading} />
            ) : (
              <div style={{ border: "1px solid #3d3d3d" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile() ? "column" : "row",
                    paddingTop: "15px",
                    gap: "15px",
                  }}
                >
                  <Stat
                    value={stats.total_jobs}
                    color={"primary"}
                    label={"Total Jobs"}
                    description={`Total amount of jobs assigned to you in the last ${statTimeFrame.replace(
                      "ly",
                      "",
                    )}`}
                    style={{ flex: 1 }}
                  />
                  <Stat
                    value={stats.completed_jobs}
                    color={"success"}
                    label="Completed Jobs"
                    description={`Total amount of jobs you have completed in the last ${statTimeFrame.replace(
                      "ly",
                      "",
                    )}`}
                    style={{ flex: 1 }}
                  />
                  <Stat
                    value={`${Math.ceil(stats.completion_rate)}%`}
                    color={"info"}
                    label="Completion Rate"
                    description={`Percentage of jobs you have completed in the last ${statTimeFrame.replace(
                      "ly",
                      "",
                    )}`}
                    style={{ flex: 1 }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile() ? "column" : "row",

                    paddingTop: "15px",
                    gap: "15px",
                  }}
                >
                  <Stat
                    value={`${stats.avg_response} min`}
                    color={"primary"}
                    label={"Avg Response Time"}
                    description={`Average response time in the last ${statTimeFrame.replace(
                      "ly",
                      "",
                    )}`}
                    style={{ flex: 1 }}
                  />
                  <Stat
                    value={stats.cancelled_jobs}
                    color={"warning"}
                    label="Cancelled Jobs"
                    description={`Total amount of jobs assigned to you that were canceled in the last ${statTimeFrame.replace(
                      "ly",
                      "",
                    )}`}
                    style={{ flex: 1 }}
                  />
                  <Stat
                    value={`${Math.floor(stats.cancellation_rate)}%`}
                    color={"danger"}
                    label="Cancellation Rate"
                    description={`Percentage of jobs assigned to you and canceled in the last ${statTimeFrame.replace(
                      "ly",
                      "",
                    )}`}
                    style={{ flex: 1 }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Divider contentPosition={isMobile() ? "center" : "left"}>
        User Settings
      </Divider>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginBottom: "15px",
        }}
        className={"form"}
      >
        <Input
          placeholder="Current Password"
          value={password.current}
          type="password"
          onChange={(e) => {
            setPassword({ ...password, current: e });
          }}
        />

        <Input
          placeholder="New Password"
          value={password.a}
          type="password"
          onChange={(e) => {
            setPassword({ ...password, a: e });
          }}
        />

        <Input
          placeholder="Repeat New Password"
          value={password.b}
          type="password"
          onChange={(e) => {
            setPassword({ ...password, b: e });
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile() ? "column" : "row",
          gap: "10px",
        }}
      >
        <Button
          block
          color="primary"
          loading={loading}
          onClick={() => {
            savePassword();
          }}
        >
          Change Password
        </Button>
      </div>
      <Divider contentPosition={isMobile() ? "center" : "left"}>
        Layout Settings
      </Divider>
      <p>Default Job Listing Category</p>
      <Select2
        options={categories}
        onChange={(e: any) => {
          setDefaultCategory(e as number);
        }}
      ></Select2>
      <Button
        block
        color="primary"
        loading={loading}
        onClick={() => {
          saveLayoutSettings();
        }}
      >
        Save
      </Button>

      <Divider contentPosition="left">Delete Account</Divider>
      <Button block onClick={deleteAccount} color="danger">
        Delete Account
      </Button>

      <Divider contentPosition="left">Debug Data</Divider>
      <Button
        block
        color="primary"
        onClick={() => {
          fetchStored("session").then((res) => {
            setDebugData({ sessionInfo: res, userInSession: session });
            setDebug(true);
          });
        }}
      >
        Reveal
      </Button>
      {debug && (
        <textarea
          style={{
            width: "99.5%",
            color: "gray",
            height: "100px",
            marginTop: "15px",
          }}
          value={JSON.stringify(debugData)}
        ></textarea>
      )}
    </Layout>
  );
};

export default Settings;
