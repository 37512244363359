import { NavBar } from "antd-mobile";
import { LeftOutlined, MenuOutlined, NumberOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isMobile } from "../SharedUtils";
import Company from "../../Company";
import { Menu2 } from "./Menu2";

interface LayoutProps {
  children: any;
  title: string;
  drawer?: any;
}

const Layout = (props: LayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const navigate = useNavigate();

  const [title, setTitle] = useState(props.title);
  const [children, setChildren] = useState(props.children);

  useEffect(() => {
    setTitle(props.title);
    setChildren(props.children);
  }, [props.title, props.children]);

  return (
    <div className={isMobile() ? "is-mobile" : "is-web"}>
      {isMobile() && (
        <div
          style={{ height: "50px", background: "var(--boxBackgroundColor)" }}
        ></div>
      )}
      <Menu2 onClick={() => {}} onBackClick={() => {}} title={props.title} />
      <div className="main-content">{props.children}</div>
      <div className={"footer"}>
        <p className={"text-align-center"}>
          TowingTrack &mdash; {Company.version}
        </p>
      </div>
    </div>
  );
};

export default Layout;
