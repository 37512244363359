import { RequiredAccountFields, useJobCreateContext } from "../JobCreate";
import Select2 from "../../../../../shared/components/Select2";
import { PhoneMask } from "../../../../../shared/InputMask";
import { Account as IAccount } from "../../../../../shared/Interfaces";
import Button from "antd-mobile/es/components/button";
import { PlusOutlined } from "@ant-design/icons";
import { Dialog, Divider } from "antd-mobile";
import FormAccount from "../../accounts/FormAccount";
import Account from "../../accounts/Account";
import NextOrBackButtons from "./NextOrBack";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../../../shared/SharedUtils";
import { useEffect } from "react";
import { isAccountValid } from "./Validators";

const StepAccount: React.FC = () => {
  const { create, setCreate, account, setAccount } = useJobCreateContext();
  const navigate = useNavigate();

  const goToNextStep = (newAccount: IAccount) => {
    console.log(newAccount);
    console.log(isAccountValid(newAccount));
    if (!isAccountValid(newAccount)) {
      toast(
        "Account information (name and phone number) required, select existing or add new.",
        {
          type: "error",
          autoClose: 5000,
        },
      );
    } else {
      setAccount(newAccount);
      if (account.id === undefined) setAccount({ ...newAccount, id: 0 });
      navigate("/jobs/create/step-unit");
    }
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <div>
        <div className={"form"}>
          <Select2
            async
            asyncSearchType="account"
            label="Search or add new"
            transformInput={(str: string) => {
              return PhoneMask(str);
            }}
            onChange={(option) => {
              if (option) {
                setAccount(option as IAccount);
                setCreate({ ...create, isAccountCreateMode: false });
              }
            }}
            onCreate={(phone) => {
              setAccount({ ...RequiredAccountFields, phone: phone });
              setCreate({ ...create, isAccountCreateMode: true });
            }}
          />
          <span style={{ color: "red" }}>
            (You can only search by phone numbers)
          </span>
          <br />
          <Button
            onClick={() => {
              setCreate({ ...create, isAccountCreateMode: true });
              setAccount({ ...RequiredAccountFields });
            }}
            fill={"none"}
          >
            <PlusOutlined />
            Add New Account
          </Button>

          {create.isAccountCreateMode ? (
            <>
              <Divider />
              <FormAccount
                loading={false}
                account={account}
                onSave={(newAccount) => {
                  goToNextStep(newAccount);
                }}
                onCancel={() => {
                  Dialog.confirm({
                    title: "Cancel",
                    content: "Are you sure you want to cancel?",
                    onConfirm: () => {
                      navigate("/jobs/");
                    },
                    onCancel: () => {},
                    confirmText: "Yes",
                    cancelText: "No",
                  });
                }}
              />
            </>
          ) : (
            <>
              {account.id && account.id !== 0 ? (
                <Account {...account} editable={false} />
              ) : null}
              <NextOrBackButtons
                onCancel={() => {
                  Dialog.confirm({
                    title: "Cancel",
                    content: "Are you sure you want to cancel?",
                    onConfirm: () => {
                      navigate("/jobs/");
                    },
                    onCancel: () => {},
                    confirmText: "Yes",
                    cancelText: "No",
                  });
                }}
                onSubmit={() => {
                  goToNextStep(account);
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default StepAccount;
