import { SignatureObject } from "./components/SignaturePad";
import { MessagePayload } from "firebase/messaging";
import { StatusType, Categories, JobType } from "./SharedTypes";

export interface IEditableWithProps {
  isEdit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  editable?: boolean;
  style?: {};
  onEdit?: () => void;
  onSubmit?: () => void;
  onSelect?: () => void;
  onChange?: () => void;
  onCancel?: () => void;
  onDelete?: (index?: number) => void;
  onClick?: () => void;
  fixedHeight?: number;
}

export interface Account extends IEditableWithProps {
  id: number;
  hid?: string;
  name?: string;
  phone?: string;
  email?: string;
  account_type?: number;
  is_active: boolean;
  address?: string;
  lienholder?: string;
  amends?: Amendment[];
  meta?: string;
  created_at?: string;
  updated_at?: string;
}

export type AccountPatch = Partial<Account>;
export type UnitPatch = Partial<Unit>;
export interface Unit extends IEditableWithProps {
  id: number;
  unit_type: number;
  make: string;
  model: string;
  year: string;
  license: string;
  vin: string | null;
  color: string;
  current_location?: string;
  amends?: Amendment[];
  meta: string;
}
export type PaymentPatch = Partial<Payment>;
export interface Payment extends IEditableWithProps {
  id?: number;
  clover_order_id?: string;
  is_paid?: boolean;
  payment_date?: string;
  payment_type: number;
  total: string;
  paid_by?: number;
  paid_by_data?: Account;
  received_by_data?: User;
  signature?: SignatureObject;
  charges?: Charge[];
  payment_received_by: number | null;
}

export interface Attachment {
  id: number;
  filepath: string;
  file_type: string;
  entity_id: number;
  entity_type?: string;
  created_by: number;
  title?: string;
  created_at?: string;
  updated_at?: string;
  created_by_data?: User;
}

export interface Amendment {
  message?: string;
  user_id?: number | string;
  timestamp?: string;
  action?: string;
}

export type JobPatch = Partial<Job>;
export interface Job extends IEditableWithProps {
  id: number;
  tow_type?: number;
  from_address?: string;
  destination_address?: string;
  unit_id: number;
  account_id: number;
  created_by: number;
  updated_by: number;
  assigned_to: number;
  payment_id: number;
  quoted?: string | number;
  reason?: string | number;
  equipment?: [] | number[];
  requested_by?: string | number;
  public_notes?: string;
  private_notes?: string;
  category: number;
  status?: number;
  status_notes?: string;
  released_to?: string | number;
  reference?: string;
  meta?: string | null;
  amends?: Amendment[];
  created_at?: string;
  updated_at?: string;
  scheduled_at?: string;
  flags?: Flags;
  last_reminder_time: string;
  snooze_until: string;
  send_reminder: boolean;
  metadata?: {
    unit_data?: Unit;
    account_data?: Account;
    user_data?: User;
    payment_data?: Payment;
    payee_data?: Account;
    requested_by_data?: Account;
    released_to_data?: Account;
    updated_by_data?: User;
    created_by_data?: User;
  };
  new?: boolean;
  onCreated?: any;
  onChanged?: any;
  job?: any;
  preventEditing?: boolean;
}
export type FleetUnitPatch = Partial<FleetUnit>;
export interface FleetUnit extends IEditableWithProps {
  id: number;
  unit_type?: number;
  year: string;
  make: string;
  model: string;
  license: string;
  vin: string;
  color: string;
  registration_expires?: string;
  odo: string;
  amends?: Amendment[];
  meta?: string;
}

export interface Option {
  id: number;
  name: string;
  value: string;
  created_at: string;
  updated_at: string;
}

export type UserPatch = Partial<User>;
export interface User extends IEditableWithProps {
  id: number;
  name: string;
  email: string;
  phone: string;
  role: number;
  is_active: boolean;
  password_digest: string;
  meta: string;
  amends?: Amendment[];
  created_at: string;
  update_at: string;
}

export interface AuthPerson {
  index?: number;
  onChanged?: (i: any, entry: any, origin: string) => void;
  onDeleted?: (i: number) => void;
  name: string;
  phone: string;
  email: string;
}

export type ImpoundPatch = Partial<Impound>;
export interface Impound extends IEditableWithProps {
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  addresses: string[];
  authorized: AuthPerson[];
  meta: string;
  amends?: Amendment[];
  created_at: string;
  updated_at: string;
}

export interface Message {
  id: number;
  message: string;
  assigned_to: string | number;
  dismissed_by: string | number;
  expires_in: string | number;
  created_at: string;
  updated_at: string;
}

export interface Charge {
  name: string;
  amount: string;
}

export interface ChargeGroup {
  name: string;
  charges: Charge[];
}

export interface SearchResult {
  entityName: "Account" | "Unit" | "User" | "Job" | "FleetUnit";
  entity: Account | Unit | User | Job | FleetUnit;
  id: number;
}

export enum NotificationType {
  JOB_CREATED = "job_created",
  JOB_UPDATED = "job_updated",
  JOB_CANCELLED = "job_cancelled",
  JOB_SCHEDULE_REMINDER = "job_schedule_reminder",
}

export interface INotification {
  id: number | string;
  event_type: NotificationType;
  title: string;
  body: string;
  data?: any;
}

interface CreateAccount extends Partial<Account> {}

interface CreateUnit extends Partial<Unit> {}

interface CreatePayment extends Partial<Payment> {}

interface CreateJob extends Partial<Job> {}

export interface Resource {
  account: CreateAccount | number;
  unit: CreateUnit | number;
  job: CreateJob;
  payment: CreatePayment;
  assigned_to: number;
}

export interface JobListing {
  id: number;
  account_id: number;
  unit_id: number;
  assigned_to: number;
  status: keyof typeof StatusType;
  category: keyof typeof Categories;
  tow_type: keyof typeof JobType;
  from_address: string | null;
  destination_address: string | null;
  scheduled_at: string | Date;
  account_name: string | null;
  account_phone: string | null;
  unit_year: number | string | null;
  unit_make: string | null;
  unit_model: string | null;
  unit_license: string | null;
  user_name: string | null;
}

export interface JobWithMetadata extends Job {
  requested_by_name?: string;
  released_to_name?: string;
  assigned_to_name?: string;
  created_by_name?: string;
  updated_by_name?: string;
  account_name?: string;
  account_phone?: string;
}

export interface QuarterStats {
  quarter_name: string;
  quarter_start_date: string;
  quarter_end_date: string;
  quarter_total_jobs: number;
  quarter_completed_jobs: number;
  quarter_cancelled_jobs: number;
  quarter_category_stats: {
    [key: string]: number;
  };
  jobs_this_year: number;
  quarter_top_driver: string;
  quarter_top_driver_jobs: number;
  cancellation_rate: string;
  average_completion_time: string;
}

export interface Flag {
  status?: boolean;
  at?: string;
  by_user?: number;
  by_user_name?: string;
  by_account?: number;
  by_account_name?: string;
}

export interface Flags {
  seen?: Flag;
  en_route?: Flag;
  on_scene?: Flag;
  completed?: Flag;
  cancelled?: Flag;
  mailed_a?: Flag;
  response_a?: Flag;
  mailed_b?: Flag;
  response_b?: Flag;
  damage_waiver?: Flag;
  released?: Flag;
  keys?: Flag;
  title?: Flag;
  lien?: Flag;
  ready?: Flag;
  reported?: Flag;
  moved?: Flag;
}
