import {useJobCreateContext} from "../JobCreate";
import {Payment as IPayment} from "../../../../../shared/Interfaces";
import EditPayment from "../../payments/EditPayment";
import {useNavigate} from "react-router-dom";
import {toast} from "../../../../../shared/SharedUtils";
import {useEffect} from "react";
import {isAccountValid, isPaymentValid} from "./Validators";
import FormPayment from "../../payments/FormPayment";

const StepPayment: React.FC = () => {
    const {create, setCreate, payment, setPayment, job, account} = useJobCreateContext();
    const navigate = useNavigate();


    useEffect(() => {
        if (!isAccountValid(account)) navigate('/jobs/create/step-account')
    }, []);


    const goToNextStep = (newPayment: IPayment) => {
        if (!isPaymentValid(newPayment)) {
            toast("Payment information (payment type and amount) required.", {
                type: "error",
                autoClose: 5000
            });
        } else {
            navigate('/jobs/create/step-job');
        }
    }


    return <div style={{marginTop: "15px"}}>
        <div>
            <div className={"form"}>
                <FormPayment
                    account={account}
                    job={job}
                    loading={false}
                    payment={payment}
                    onChange={(payment) => {
                        setPayment(payment);
                    }}
                    onSave={(payment) => {
                        setPayment(payment)
                        goToNextStep(payment);
                    }}
                    onCancel={() => {
                        navigate(-1);
                    }}

                />
            </div>
        </div>
    </div>
}
export default StepPayment;
