import { Account, Charge, Job, Payment } from "../../../../shared/Interfaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import React, { useEffect, useState } from "react";
import { Button, Divider, Input, Switch, Tabs } from "antd-mobile";
import {
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  DollarOutlined,
  PlusOutlined,
  ScissorOutlined,
} from "@ant-design/icons";
import { FallbackPriceGroups } from "../../../../shared/SharedFallbackValues";
import Select2 from "../../../../shared/components/Select2";
import {
  formatCurrency,
  getJobTypeOptionsWithIcons,
  toast,
} from "../../../../shared/SharedUtils";
import { OverlayLoading } from "../../../../shared/components/Loading";
import { AxiosResponse } from "axios";
import API from "../../../../shared/API";
import {
  CALENDAR_DT_INPUT_FORMAT,
  JobType,
  PaymentMethod,
} from "../../../../shared/SharedTypes";
import moment from "moment/moment";
import EntitySelector from "../../../../shared/components/EntitySelector2";
import FormPayment, { toCloverNumber } from "./FormPayment";

const FormatCurrency = (rawInput: string) => {
  const isNegative = rawInput.startsWith("-");
  const numericInput = rawInput.replace(/[^\d]/g, "");

  // Convert to a number and format as cents
  let numberValue = parseInt(numericInput, 10);
  if (!isNaN(numberValue)) {
    numberValue = ((isNegative ? -1 : 1) * numberValue) / 100;
    return numberValue.toFixed(2);
  } else {
    return "";
  }
};

interface ChargeEntryProps {
  index: number;
  name: string;
  amount: string;
  onDelete: () => void;
  onNameChange: (value: string) => void;
  onAmountChange: (value: string) => void;
}

const ChargeEntry: React.FC<ChargeEntryProps> = (props: ChargeEntryProps) => {
  return (
    <tr key={props.index}>
      <td>{props.index + 1}</td>
      <td>
        <Input
          placeholder="Charge Name"
          value={props.name}
          onChange={(value) => {
            props.onNameChange(value);
          }}
        />
      </td>
      <td>
        <Input
          placeholder="Amount"
          value={props.amount}
          onChange={(value) => {
            props.onAmountChange(FormatCurrency(value));
          }}
        />
      </td>
      <td>
        <Button fill="none" color="danger" onClick={props.onDelete}>
          <DeleteOutlined />
        </Button>
      </td>
    </tr>
  );
};

export interface EditPaymentProps {
  loading?: boolean;
  payment?: Payment;
  hideButtons?: boolean;
  onChange?: (payment: Payment) => void;
  onSave?: (payment: Payment) => void;
  onSubmitSuccess?: () => void;
  preventSubmit?: boolean;
  onCancel?: () => void;
}

const EditPayment: React.FC<EditPaymentProps> = (props: EditPaymentProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { session } = useAuth();
  const api = API.getInstance();

  const [loading, setLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<Payment>(state as Payment);
  const [account, setAccount] = useState<Account>(state.account as Account);
  const [job, setJob] = useState<Job>(state.job as Job);

  // Load Payment Info
  useEffect(() => {
    setLoading(true);
    api
      .fetchPaymentInfo(id)
      .then((res: AxiosResponse) => {
        let paymentObject = res.data as Payment;
        paymentObject.charges = paymentObject.charges?.map((charge) => {
          return {
            name: charge.name,
            amount: formatCurrency(String(charge.amount)),
          };
        });

        setPayment(paymentObject);
      })
      .catch((e: Error) => {
        toast("Error fetching payment info", { type: "error" }, e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const savePayment = (payment: Payment) => {
    setLoading(true);
    const cloverAccurateCharges = payment.charges?.map((charge) => {
      return {
        name: charge.name,
        amount: toCloverNumber(charge.amount),
      };
    });

    let paymentObject = { ...payment, charges: cloverAccurateCharges };
    api
      .updatePaymentInfo(payment.id, paymentObject)
      .then(() => {
        toast("Payment Updated", { type: "success" });
        navigate(-1);
      })
      .catch((e) => {
        toast("Error updating payment", { type: "error" }, e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <FormPayment
        job={job}
        payment={payment}
        account={account}
        loading={loading}
        onChange={(payment) => {
          setPayment(payment);
        }}
        onCancel={() => {
          navigate(-1);
        }}
        onSave={savePayment}
      />
      {loading && <OverlayLoading />}
    </div>
  );
};

export default EditPayment;
