import { Button, Dialog, Input, NoticeBar } from "antd-mobile";
import { useState } from "react";

import Company from "../../Company";
import { isMobile } from "../../shared/SharedUtils";
import API from "../../shared/API";
import { PhoneMask } from "../../shared/InputMask";
import { Link } from "react-router-dom";

import Logo from "../../shared/assets/images/logo.png";
const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const signup = () => {
    const api = API.getInstance();

    setError("");

    if (password !== password2) {
      setError("Passwords do not match");
      return;
    }

    if (email.length < 5) {
      setError("Invalid email");
      return;
    }

    if (phone.length < 10) {
      setError("Invalid phone number");
      return;
    }

    if (name.length < 5) {
      setError("Invalid name");
      return;
    }

    const user = {
      email: email,
      name: name,
      phone: phone,
      role: 0,
      is_active: false,
      password: password,
    };

    setLoading(true);

    api
      .signupUser(user)
      .then((response) => {
        if (response.status === 200) {
          Dialog.alert({
            title: "Success",
            content:
              "Signup successful, once your account is reviewed you will receive an e-mail with further details.",
            confirmText: "OK",
            onConfirm: () => {
              window.location.href = "/";
            },
            onClose: () => {
              window.location.href = "/";
            },
          });
        } else {
          setError(response.data.message);
        }
      })
      .catch((e) => {
        setError(e.message);
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      <div className="form" style={{ width: isMobile() ? "90%" : "45%" }}>
        <img
          alt="Logo"
          src={Logo}
          style={{
            width: "150px",
            display: "block",
            margin: "0 auto",
            marginTop: "150px",
            marginBottom: "40px",
          }}
        />
        <p style={{ textAlign: "center" }}>
          Welcome, please use this form to signup.
        </p>
        <Input
          name="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => {
            setEmail(e);
          }}
        />
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e);
          }}
        />
        <Input
          name="phone"
          placeholder="Phone"
          value={phone}
          onChange={(e) => {
            setPhone(PhoneMask(e));
          }}
        />
        <Input
          name="password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => {
            setPassword(e);
          }}
        />
        <Input
          name="email"
          type="password"
          placeholder="Repeat Password"
          value={password2}
          onChange={(e) => {
            setPassword2(e);
          }}
        />
        <Button
          onClick={signup}
          loading={loading}
          style={{ width: "100%" }}
          color="primary"
        >
          Sign Up
        </Button>
        {error && (
          <div style={{ margin: "10px 0" }}>
            <NoticeBar content={error} color="alert" closeable />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "25px",
          }}
        >
          <Link to="/login">Log In</Link>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
