import React, { useEffect, useState } from "react";
import Layout from "../../shared/components/Layout";
import API from "../../shared/API";
import {
  Message as IMessage,
  Job as IJob,
  QuarterStats,
  User,
  Account,
} from "../../shared/Interfaces";
import Message from "../../shared/components/Message";
import { hid, toast } from "../../shared/SharedUtils";
import { OverlayLoading } from "../../shared/components/Loading";
import Stat from "../../shared/components/Stat";
import { isMobile } from "../../shared/SharedUtils";
import { Divider, FloatingBubble, List, Result } from "antd-mobile";
import UrgentAlert from "../../shared/components/UrgentAlert";
import { useNavigate } from "react-router-dom";
import { JobType, SharedLotAddresses } from "../../shared/SharedTypes";
import Form2, {
  Form,
  FormAutoComplete,
  FormEntitySelector,
  FormInput,
  FormSwitch,
} from "../../shared/components/Form2";
import { PhoneMask } from "../../shared/InputMask";
import { useAuth } from "../../shared/providers/AuthProvider";
import { PlusOutlined } from "@ant-design/icons";
import EntitySelector from "../../shared/components/EntitySelector3";

const HomePage: React.FC = (props: any) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [stats, setStats] = useState<QuarterStats>({} as QuarterStats);
  const [urgentJobs, setUrgentJobs] = useState<IJob[]>([]);
  const [outstandingJobs, setOutstandingJobs] = useState<IJob[]>([]);

  const api = API.getInstance();
  const navigate = useNavigate();
  useEffect(() => {
    loadMessages();
    loadStats();
    loadAlerts();
    loadOutstandingJobs();
  }, []);

  const { hasPerms } = useAuth();

  const loadMessages = () => {
    /*setLoading(true);
    const api = API.getInstance();
    api
      .fetchMessages()
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });*/
  };

  const loadAlerts = () => {
    setLoading(true);

    api
      .fetchUrgentJobs()
      .then((res) => {
        setUrgentJobs(res.data);
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadStats = () => {
    setLoading(true);

    api
      .fetchStats()
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        toast(err.message, { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadOutstandingJobs = () => {
    setLoading(true);
    const api = API.getInstance();
    api
      .fetchOutstandingJobs()
      .then((res) => {
        setOutstandingJobs(res.data);
      })
      .catch((err) => {
        toast(err.message, { type: "err" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout title="Home">
      {loading ? (
        <OverlayLoading />
      ) : (
        <>
          {messages.map((message) => {
            return (
              <Message
                key={message.id}
                {...message}
                onDismiss={() => {
                  setMessages(messages.filter((m) => m.id !== message.id));
                }}
              />
            );
          })}
          {urgentJobs.length > 0 && (
            <div>
              <Divider contentPosition={isMobile() ? "center" : "left"}>
                Please update the status for these jobs
              </Divider>
              <div>
                {urgentJobs.map((job) => {
                  return (
                    <UrgentAlert
                      key={job.id}
                      job={job}
                      onRemove={() => {
                        setUrgentJobs(
                          urgentJobs.filter((j) => j.id !== job.id),
                        );
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <Divider contentPosition={isMobile() ? "center" : "left"}>
            {stats.quarter_name} Statistics [{stats.quarter_start_date} -{" "}
            {stats.quarter_end_date}]
          </Divider>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "10px",
            }}
          >
            <div style={{ flex: 1 }}>
              <Stat
                label="Total Jobs"
                value={stats.quarter_total_jobs}
                description="Jobs created this quarter"
                color="info"
              />
            </div>
            <div style={{ flex: 1 }}>
              <Stat
                label="Completed Jobs"
                value={stats.quarter_completed_jobs}
                color="success"
                description="Jobs completed this quarter"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <div style={{ flex: 1 }}>
              <Stat
                label="Cancelled Jobs"
                value={stats.quarter_completed_jobs}
                color="danger"
                description="Jobs cancelled this quarter"
              />
            </div>
            <div style={{ flex: 1 }}>
              <Stat
                label="Total Jobs this year"
                value={stats.jobs_this_year}
                color="warning"
                description="Total jobs this year"
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            {stats.quarter_category_stats &&
              Object.keys(stats.quarter_category_stats).map((key, index) => {
                return (
                  <Stat
                    key={index}
                    label={`Jobs in ${key}`}
                    color="info"
                    value={stats.quarter_category_stats[key]}
                    style={{ flex: 1 }}
                    description={`Total jobs created in ${key}`}
                  ></Stat>
                );
              })}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <Stat
              label={stats.quarter_top_driver}
              value="#1"
              color="success"
              description={`Top driver this quarter with ${stats.quarter_top_driver_jobs} jobs completed`}
              style={{ flex: 1 }}
            />
            <Stat
              label="Cancellation Rate"
              value={stats.cancellation_rate}
              color="danger"
              postfix={"%"}
              description={`Average cancellation rate this quarter`}
              style={{ flex: 1 }}
            />
            <Stat
              label="Average completion time"
              value={stats.average_completion_time}
              color="danger"
              postfix={"min"}
              description={`Average completion time (minutes) for jobs this quarter`}
              style={{ flex: 1 }}
            />
          </div>
          {hasPerms() && (
            <div>
              <Divider contentPosition={isMobile() ? "center" : "left"}>
                {stats.quarter_name} Unpaid Jobs
              </Divider>
              <div>
                {outstandingJobs.length > 0 && (
                  <List>
                    {outstandingJobs.map((job, index) => {
                      return (
                        <List.Item
                          key={index}
                          clickable
                          onClick={() => {
                            navigate(`/jobs/${hid(job.id)}`);
                          }}
                        >
                          {hid(job.id)} - {JobType[Number(job.tow_type)]}
                        </List.Item>
                      );
                    })}
                  </List>
                )}
                {outstandingJobs.length === 0 && (
                  <Result
                    title="No outstanding jobs"
                    description="Congratulations no outstanding jobs this quarter"
                    status="success"
                  />
                )}
              </div>
            </div>
          )}
          <FloatingBubble
            style={{
              "--initial-position-bottom": "24px",
              "--initial-position-right": "24px",
              "--edge-distance": "24px",
            }}
            axis={"xy"}
            magnetic={"x"}
          >
            <PlusOutlined
              onClick={() => {
                navigate("/jobs/quick-create");
              }}
              style={{ fontSize: "32px" }}
            />
          </FloatingBubble>
        </>
      )}
    </Layout>
  );
};

export default HomePage;
