import { isMobile } from "../../shared/SharedUtils";
import {
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import { Input } from "antd-mobile";
import { useSwipeable } from "react-swipeable";

export interface AutoCompleteProps {
  options: string[];
  value?: string;
  onSelect?: (match: string) => void;
  onChange?: (value: string) => void;
  minLength?: number;
  style?: React.CSSProperties;
  hint?: string;
  placeholder?: string;
  inputType?: string;
}

const AutoComplete: ForwardRefRenderFunction<
  HTMLInputElement,
  AutoCompleteProps
> = (
  {
    options,
    value,
    onSelect,
    onChange,
    minLength = 2,
    style,
    hint = isMobile()
      ? "Tap to select highlighted"
      : "Press tab ⭾ to select highlighted",
    placeholder,
    inputType = "text",
  },
  ref,
) => {
  const [filtered, setFiltered] = useState<string[]>([]);
  const [searchString, setSearchString] = useState(value);

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const findOccurrences = (query: string) => {
    if (query.length <= minLength) {
      setFiltered([]);
      return;
    }

    const filteredData = options.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase()),
    );

    setFiltered(filteredData);
  };

  const handleInputSearch = (e: any) => {
    onChange && onChange(e);
    setSearchString(e);
    findOccurrences(e);
  };
  const handleTabKey = (e: KeyboardEvent) => {
    if (e.key !== "Tab") return;

    if (filtered.length > 0) {
      e.preventDefault();
      onSelect && onSelect(filtered[0]);
      setFiltered([]);
    }
  };

  const handleTap = () => {
    if (searchString && searchString.length <= minLength) return;
    onSelect && onSelect(filtered[0]);
    setSearchString("");
    setFiltered([]);
  };

  return (
    <div
      style={style}
      onClick={handleTap}
      onKeyDown={handleTabKey}
      className="autocomplete"
    >
      <div
        className={`autocomplete-input ${
          filtered.length > 0 ? "highlighted" : ""
        }`}
      >
        <Input
          placeholder={placeholder}
          value={searchString}
          onChange={handleInputSearch}
          type={inputType}
        />
      </div>
      {filtered.length > 0 && (
        <div className="autocomplete-list">
          <strong>{searchString && searchString.toUpperCase()}</strong>
          {searchString &&
            filtered[0].substring(searchString.length).toUpperCase()}
          <p>{hint}</p>
        </div>
      )}
    </div>
  );
};

export default forwardRef(AutoComplete);
