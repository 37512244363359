import { API_BASE } from "../API";
import { Attachment } from "../Interfaces";
import { useAuth } from "../providers/AuthProvider";
import React, { useContext, useEffect, useState } from "react";
import {
  CloseOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Button from "antd-mobile/es/components/button";
import { ImageViewer, Loading } from "antd-mobile";
import { SlidesRef } from "antd-mobile/es/components/image-viewer/slides";
import moment from "moment";
import { CALENDAR_FORMAT } from "../SharedTypes";
import DragUpload, { DragFile } from "./DragUpload";

interface GalleryProps {
  attachments: Attachment[];
  job_id: number;
}

interface IAttachment extends Attachment {
  url?: string;
}

interface ImageProps {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
}

const Image = (props: ImageProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <div>
      {loading && (
        <div
          style={{
            border: "1px solid var(--primary)",
            width: "140px",
            height: "140px",
          }}
        >
          <Loading style={{ marginTop: "60px", marginLeft: "45px" }} />
        </div>
      )}
      <img
        src={props.src}
        alt={props.alt}
        width={props.width}
        height={props.height}
        onLoad={() => setLoading(false)}
        style={{ visibility: loading ? "hidden" : "visible" }}
      />
    </div>
  );
};

const Gallery: React.FC<GalleryProps> = (props: GalleryProps) => {
  const { session } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const galleryRef = React.createRef<SlidesRef>();

  const [attachments, setAttachments] = useState<IAttachment[]>(
    props.attachments,
  );
  const [displayAttachments, setDisplayAttachments] =
    useState<IAttachment[]>(attachments);

  useEffect(() => {
    let kek = attachments.map((attachment) => {
      if (attachment.file_type.startsWith("image")) {
        if (attachment.filepath.startsWith("blob")) {
          attachment.url = attachment.filepath;
        } else {
          attachment.url = `${API_BASE}/uploads/${attachment.filepath}?token=${session?.token}`;
        }
        return attachment;
      } else {
        if (attachment.filepath.startsWith("blob")) {
          attachment.url = attachment.filepath;
        } else {
          attachment.url = `${API_BASE}/uploads/${attachment.filepath}?token=${session?.token}`;
        }
        return attachment;
      }
    });

    setDisplayAttachments(kek);
  }, [attachments]);

  const appendFiles = (files: DragFile[]) => {
    let newAttachments = [];
    if (files) {
      for (let i = 0; i < files?.length; i++) {
        newAttachments.push({
          file_type: files[i].file.type,
          title: files[i].title,
          filepath: URL.createObjectURL(files[i].file),
          entity_id: props.job_id,
        } as Attachment);
      }
      setAttachments([...attachments, ...newAttachments]);
    }
  };

  return (
    <div>
      <div id="z-gallery">
        {displayAttachments.length === 0 && (
          <h3 style={{ textAlign: "center", marginTop: "20px" }}>
            No attachments yet.
          </h3>
        )}
        {displayAttachments.map((attachment, index) => {
          return (
            <div
              key={index}
              className="gallery-item"
              onClick={() => {
                if (attachment.file_type.startsWith("image")) {
                  setOpen(true);
                  setSelected(index);
                  if (galleryRef.current) {
                    galleryRef.current.swipeTo(index);
                  }
                }
              }}
            >
              {attachment.file_type.startsWith("image") ? (
                <Image src={attachment.url} alt={attachment.title} />
              ) : (
                <a
                  href={attachment.url}
                  target="_blank"
                  title={attachment.title}
                  rel={"noreferrer"}
                >
                  <FilePdfOutlined />
                </a>
              )}
            </div>
          );
        })}
        <ImageViewer.Multi
          images={displayAttachments.map((attachment) => {
            return attachment.file_type.startsWith("image")
              ? attachment.url || ""
              : "";
          })}
          defaultIndex={selected}
          visible={open}
          onClose={() => setOpen(false)}
          renderFooter={(image: string, index) => {
            if (!attachments[index]) return "";
            return (
              <div className="gallery-footer">
                <p>{attachments[index].title}</p>
                <p>
                  <em>
                    {attachments[index].created_at
                      ? moment(attachments[index].created_at).format(
                          CALENDAR_FORMAT,
                        )
                      : ""}
                  </em>
                </p>
                {attachments[index].created_by_data && (
                  <p>{attachments[index].created_by_data?.name}</p>
                )}
              </div>
            );
          }}
          ref={galleryRef}
        />
      </div>
      <div className={`gallery-form`} style={{ marginTop: "45px" }}>
        <DragUpload job_id={props.job_id} onUpload={appendFiles} />
      </div>
    </div>
  );
};
export default Gallery;
