import { Button, Input, NoticeBar } from "antd-mobile";
import { useContext, useState } from "react";

import Company from "../../Company";
import { useAuth } from "../../shared/providers/AuthProvider";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Logo from "../../shared/assets/images/logo.png";
import { isMobile } from "../../shared/SharedUtils";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let { errorMessage, login } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleLogin = () => {
    setIsLoading(true);
    if (email && email.trim() !== "" && password && password.trim() !== "") {
      login(email, password).then((res) => {
        setIsLoading(false);
      });
    } else {
      toast("Please enter a valid email and password", {
        theme: "dark",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      <div className="form" style={{ width: isMobile() ? "90%" : "45%" }}>
        <div
          style={{
            width: "150px",
            height: "150px",
            display: "block",
            margin: "0 auto",
            marginTop: "150px",
            marginBottom: "40px",
          }}
        >
          <img
            src={Logo}
            style={{
              width: "150px",
            }}
            alt="Logo"
          />
        </div>
        <form>
          <Input
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e);
            }}
            type="email"
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e);
            }}
          />
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleLogin();
            }}
            type="submit"
            loading={isLoading}
            style={{ width: "100%" }}
            color="primary"
          >
            Login
          </Button>
        </form>
        {errorMessage && (
          <div style={{ margin: "10px 0" }}>
            <NoticeBar content={errorMessage} color="alert" closeable />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "25px",
          }}
        >
          <Link to="/sign-up">Sign Up</Link>
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
