const Company = {
  name: "24/7 Alaska Towing LLC",
  main_phone: "907-444-5555",
  address_line_a: "340 E 95 TH CT",
  address_line_b: "ANCHORAGE, AK 99515",
  main_email: "247alaskatowing@gmail.com",
  main_logo_url: "https://247alaskatowing.com/assets/images/logo.png",
  main_logo_alt: "",
  version: "5.0.4",
};

export default Company;
