import React, { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import { Button, Input } from "antd-mobile";
import SignatureCanvas from "react-signature-canvas";
import { isMobile } from "../SharedUtils";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { toast } from "../SharedUtils";

interface SignaturePadProps {
  visible: boolean;
  onClose: () => void;
  signature: SignatureObject | null;
  onSign: (signature: SignatureObject) => void;
  onChange: (signature: SignatureObject) => void;
  clearable?: boolean;
  style?: any;
}

export interface SignatureObject {
  signature: string | null;
  fullName: string;
  date: Date;
}

const SignaturePad: React.FC<SignaturePadProps> = (
  props: SignaturePadProps,
) => {
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const signaturePadRef = useRef<any>(null);
  const [signaturePadWH, setSignaturePadWH] = useState<number[]>(
    isMobile()
      ? [window.outerWidth - 20, window.outerHeight / 2]
      : [window.outerWidth / 2, window.outerHeight / 3],
  );

  useEffect(() => {
    const handleResize = () => {
      setSignaturePadWH(
        isMobile()
          ? [window.outerWidth - 20, window.outerHeight / 2]
          : [window.outerWidth / 2, window.outerHeight / 3],
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal visible={props.visible} onClose={props.onClose}>
      <div className={"form"}>
        <div className="signature-pad-canvas-wrapper">
          <Input
            type="text"
            placeholder="Full Name"
            autoFocus
            style={{ marginBottom: "10px" }}
            value={props.signature?.fullName}
            className={hasErrors ? "withErrors" : ""}
            onChange={(e) => {
              props.onChange({
                ...props.signature,
                fullName: e,
              } as SignatureObject);

              if (e.length > 4) {
                setHasErrors(false);
              } else {
                setHasErrors(true);
              }
            }}
          />
          {hasErrors && (
            <span className={`.error-helper`}>Name is required</span>
          )}
          <SignatureCanvas
            canvasProps={{
              width: signaturePadWH[0] + "px",
              height: signaturePadWH[1] + "px",
            }}
            ref={signaturePadRef}
          />
          <p style={{ textAlign: "center" }}>
            {props.signature?.date
              ? moment(props.signature?.date).format("MM-DD-YYYY")
              : ""}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ flex: 1 }}>
            <Button
              color="primary"
              block
              onClick={() => {
                if (props.signature) {
                  if (props.signature.fullName === "") {
                    setHasErrors(true);
                    toast("Name is required", {
                      type: "error",
                      autoClose: 5000,
                    });
                  } else {
                    let signatureObject = {
                      ...props.signature,
                      signature: signaturePadRef.current.toDataURL(),
                      date: new Date(),
                    };
                    props.onChange(signatureObject);
                    props.onSign(signatureObject);
                    setHasErrors(false);
                  }
                } else {
                  toast("Name and Signature is required", {
                    type: "error",
                    autoClose: 5000,
                  });
                }
              }}
            >
              Sign
            </Button>
          </div>
          {props.clearable && (
            <div style={{ flex: 1 }}>
              <Button
                block
                color="danger"
                onClick={() => {
                  signaturePadRef.current.clear();
                  let signatureObject = {
                    fullName: props.signature?.fullName || "",
                    signature: null,
                    date: new Date(),
                  };
                  props.onChange(signatureObject);
                }}
              >
                Clear Pad <DeleteOutlined />
              </Button>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <Button block onClick={props.onClose}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SignaturePad.defaultProps = {
  clearable: true,
};
export default SignaturePad;
