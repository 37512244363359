import {
  CheckOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  InfoCircleOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";

export let AccountType = [
  "Customer",
  "PPI",
  "Insurance",
  "Shop",
  "Third Party",
  "Local Police",
  "State Police",
];

export let UnitType = [
  "Vehicle",
  "Sedan",
  "Suv",
  "Van",
  "Truck",
  "Box",
  "RV",
  "Trailer",
  "RV Trailer",
  "Equipment",
  "Car Parts",
  "Building",
];

export let FleetUnitType = [
  "Flatbed",
  "Wheel Lift",
  "Sling/Hoist",
  "Heavy Duty",
  "Trailer",
  "Low Boy",
];

export let JobType = [
  "Towing",
  "Accident",
  "Arrest",
  "Unregistered",
  "Tow Zone",
  "Snow Removal",
  "Abandoned",
  "Break Down",
  "Lock Out",
  "Jump Start",
  "Fuel Delivery",
  "Impound",
  "Winch Out Snow",
  "Winch Out Mud",
  "Tire Change",
  "Stolen",
  "Heavy-Duty",
  "Motorcycle",
  "Boat",
  "RV or Camper",
  "Exotic Car",
  "Off-Road Recovery",
  "Repo Service",
];

export let PaymentMethod = ["Cash", "Check", "Card", "AFT", "PO", "Other"];

export let EquipmentType = [
  "None",
  "Single line winching",
  "Dual line winching",
  "Snatch blocks",
  "Tow Dolly",
  "Flares",
  "Oil Pads",
  "Scotch blocks",
  "Go Jacks",
  "Boom Lift",
  "Flatbed",
  "Landoll",
  "Hook & Chains",
  "Traffic Cones",
  "Warning Triangles",
  "Jump Starter",
  "Air Compressor",
  "Lock Out Kit",
  "Tire Wrench",
  "Jack",
];

export let Categories = [
  "Anchorage",
  "Fairbanks",
  "Fai -> Anc",
  "Anc -> Fai",
  "Out of State",
];

export let SearchEntities = [
  "Job",
  "Account",
  "Unit",
  "Impound",
  "Fleet Unit",
  "User",
];

export let StatusType = [
  "Open",
  "In Progress",
  "Cancelled",
  "Closed",
  "Scheduled",
  "Public",
];

export const statusIcon = [
  <ClockCircleOutlined />,
  <InfoCircleOutlined />,
  <WarningOutlined />,
  <CheckOutlined />,
  <HourglassOutlined />,
  <UserOutlined />,
];

export const statusColor = [
  "success",
  "warning",
  "danger",
  "success",
  "info",
  "info",
];

export const CALENDAR_FORMAT = "ddd, MMM DD, YYYY HH:mm";
export const CALENDAR_DB_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const CALENDAR_DT_INPUT_FORMAT = moment.HTML5_FMT.DATETIME_LOCAL;
export const CALENDAR_HUMAN_FORMAT = "ddd, MMM DD, YYYY h:mm A";
export const SharedLotAddresses = [
  "340 E 95 TH CT, ANCHORAGE AK 99515",
  "224 E 54 TH ST, ANCHORAGE AK 99518",
  "1675 E 5th Ave, Anchorage, AK 99501",
  "2771 19th Ave, Fairbanks, AK 99709",
] as string[];

export const COMMON_EMAIL_PROVIDERS = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "msn.com",
  "live.com",
  "me.com",
  "mac.com",
  "comcast.net",
  "att.net",
  "verizon.net",
  "cox.net",
  "optimum.net",
  "charter.net",
  "gci.net",
];

export const StatusMessagesComplete = [
  "No Issues.",
  "Minor Problems Encountered.",
  "Major Problems Encountered.",
  "Delayed but Done.",
  "Delayed due to drop off changes.",
  "Delayed due difficulties at drop off.",
  "Delayed by shop/dealer",
  "Partially Completed.",
];
export const StatusMessageNotComplete = [
  "Payment Issues.",
  "Customer Cancelled.",
  "Technical Difficulties.",
  "Customer did not answer.",
  "Unit not found.",
  "Unit not accessible.",
  "Miscommunication.",
  "Safety Concerns.",
  "Too long of a Delay.",
  "Customer no longer needs assistance.",
  "Another company has already assisted.",
  "Will go through insurance/vendor.",
  "Unit too large or un-towable.",
  "Miscommunication.",
];
