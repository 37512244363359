import React, { useEffect, useState } from "react";
import {
  Account as IAccount,
  Attachment,
  JobWithMetadata,
  Payment as IPayment,
  Unit as IUnit,
} from "../../../../shared/Interfaces";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../../shared/API";
import { LabelledLoading } from "../../../../shared/components/Loading";
import { Button, Result } from "antd-mobile";

import {
  getDisplayLabel,
  getRealId,
  isMobile,
  toast,
} from "../../../../shared/SharedUtils";
import Account from "../accounts/Account";
import Layout from "../../../../shared/components/Layout";
import Unit from "../units/Unit";
import Job from "./Job";
import Payment from "../payments/Payment";
import Details from "./Details";
import Gallery from "../../../../shared/components/Gallery";
import { Documents } from "../documents/Documents";
import {
  DownOutlined,
  EditOutlined,
  SelectOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import Modal from "../../../../shared/components/Modal";
import FormAccount from "../accounts/FormAccount";
import FormUnit from "../units/FormUnit";
import Avatar from "../../../../shared/components/Avatar";

const JobView: React.FC = (props: any) => {
  const { id } = useParams();
  const { state } = useLocation();
  const { session } = useAuth();
  const [job, setJob] = useState<JobWithMetadata>({} as JobWithMetadata);
  const [account, setAccount] = useState<IAccount>({} as IAccount);
  const [unit, setUnit] = useState<IUnit>({} as IUnit);
  const [payment, setPayment] = useState<IPayment>({} as IPayment);
  const [attachments, setAttachments] = useState<Attachment[]>(
    [] as Attachment[],
  );

  const [jobLoading, setJobLoading] = useState<boolean>(true);
  const [accountLoading, setAccountLoading] = useState<boolean>(true);
  const [unitLoading, setUnitLoading] = useState<boolean>(true);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(true);
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(true);
  const [amendsCollapsed, setAmendsCollapsed] = useState<boolean>(true);

  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [createdEntity, setCreatedEntity] = useState<"account" | "unit">(
    "account",
  );

  const [createEntityData, setCreateEntityData] = useState<IAccount | IUnit>(
    {} as IAccount,
  );
  const [createEntityLoading, setCreateEntityLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    const _id = getRealId(id);

    if (state === null) {
      const api = API.getInstance();
      api
        .fetchJob(_id)
        .then((res) => {
          setJob(res.data);
          fetchAllEntities(
            res.data.account_id,
            res.data.unit_id,
            res.data.payment_id,
          );
        })
        .catch((e) => {
          toast("Error fetching job", { type: "error" }, e);
        })
        .finally(() => {
          setJobLoading(false);
        });
    } else {
      setJob(state);
      setJobLoading(false);
      fetchAllEntities(job.account_id, job.unit_id, job.payment_id);
    }
  }, []);

  const fetchAllEntities = (
    account_id: number,
    unit_id: number,
    payment_id: number,
  ) => {
    let api = API.getInstance();
    const _id = getRealId(id);

    api
      .fetchAccount(account_id)
      .then((res) => {
        setAccount(res.data);
      })
      .catch((e) => {
        toast("Error fetching account", { type: "error" }, e);
      })
      .finally(() => {
        setAccountLoading(false);
      });

    api
      .fetchUnit(unit_id)
      .then((res) => {
        setUnit(res.data);
      })
      .catch((e) => {
        toast("Error fetching unit", { type: "error" }, e);
      })
      .finally(() => {
        setUnitLoading(false);
      });

    api
      .fetchPaymentInfo(payment_id)
      .then((res) => {
        setPayment(res.data);
      })
      .catch((e) => {
        toast("Error fetching payment", { type: "error" }, e);
      })
      .finally(() => {
        setPaymentLoading(false);
      });

    api
      .fetchJobAttachments(_id)
      .then((res) => {
        setAttachments(res.data as Attachment[]);
      })
      .catch((e) => {
        toast("Error fetching attachments", { type: "error" }, e);
      })
      .finally(() => {
        setAttachmentsLoading(false);
      });
  };

  const createEntity = (entity: "account" | "unit") => {
    setCreateOpen(!createOpen);
    setCreatedEntity(entity);
  };
  const renderMobileView = () => {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            {jobLoading ? (
              <LabelledLoading label="Job" />
            ) : (
              <Job {...job} onRefresh={(job) => setJob(job)} />
            )}
          </div>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            {payment.id ? (
              paymentLoading ? (
                <LabelledLoading label="Payment" />
              ) : (
                <Payment {...payment} job={job} account={account} unit={unit} />
              )
            ) : (
              <Result
                title="Payment not found"
                status="error"
                description="There was en error loading this payment information."
              />
            )}
          </div>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            {account.id ? (
              accountLoading ? (
                <LabelledLoading label="Account" />
              ) : (
                <Account
                  {...account}
                  editable
                  creatable
                  onCreate={() => {
                    createEntity("account");
                  }}
                />
              )
            ) : (
              <Result
                title="Account not found"
                status="error"
                description="There was en error loading this account."
              />
            )}
          </div>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            {unit.id ? (
              unitLoading ? (
                <LabelledLoading label="Unit" />
              ) : (
                <Unit
                  {...unit}
                  onRefresh={(unit) => setUnit(unit)}
                  editable
                  creatable
                  onCreate={() => {
                    createEntity("unit");
                  }}
                />
              )
            ) : (
              <Result
                title="Unit not found"
                status="error"
                description="There was en error loading this unit."
              />
            )}
          </div>

          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            <div className={`box document-center`}>
              {attachmentsLoading ? (
                <LabelledLoading label="Attachments" />
              ) : (
                <Gallery job_id={job.id} attachments={attachments} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderDesktopView = () => {
    return (
      <div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            {account.id ? (
              <div className={"account-preview box"}>
                <div style={{ marginRight: "15px" }}>
                  <Avatar size={50} name={account.name} initialsOnly />
                </div>
                <div style={{ flex: 1 }}>
                  <p>{account.name}</p>
                  <p>{account.phone}</p>
                </div>
                <div style={{ float: "right" }}>
                  <Button
                    size="small"
                    onClick={() => navigate(`/accounts/${account.id}/edit`)}
                  >
                    Edit <EditOutlined />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => navigate(`/accounts/${account.id}/edit`)}
                  >
                    Change <SelectOutlined />
                  </Button>
                </div>
              </div>
            ) : accountLoading ? (
              <div className={`box`} style={{ height: "275px" }}>
                <LabelledLoading label="Account" />
              </div>
            ) : (
              <Result
                title="Account not found"
                status="error"
                description="There was en error loading this account."
              />
            )}
          </div>

          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            {unit.id ? (
              <div
                className={"box"}
                style={{ height: "53px", lineHeight: "50px" }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <p>
                      {getDisplayLabel("unit", unit)}{" "}
                      {`${unit.vin || ""} ${unit.color || ""}`}
                    </p>
                  </div>
                  <div>
                    <Button
                      size="small"
                      onClick={() => navigate(`/accounts/${account.id}/edit`)}
                    >
                      Edit <EditOutlined />
                    </Button>
                    <Button
                      size="small"
                      onClick={() => navigate(`/accounts/${account.id}/edit`)}
                    >
                      Change <SelectOutlined />
                    </Button>
                  </div>
                </div>
              </div>
            ) : unitLoading ? (
              <div className={`box`} style={{ height: "275px" }}>
                <LabelledLoading label="Unit" />
              </div>
            ) : (
              <Result
                title="Unit not found"
                status="error"
                description="There was en error loading this unit."
              />
            )}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            <div>
              {job.id ? (
                <Job {...job} onRefresh={(job) => setJob(job)} />
              ) : jobLoading ? (
                <div className={`box`} style={{ height: "800px" }}>
                  <LabelledLoading label="Job" />
                </div>
              ) : (
                <Result
                  title="Job not found"
                  status="error"
                  description="There was en error loading this job."
                />
              )}
            </div>
            <div>
              {job.amends && (
                <div
                  className={`box amendments ${
                    amendsCollapsed ? "hidden" : "open"
                  }`}
                >
                  <Button
                    onClick={() => {
                      setAmendsCollapsed(!amendsCollapsed);
                    }}
                    block
                    fill="none"
                  >
                    Changes{" "}
                    {amendsCollapsed ? <UpOutlined /> : <DownOutlined />}
                  </Button>
                  <Details amends={job.amends} />
                </div>
              )}
            </div>
          </div>
          <div style={{ flex: 1, margin: isMobile() ? "0px" : "15px" }}>
            <div>
              {payment.id ? (
                <Payment {...payment} job={job} account={account} unit={unit} />
              ) : paymentLoading ? (
                <div className={`box payment`} style={{ height: "250px" }}>
                  <LabelledLoading label="Payment" />
                </div>
              ) : (
                <Result
                  title="Payment not found"
                  status="error"
                  description="There was en error loading this payment information."
                />
              )}
            </div>
            <div>
              <div className={`box document-center`}>
                {attachmentsLoading ? (
                  <LabelledLoading label="Attachments" />
                ) : (
                  <Gallery job_id={job.id} attachments={attachments} />
                )}
              </div>

              <div className={`box document-center`}>
                <h3>Document Center</h3>
                {Documents.map((action, index) => {
                  return (
                    <Button
                      style={{ marginTop: "5px" }}
                      key={action.key}
                      fill="outline"
                      color={action.color === "default" ? "default" : "danger"}
                      block
                      onClick={() => {
                        navigate("/documents/" + job.id + "/" + action.key);
                      }}
                    >
                      {action.icon} {action.title}
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderLayout = () => {
    return (
      <div>
        {isMobile() ? renderMobileView() : renderDesktopView()}
        <Modal
          visible={createOpen}
          onClose={() => {
            setCreateOpen(false);
          }}
        >
          <div
            style={{ minWidth: isMobile() ? "" : "500px", overflow: "scroll" }}
          >
            {createdEntity === "account" ? (
              <FormAccount
                loading={createEntityLoading}
                account={createEntityData as IAccount}
                onCancel={() => {
                  setCreateOpen(false);
                }}
              />
            ) : (
              <FormUnit
                unit={createEntityData as IUnit}
                onChange={(unit) => {
                  setCreateEntityData(unit);
                }}
                loading={createEntityLoading}
                onCancel={() => {
                  setCreateOpen(false);
                }}
              />
            )}
          </div>
        </Modal>
      </div>
    );
  };

  return <Layout title="View job">{renderLayout()}</Layout>;
};

export default JobView;
