import React, { useEffect, useState } from "react";
import { Unit as IUnit } from "../../../../shared/Interfaces";
import { UnitType } from "../../../../shared/SharedTypes";
import {
  EditOutlined,
  CarOutlined,
  BarcodeOutlined,
  GlobalOutlined,
  PlusOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd-mobile";
import {
  getDisplayLabel,
  isMobile,
  isNA,
  missingo,
} from "../../../../shared/SharedUtils";
import Chip from "../../../../shared/components/Chip";

interface IUnitProps extends IUnit {
  hideButtons?: boolean;
  onRefresh?: (unit: IUnit) => void;
  creatable?: boolean;
  onCreate?: () => void;
}

const Unit: React.FC<IUnitProps> = (values: IUnitProps) => {
  const [props, setProps] = useState(values);
  useEffect(() => {
    setProps(values);
  }, [values]);

  const { onCreate, onRefresh, ...linkProps } = props;
  const navigate = useNavigate();
  const fixedHeight = props.fixedHeight
    ? { height: props.fixedHeight + "px" }
    : {};
  const renderMobile = () => {
    return (
      <div className="box">
        <table className={"listing-table condensed"}>
          <tbody>
            <tr>
              <td>Unit</td>
              <td>
                {`${props.year || ""} ${props.make || ""} ${props.model || ""}`}
              </td>
            </tr>
            <tr>
              <td>License</td>
              <td>{missingo(props.license, "No License Plate")}</td>
            </tr>
            <tr>
              <td>VIN</td>
              <td>{missingo(props.vin, "No VIN")}</td>
            </tr>
            <tr>
              <td>Color</td>
              <td>{missingo(props.color, "No Color")}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>
                <Chip>{UnitType[Number(props.unit_type)]}</Chip>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p>
            <Button
              block
              onClick={() => navigate(`/accounts/${props.id}/jobs`)}
            >
              <ForwardOutlined /> Account Jobs
            </Button>
          </p>
          <p>
            <Button
              block
              onClick={() => navigate(`/accounts/${props.id}/edit`)}
            >
              <EditOutlined /> Edit
            </Button>
          </p>
        </div>
      </div>
    );
  };

  const renderWeb = () => {
    return (
      <tr>
        <td style={{ width: "60px" }}>{missingo(props.year, "No Year")}</td>
        <td>{missingo(props.make, "No Make")}</td>
        <td>{missingo(props.model, "No Model")}</td>
        <td>{missingo(props.license, "No License")}</td>
        <td>{missingo(props.vin, "No VIN")}</td>
        <td>{missingo(props.color, "No Color")}</td>
        <td style={{ textAlign: "center" }}>
          <Chip>{UnitType[Number(props.unit_type)]}</Chip>
        </td>
        <td>
          {isNA(props.current_location) ? (
            missingo(props.current_location, "No Address")
          ) : (
            <a
              href={`https://maps.google.com/?q=${encodeURI(
                String(props.current_location),
              )}`}
            >
              {props.current_location}
            </a>
          )}
        </td>
        <td style={{ float: "right" }}>
          <Button
            onClick={() => {
              navigate(`/units/${props.id}/jobs`);
            }}
            size="small"
          >
            <ForwardOutlined /> Unit Jobs
          </Button>
          <Button
            onClick={() => navigate(`/units/${props.id}/edit`)}
            size="small"
          >
            <EditOutlined /> Edit
          </Button>
        </td>
      </tr>
    );
  };

  return isMobile() ? renderMobile() : renderWeb();
};

export default Unit;
