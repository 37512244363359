import Layout from "../../shared/components/Layout";
import { useEffect, useState } from "react";
import {
  Account as IAccount,
  FleetUnit as IFleetUnit,
  Impound as IImpound,
  Job as IJob,
  Unit as IUnit,
  User as IUser,
} from "../../shared/Interfaces";
import EditAccount from "./components/accounts/EditAccount";
import EditFleetUnit from "./components/fleetunits/EditFleetUnit";
import EditUnit from "./components/units/EditUnit";
import EditUser from "./components/users/EditUser";
import EditImpound from "./components/impound/EditImpound";
import EditJob from "./components/jobs/EditJob";
import EditPayment from "./components/payments/EditPayment";

interface EditProps {
  type:
    | "Account"
    | "Unit"
    | "FleetUnit"
    | "User"
    | "Job"
    | "Impound"
    | "Payment";
  data?: IAccount | IUnit | IFleetUnit | IUser | IImpound | IJob;
}

const Edit: React.FC<EditProps> = (props: EditProps) => {
  const [pageTitle, setPageTitle] = useState<string>(
    "Change " + props.type + "s",
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [error, setError] = useState<string>("");
  const [IType, setIType] = useState(props.type);

  useEffect(() => {
    setIType(props.type);
    setPageTitle("Change " + props.type + "s");
  }, [props.type]);

  const renderFragment = () => {
    if (IType === "Account") {
      return <EditAccount id={1} is_active={false} />;
    }
    if (IType === "FleetUnit") {
      return <EditFleetUnit />;
    }
    if (IType === "Unit") return <EditUnit />;

    if (IType === "User") return <EditUser />;
    if (IType === "Impound") return <EditImpound />;
    if (IType === "Job") return <EditJob />;
    if (IType === "Payment") return <EditPayment />;
  };

  return (
    <Layout title={pageTitle}>
      <div className="listing">{renderFragment()}</div>
    </Layout>
  );
};

export default Edit;
