import Listing from "./Listing";
import { useContext, useEffect, useState } from "react";
import { Job, JobListing } from "../../../../../shared/Interfaces";
import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;
import { useAuth } from "../../../../../shared/providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useHandleAction } from "./Actions";
import Layout from "../../../../../shared/components/Layout";
import { OverlayLoading } from "../../../../../shared/components/Loading";

import API from "../../../../../shared/API";

interface EntityListingProps {
  type: "Account" | "Unit" | "User";
}
const EntityListing: React.FC<EntityListingProps> = (
  props: EntityListingProps,
) => {
  const [items, setItems] = useState<JobListing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const { session } = useAuth();
  const [overlayLoading, setOverlayLoading] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState<string>("Jobs");
  const { id } = useParams();

  const handleAction = useHandleAction({
    items,
    session,
    setItems,
    setOverlayLoading,
    navigationRef: navigate,
  });

  const api = API.getInstance();

  useEffect(() => {
    setPageTitle(`Jobs for ${props.type}`);
  }, [props.type]);

  useEffect(() => {
    setLoading(true);
    let params = {};
    if (props.type === "Account") {
      params = { ...params, account_id: id };
    }
    if (props.type === "Unit") {
      params = { ...params, unit_id: id };
    }
    if (props.type === "User") {
      params = { ...params, user_id: id };
    }

    api.fetchConstrainedJobs(params).then((res) => {
      setItems(res.data);
      setLoading(false);
    });
  }, []);
  return (
    <Layout title={pageTitle}>
      <Listing
        loadNext={(ss) => {
          return new Promise((resolve, reject) => {});
        }}
        hasMore={hasMore}
        loading={loading}
        items={items}
        onAction={handleAction}
        infiniteScroll={false}
      />
      {overlayLoading && <OverlayLoading />}
    </Layout>
  );
};
export default EntityListing;
