import Button from "antd-mobile/es/components/button";

interface NextOrBack {
    onCancel: () => void;
    onSubmit: () => void;
    disabled?: boolean;
    loading?: boolean;
    nextText?: string | any;
    backText?: string;
}


const NextOrBackButtons: React.FC<NextOrBack> = ({onCancel, onSubmit, disabled, loading, backText = "Cancel", nextText = "Next"}) => {
    return <div style={{display: "flex", gap: "10px"}}>
        <Button block type="button" onClick={onCancel} disabled={disabled}>
            {backText}
        </Button>
        <Button onClick={onSubmit} disabled={disabled} block type="submit" color="primary" loading={loading}>
            {nextText}
        </Button>
    </div>
}

export default NextOrBackButtons;
