import React, { useState } from "react";
import { JobWithMetadata } from "../../../../shared/Interfaces";
import {
  CALENDAR_HUMAN_FORMAT,
  Categories,
  EquipmentType,
  JobType,
  statusColor,
  statusIcon,
  StatusType,
} from "../../../../shared/SharedTypes";
import {
  BellOutlined,
  ClockCircleOutlined,
  CompassOutlined,
  EditOutlined,
  GlobalOutlined,
  LinkOutlined,
  MessageOutlined,
  SelectOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import JobIcon from "./JobIcon";
import Chip from "../../../../shared/components/Chip";
import {
  getMapProviderUrl,
  hid,
  isMobile,
  isNA,
  markdown,
  missingo,
  toast,
} from "../../../../shared/SharedUtils";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import { Button, Dialog, Input, Radio, Tag } from "antd-mobile";
import moment from "moment";
import Modal from "../../../../shared/components/Modal";
import API from "../../../../shared/API";
import { PhoneMask } from "../../../../shared/InputMask";

import MiniBox from "../../../../shared/components/MiniBox";
import { Flags } from "../../../../shared/components/Flags";

const Block: React.FC<{
  title: any;
  children: any;
  right?: boolean;
  last?: boolean;
  style?: any;
  leftStyle?: any;
  rightStyle?: any;
}> = (props) => {
  const alignRight = props.right;
  return (
    <div
      style={{
        ...{
          display: "flex",
          marginBottom: "5px",
          borderBottom: props.last ? "" : "1px solid var(--primary)",
          alignItems: "center",
          fontSize: isMobile() ? "12px" : "16px",
          //flexDirection: isMobile ? "column" : "row",
        },
        ...props.style,
      }}
    >
      <div
        style={{
          ...{ flex: "1", margin: "5px" },
          ...props.leftStyle,
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          ...{
            flex: 2,
            margin: "15px",
            textAlign: alignRight ? "right" : "inherit",
          },
          ...props.rightStyle,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

type LocationRequestDirections = "D" | "P";

interface IJobProps extends JobWithMetadata {
  hideButtons?: boolean;
  onRefresh?: (job: JobWithMetadata) => void;
}

const Job: React.FC<IJobProps> = (props: IJobProps) => {
  const [privateShown, setPrivateShown] = useState<boolean>(false);
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [locationUpdateLoading, setLocationUpdateLoading] =
    useState<boolean>(false);
  const [texting, setTexting] = useState<boolean>(false);
  const [locationNumber, setLocationNumber] = useState<string>(
    props.account_phone ?? "",
  );
  const [locationDirection, setLocationDirection] =
    useState<LocationRequestDirections>("P");
  const [locationModalVisible, setLocationModalVisible] =
    useState<boolean>(false);
  const { role, session, hasPerms } = useAuth();

  const requestCustomerLocationUpdate = () => {
    const api = API.getInstance();
    setLocationUpdateLoading(true);
    api
      .requestLocationUpdate(locationNumber, locationDirection, props.id)
      .then((res) => {
        toast("Location update request sent successfully", { type: "success" });
      })
      .catch((err) => {
        toast("Error sending location update request", { type: "error" });
      })
      .finally(() => {
        setLocationUpdateLoading(false);
        setLocationModalVisible(false);
      });
  };

  const sendTextToDriver = () => {
    Dialog.confirm({
      title: "Are you sure?",
      confirmText: "Yes",
      cancelText: "No",
      content:
        "This will send a text message to the driver assigned to this job.",
      onConfirm: () => {
        const api = API.getInstance();
        setTexting(true);
        api
          .sendTextToDriver(props.id)
          .then((res) => {
            toast("Text sent successfully", { type: "success" });
          })
          .catch((err) => {
            toast("Error sending text", { type: "error" });
          })
          .finally(() => {
            setTexting(false);
          });
      },
    });
  };

  return (
    <div className={"box listing"}>
      <table className={"listing-table"}>
        <tbody>
          <tr>
            <td>JOB #</td>
            <td>
              <Chip type="success">{hid(props.id)}</Chip>
            </td>
          </tr>
          <tr>
            <td>From</td>
            <td>
              {!isNA(props.from_address) ? (
                <a
                  href={getMapProviderUrl(props.from_address)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.from_address}
                </a>
              ) : (
                missingo(props.from_address, "No Address")
              )}
            </td>
          </tr>
          <tr>
            <td>To</td>
            <td>
              {!isNA(props.destination_address) ? (
                <a
                  href={getMapProviderUrl(props.destination_address)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.destination_address}
                </a>
              ) : (
                missingo(props.destination_address, "No Address")
              )}
            </td>
          </tr>
          <tr>
            <td>Type</td>
            <td>
              <JobIcon
                type={Number(props.tow_type)}
                width={40}
                postfix={
                  <span style={{ marginLeft: "15px" }}>
                    {JobType[Number(props.tow_type)]}
                  </span>
                }
                borderLine={false}
              />
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <Chip type={statusColor[Number(props.status)]}>
                {statusIcon[Number(props.status)]}{" "}
                {StatusType[Number(props.status)]}
              </Chip>
            </td>
          </tr>
          <tr>
            <td>Scheduled At</td>
            <td>
              <Chip type="info">
                {moment(props.scheduled_at).format("LLL")}
              </Chip>{" "}
              <span>{moment(props.scheduled_at).fromNow()}</span>
            </td>
          </tr>
          <tr>
            <td>Reason</td>
            <td>
              <JobIcon
                type={Number(props.reason)}
                width={40}
                postfix={
                  <span style={{ marginLeft: "5px" }}>
                    {JobType[Number(props.reason)]}
                  </span>
                }
                borderLine={false}
              />
            </td>
          </tr>
          <tr>
            <td>Category</td>
            <td>
              <GlobalOutlined /> {Categories[Number(props.category)]}
            </td>
          </tr>
          <tr>
            <td>Quote?</td>
            <td>{missingo(props.quoted, "Nothing")}</td>
          </tr>
          <tr
            style={{
              background: "var(--dangerBackgroundColor)",
              color: "var(--dangerTextColor)",
            }}
          >
            <td>Notes</td>
            <td>{missingo(props.public_notes, "No Notes")}</td>
          </tr>

          <tr>
            <td>Equipment</td>

            <td>
              {props.equipment && props.equipment.length > 0 ? (
                <Block title="EQUIPMENT" right>
                  {props.equipment.map((item, index) => {
                    return (
                      <Chip key={index} type="danger">
                        {EquipmentType[Number(item)]}
                      </Chip>
                    );
                  })}
                </Block>
              ) : (
                <span className={"missing"}>No Equipment</span>
              )}
            </td>
          </tr>
          <tr>
            <td>Reference</td>
            <td>{missingo(props.reference, "None")}</td>
          </tr>
        </tbody>
      </table>
      {hasPerms() && (
        <table className={"listing-table"}>
          <tbody>
            <tr
              style={{
                background: "var(--dangerBackgroundColor)",
                color: "var(--dangerTextColor)",
              }}
            >
              <td style={{ color: "red" }}>Private Notes</td>
              <td>{missingo(props.private_notes, "No Notes")}</td>
            </tr>
            <tr>
              <td>Requested By</td>
              <td>
                <MiniBox
                  icon={<UserOutlined />}
                  type="account"
                  id={Number(props.requested_by)}
                  title={props.requested_by_name || ""}
                />
              </td>
            </tr>
            <tr>
              <td>Released To</td>
              <td>
                <MiniBox
                  icon={<UserOutlined />}
                  type="account"
                  id={Number(props.released_to)}
                  title={props.released_to_name || ""}
                />
              </td>
            </tr>
            <tr>
              <td>Assigned To</td>
              <td>
                {" "}
                <MiniBox
                  icon={<UserOutlined />}
                  type="user"
                  id={Number(props.assigned_to)}
                  title={props.assigned_to_name || ""}
                />
              </td>
            </tr>
            <tr>
              <td>Created By</td>
              <td>
                <MiniBox
                  icon={<UserOutlined />}
                  type="user"
                  id={Number(props.created_by)}
                  title={props.created_by_name || ""}
                />
              </td>
            </tr>
            <tr>
              <td>Updated By</td>
              <td>
                <MiniBox
                  icon={<UserOutlined />}
                  type="user"
                  id={Number(props.updated_by)}
                  title={props.updated_by_name || ""}
                />
              </td>
            </tr>
            <tr>
              <td>Reminders</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>Send Reminders</td>
                      <td>
                        <Chip type={props.send_reminder ? "info" : "warning"}>
                          {props.send_reminder ? "Yes" : "No"}
                        </Chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Snooze Until</td>
                      <td>
                        {!isNA(props.snooze_until) ? (
                          <Chip size="small">
                            {moment(props.snooze_until).format("LLL")}
                          </Chip>
                        ) : (
                          <Chip size="small">Not set</Chip>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Reminder</td>
                      <td>
                        {!isNA(props.last_reminder_time) ? (
                          <Chip size="small">
                            {moment(props.last_reminder_time).format("LLL")}
                          </Chip>
                        ) : (
                          <Chip size="small">Not set</Chip>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Flags
                  flags={props.flags}
                  onSaved={(flags) => {
                    props.onRefresh && props.onRefresh({ ...props, flags });
                  }}
                  jobId={props.id}
                />
              </td>
            </tr>
          </tbody>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "5px",
            }}
          >
            <Button
              onClick={() => {
                setLocationModalVisible(true);
              }}
              block
              color="danger"
            >
              <CompassOutlined /> Request Customer Location Update
            </Button>
            <Button
              loading={texting}
              onClick={sendTextToDriver}
              block
              color="primary"
            >
              <MessageOutlined /> Text Job
            </Button>
          </div>
        </table>
      )}
    </div>
  );
};
export default Job;
