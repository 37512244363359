import { Mask } from "maska";

/* {
  '#': { pattern: /[0-9]/ },       // digits
  '@': { pattern: /[a-zA-Z]/ },    // letters
  '*': { pattern: /[a-zA-Z0-9]/ }, // letters & digits
} */

export const HourMinuteMask = (value: string) => {
  const mask = new Mask({ mask: "##:##" });
  if (value !== undefined) {
    return mask.masked(value);
  }
  return value;
};


export const INTLCurrencyMask = (value: string) => {
  value = value.replace(/[^0-9.]/g, "");
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(parseFloat(value));
};

export const PhoneMask = (value: string) => {
  const mask = new Mask({ mask: "###-###-####" });
  if (value !== undefined) {
    return mask.masked(value);
  }
  return value;
};

export const PlateMask = (value: string) => {
  const mask = new Mask({ mask: "@@-***********" });
  if (value !== undefined) {
    return mask.masked(value).toUpperCase();
  }
  return value;
};

export const VinMask = (value: string) => {
  const mask = new Mask({ mask: "*****************" });
  if (value !== undefined) {
    return mask.masked(value).toUpperCase();
  }
  return value;
};

export const YearMask = (value: string) => {
  const mask = new Mask({ mask: "####" });
  if (value !== undefined) {
    return mask.masked(value);
  }
  return value;
};

export const ColorMask = (value: string) => {
  const mask = new Mask({ mask: "@@@" });
  if (value !== undefined) {
    return mask.masked(value).toUpperCase();
  }
  return value;
};
