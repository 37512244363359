import React, { useEffect } from "react";
import { AuthPerson, Impound as IImpound } from "../../../../shared/Interfaces";

import {
  UserOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";
import { Button, Divider, Grid, Result } from "antd-mobile";
import { isMobile } from "../../../../shared/SharedUtils";

const Impound: React.FC<IImpound> = (props: IImpound) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const renderComponent = () => (
    <div className="box">
      <h3>
        {`${props.name}`}{" "}
        <Link style={{ float: "right" }} to={`/impounds/${props.id}/edit`}>
          <EditOutlined />
        </Link>
      </h3>
      {props.phone && (
        <p>
          <PhoneOutlined />{" "}
          <a href={`tel:${props.phone}`}>{`${props.phone}`}</a>
        </p>
      )}
      {props.email && (
        <p>
          <MailOutlined />{" "}
          <a href={`mailto:${props.email}`}>{`${props.email}`}</a>
        </p>
      )}
      {props.address && (
        <p>
          <GlobalOutlined />{" "}
          <a
            href={`https://maps.google.com/?q=${encodeURI(
              props.address || "",
            )}`}
          >
            {props.address}
          </a>
        </p>
      )}
      <Button
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        style={{ width: "100%" }}
        color="primary"
        fill="none"
      >
        {!collapsed ? (
          <>
            {" "}
            <UpOutlined /> Show Less <UpOutlined />{" "}
          </>
        ) : (
          <>
            {" "}
            <DownOutlined /> Show More <DownOutlined />
          </>
        )}
      </Button>
      {!collapsed && (
        <>
          {props.addresses ? (
            <>
              <Divider
                style={{
                  color: "#fb8501",
                  borderColor: "#fb8501",
                  borderStyle: "dashed",
                }}
              >
                Addresses
              </Divider>
              {props.addresses.map((address: any, index) => {
                return (
                  <p key={index}>
                    <GlobalOutlined />{" "}
                    <a
                      href={`https://maps.google.com/?q=${encodeURI(
                        address || "",
                      )}`}
                    >
                      {address}
                    </a>
                  </p>
                );
              })}
            </>
          ) : (
            <p className="tiny-notice">
              <WarningOutlined /> No Addresses for this property
            </p>
          )}

          {props.authorized ? (
            <>
              <Divider
                style={{
                  color: "#fb8501",
                  borderColor: "#fb8501",
                  borderStyle: "dashed",
                }}
              >
                Authorized
              </Divider>
              {props.authorized.map((authorized: any, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ flex: 1, padding: "5px" }}>
                      <UserOutlined /> {authorized.name}{" "}
                    </span>
                    <span style={{ flex: 1, padding: "5px" }}>
                      {!authorized.phone ? null : (
                        <>
                          <PhoneOutlined />{" "}
                          <a
                            href={`tel:${authorized.phone}`}
                          >{`${authorized.phone}`}</a>
                        </>
                      )}
                    </span>
                    <span style={{ flex: 1, padding: "5px" }}>
                      {!authorized.email ? null : (
                        <>
                          <MailOutlined />{" "}
                          <a
                            href={`mailto:${authorized.email}`}
                          >{`${authorized.email}`}</a>
                        </>
                      )}
                    </span>
                  </div>
                );
              })}
            </>
          ) : (
            <p className="tiny-notice">
              <WarningOutlined /> No authorized accounts for this property
            </p>
          )}

          {props.meta && (
            <p>
              <InfoCircleOutlined /> {props.meta}
            </p>
          )}
        </>
      )}
    </div>
  );

  return isMobile() ? (
    renderComponent()
  ) : (
    <tr>
      <td>{renderComponent()}</td>
    </tr>
  );
};

export default Impound;
