import React from "react";

interface ChipProps {
  type?: "success" | "warning" | "danger" | "info" | any;
  size?: "small" | "medium" | "large";
  children?: any;
  onClick?: () => void;
  style?: any;
}

const Chip: React.FC<ChipProps> = (props: ChipProps) => {
  const type = props.type || "info";
  const hoverable = props.onClick ? "hoverable" : "";
  const size = props.size || "medium";
  return (
    <span
        style={props.style}
      onClick={props.onClick}
      className={`chip ${type} ${hoverable} ${size}`}
    >
      {props.children}
    </span>
  );
};
export default Chip;
