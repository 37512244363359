import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import Account from "../../pages/protected/components/accounts/Account";
import {
  Account as IAccount,
  Unit as IUnit,
  User as IUser,
} from "../Interfaces";
import User from "../../pages/protected/components/users/User";
import Unit from "../../pages/protected/components/units/Unit";
import { getDisplayLabel, isNada, toast } from "../SharedUtils";
import { CloseOutlined } from "@ant-design/icons";
import API from "../API";
import { Loading } from "./Loading";

interface MiniBoxProps {
  icon: React.ReactNode;
  title?: string;
  type: "user" | "account" | "unit";
  id?: number;
}

const MiniBox: React.FC<MiniBoxProps> = ({ icon, title, type, id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [boxTitle, setBoxTitle] = useState<string>(title || "");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [entity, setEntity] = useState<IUnit | IAccount>({} as any);

  useEffect(() => {
    setBoxTitle(title || "");
    setDisabled(isNada(title));
  }, [title]);

  useEffect(() => {
    if (open) {
      if (!id) {
        setOpen(false);
        return;
      }
      setLoading(true);
      let api = API.getInstance();
      const call =
        type === "user" ? api.fetchUser.bind(api) : api.fetchAccount.bind(api);

      call(id)
        .then((result) => {
          setLoading(false);
          setEntity(result.data);
        })
        .catch((err) => {
          toast("Error fetching data", { type: "error" }, err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open]);

  const getModalContent = () => {
    return loading ? (
      <Loading loading={true} />
    ) : type === "user" ? (
      <User {...(entity as IUser)} />
    ) : (
      <Account {...(entity as IAccount)} />
    );
  };

  const openModal = () => {
    if (!isNada(boxTitle)) {
      setOpen(true);
    }
  };

  return (
    <>
      <div
        onClick={openModal}
        className={`mini-box ${disabled ? "disabled" : ""}`}
      >
        <div className="icon">{!disabled ? icon : <CloseOutlined />}</div>
        <div className="title">{boxTitle || "Nothing Selected"}</div>
      </div>
      <Modal
        visible={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {getModalContent()}
      </Modal>
    </>
  );
};

export default MiniBox;
