import React, { useCallback, useEffect, useState } from "react";
import { Account as IAccount, User as IUser } from "../Interfaces";
import { Card } from "antd-mobile";
import Button from "antd-mobile/es/components/button";
import {
  CloseCircleFilled,
  EditFilled,
  StarFilled,
  UserOutlined,
} from "@ant-design/icons";
import Modal from "./Modal";
import User from "../../pages/protected/components/users/User";
import Account from "../../pages/protected/components/accounts/Account";
import Select2 from "./Select2";
import { fetchStored } from "../AsyncStorage";
import { toast } from "../SharedUtils";

export type EntitySelectorType = IUser | IAccount | null;

export interface EntitySelector3Props {
  clearable?: boolean;
  required?: boolean;
  editable?: boolean;
  defaultValue?: EntitySelectorType | null;
  value?: EntitySelectorType | null;
  onChange?: (entity: EntitySelectorType | null) => void;
  label?: string;
  subText?: string;
  entityType: "user" | "account";
}

const EntitySelector: React.FC<EntitySelector3Props> = ({
  clearable = false,
  required = false,
  editable = true,
  defaultValue = null,
  value = null,
  label = "Select",
  subText = "Select an entity",
  entityType,
  onChange,
}) => {
  const [selectedEntity, setSelectedEntity] =
    useState<EntitySelectorType | null>(value);
  const [error, setError] = useState<string | null>(null);

  const [users, setUsers] = useState<IUser[]>([]);

  const hasEntity = useCallback(() => {
    if (!selectedEntity) return false;
    if (selectedEntity.id === 0) return false;
    if (selectedEntity.id === undefined) return false;
    return true;
  }, [selectedEntity]);

  useEffect(() => {
    if (required && !hasEntity()) {
      setError("This field is required");
    } else {
      setError(null);
    }
  }, [required, selectedEntity, hasEntity]);

  useEffect(() => {
    if (entityType === "user") {
      fetchStored("drivers")
        .then((res) => {
          setUsers(res);
        })
        .catch((e) => {
          toast("Couldn't reliably fetch drivers list, contact admin.", {
            type: "error",
          });
          setUsers([]);
        });
    }
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(selectedEntity);
    }
  }, [selectedEntity]);

  return (
    <div className={"entity-selector"}>
      <Card
        title={
          <div className={"header"}>
            <div className={"label"}>{label}</div>
            <div className={"user-actions"}>
              {editable && (
                <div title="Change">
                  <Button
                    onClick={() => {
                      setSelectedEntity(null);
                      onChange && onChange(null);
                    }}
                    fill="none"
                  >
                    <EditFilled />
                  </Button>
                </div>
              )}
              {clearable && (
                <div title="Clear">
                  <Button
                    onClick={() => {
                      setSelectedEntity(null);
                      onChange && onChange(null);
                    }}
                    fill="none"
                  >
                    <CloseCircleFilled />
                  </Button>
                </div>
              )}
              {defaultValue && (
                <div title="Default">
                  <Button
                    onClick={() => {
                      setSelectedEntity(defaultValue);
                      onChange && onChange(defaultValue);
                    }}
                    fill="none"
                  >
                    <StarFilled />
                  </Button>
                </div>
              )}
            </div>
          </div>
        }
      >
        <div className="user-wrapper">
          {hasEntity() ? (
            <div className="user-display">
              <div className={"user-display__avatar"}>
                <UserOutlined />
              </div>
              <div className={"user-details"}>
                {selectedEntity && (
                  <>
                    <h3>{selectedEntity.name}</h3>
                    <p>{selectedEntity.phone}</p>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              {entityType === "user" ? (
                <Select2
                  options={
                    users
                      ? users.map((driver: IUser) => {
                          return { label: driver.name, value: driver.id };
                        })
                      : []
                  }
                  onChange={(option: any) => {
                    const selectedDriver = users.find(
                      (driver: IUser) => driver.id === option,
                    );
                    setSelectedEntity(selectedDriver as IUser);
                    onChange && onChange(selectedEntity as IUser);
                  }}
                  value={selectedEntity ? (selectedEntity as IUser).id : 0}
                  label="Assigned To"
                  style={{ flex: 1 }}
                />
              ) : (
                <Select2
                  async={true}
                  asyncSearchType={entityType}
                  onChange={(value: any) => {
                    setSelectedEntity(value as IAccount);
                    onChange && onChange(value as IAccount);
                  }}
                  creatable={false}
                  clearable={false}
                  label="Type to search"
                />
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default EntitySelector;
