interface AvatarProps {
  name: string;
  size: number;
  color?: string;
  textColor?: string;
  initialsOnly?: boolean;
  defaultSymbol?: string;
}

const Avatar = (props: AvatarProps) => {
  const extractInitials = () => {
    let initials = [props.defaultSymbol];

    // replace everything but letters in the name
    let name = props.name ? props.name.replace(/[^a-zA-Z]/g, "") : "";

    if (props.name) {
      initials = name.match(/\b\w/g) || [];
      if (name === "N" || props.name === "NA" || props.name === "N/A")
        initials = [props.defaultSymbol];
    }
    return `${initials[0] || "?"}${initials[1] || ""}`;
  };

  return (
    <div
      style={{
        backgroundColor: props.color,
        width: props.size + "px",
        height: props.size + "px",
        fontSize: String(props.size / 2) + "px",
        textAlign: "center",
        lineHeight: props.size + "px",
        borderRadius: "50%",
        color: props.textColor,
      }}
    >
      {props.initialsOnly ? extractInitials() : props.name}
    </div>
  );
};

Avatar.defaultProps = {
  name: "AA",
  size: 60,
  color: "var(--primary)",
  textColor: "#ffffff",
  defaultSymbol: "?",
};

export default Avatar;
