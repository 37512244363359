import { Flag, Job } from "../Interfaces";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { CALENDAR_DB_FORMAT, JobType } from "../SharedTypes";
import { Button } from "antd-mobile";
import React, { useState } from "react";
import { isMobile } from "../SharedUtils";
import API from "../API";
import { toast } from "../SharedUtils";
import moment from "moment";
import Modal from "./Modal";
import Select2 from "./Select2";
import { useAuth } from "../providers/AuthProvider";
import { Link, useNavigate } from "react-router-dom";

interface UrgentAlertProps {
  job: Job;
  onRemove?: () => void;
}

type UpdateStateFlag = "update" | "dismiss" | "snooze";

const UrgentAlert: React.FC<UrgentAlertProps> = (props) => {
  const { job } = props;
  const [loading, setLoading] = useState<[boolean, boolean, boolean]>([
    false,
    false,
    false,
  ]);
  const [open, setOpen] = useState<boolean>(false);
  const options = [
    "15 minutes",
    "30 minutes",
    "1 hour",
    "2 hours",
    "12 hours",
    "24 hours",
    "1 week",
    "1 month",
    "1 year",
  ];
  const [selectedOption, setSelectedOption] = useState<number>(0);
  const api = API.getInstance();

  const { session } = useAuth();

  const displayNameFromJob = () => {
    return `Update status of  the ${JobType[job.tow_type ?? 0]} on ${
      job.from_address
    }`;
  };

  const getStatusLabel = () => {
    if (!job.flags?.en_route) {
      return "En Route";
    } else if (!job.flags?.on_scene) {
      return "On Scene";
    } else {
      return "Completed";
    }
  };

  const dismissAlert = () => {
    updateFlag("dismiss");
  };

  const snoozeAlert = () => {
    setOpen(!open);
  };

  const updateStatus = () => {
    updateFlag("update");
  };

  const navigate = useNavigate();

  const updateFlag = (state: UpdateStateFlag) => {
    if (state === "dismiss") {
      setLoading([false, false, true]);
      api
        .patchJob(job.id, { send_reminder: false })
        .then((res) => {
          props.onRemove && props.onRemove();
          toast("Alert dismissed", { type: "success" });
        })
        .catch((err) => {
          toast("Failed to dismiss alert", { type: "error" });
        })
        .finally(() => {
          setLoading([false, false, false]);
        });
    } else if (state === "snooze") {
      setLoading([false, true, false]);
      const [value, rawUnits] = options[selectedOption].split(" ");
      let units = rawUnits as
        | "years"
        | "months"
        | "days"
        | "hours"
        | "minutes"
        | "seconds";
      const new_snooze_until = moment(new Date())
        .add(value, units)
        .format(CALENDAR_DB_FORMAT);
      api
        .patchJob(job.id, { snooze_until: new_snooze_until })
        .then((res) => {
          props.onRemove && props.onRemove();
          toast("Alert snoozed", { type: "success" });
        })
        .catch((err) => {
          toast("Failed to snooze alert", { type: "error" });
        })
        .finally(() => {
          setLoading([false, false, false]);
        });
    } else {
      let new_flag: Flag = {
        status: true,
        at: moment(new Date()).format(CALENDAR_DB_FORMAT),
        by_user: session?.user?.id || 0,
        by_user_name: session?.user?.name || "",
      };
      let send_reminder = true;
      let flag_label = "en_route";
      if (!job.flags?.en_route) {
        flag_label = "en_route";
      } else if (!job.flags?.on_scene) {
        flag_label = "on_scene";
      } else {
        flag_label = "completed";
        send_reminder = false;
      }

      const new_flags = { ...job.flags, [flag_label]: new_flag };
      api
        .patchJob(job.id, { flags: new_flags, send_reminder: send_reminder })
        .then((res) => {
          toast("Job updated", { type: "success" });
          props.onRemove && props.onRemove();
        })
        .catch((e) => {
          toast("Failed to update job", { type: "error" });
        })
        .finally(() => {
          setLoading([false, false, false]);
        });
    }
  };

  return (
    <div>
      <div className={`message-alert ${isMobile() ? "is-mobile" : ""}`}>
        <div className={"message-alert__icon"}>
          <WarningOutlined />
        </div>
        <div className={"message-alert__content"}>
          <Link to={`/jobs/${job.id}`}>{displayNameFromJob()}</Link>
        </div>
        <div className={"message-alert__buttons"}>
          <Button color="success" loading={loading[0]} onClick={updateStatus}>
            {getStatusLabel()} <CheckOutlined />
          </Button>
          <Button color="danger" loading={loading[1]} onClick={snoozeAlert}>
            Snooze <ClockCircleOutlined />
          </Button>
          <Button loading={loading[2]} onClick={dismissAlert}>
            Dismiss <CloseOutlined />
          </Button>
        </div>
      </div>
      <Modal
        visible={open}
        onClose={() => {
          setOpen(!open);
        }}
      >
        <div>
          <Select2
            value={selectedOption}
            options={options}
            onChange={(value, index) => {
              setSelectedOption(Number(value));
            }}
          ></Select2>
          <Button
            onClick={() => {
              updateFlag("snooze");
            }}
            loading={loading[1]}
            block
            color="danger"
          >
            <ClockCircleOutlined /> Snooze
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UrgentAlert;
