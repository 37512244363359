import React, {createContext, useContext, useEffect, useState} from 'react';
import {User} from "../Interfaces"
import {fetchStored, removeStored, setStored} from '../AsyncStorage';
import {VerifyJWT} from "../SharedUtils";
import API from "../API";


export enum Role {
    DRIVER,
    OFFICE,
    ADMIN,
}

export interface AuthSession {
    token: string;
    user: User;
}

interface AuthContextProps {
    session: AuthSession | null;
    isLoading: boolean;
    login: (email: string, password: string, fcm_token?: string) => Promise<void>;
    logout: (callback?: () => void) => void;
    role: Role | null;
    hasPerms: () => boolean;
    errorMessage: string | null;
}

const AuthContext = createContext<AuthContextProps | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

interface AuthProviderProps {
    children: React.ReactNode;
}


export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const [session, setSession] = useState<AuthSession | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const login = async (email: string, password: string, fcm_token?: string) => {
        try {
            //const response = await axios.post('/api/login', {email, password, fcm_token});
            let api = API.getInstance();
            const response = await api.login(email, password, fcm_token);
            const {token, user} = response.data;

            if (token && user) {
                // You'd replace this function with your VerifyJWT function
                if (VerifyJWT(token)) {
                    if (user.is_active) {
                        setSession({token, user});
                        setStored('session', {token, user});
                    } else {
                        setErrorMessage('User is not active');
                    }
                } else {
                    setErrorMessage('Invalid token');
                }
            } else {
                setErrorMessage('Login failed');
            }
        } catch (error) {
            setErrorMessage('Login error');
        }
    };

    const logout =  (callback?: () => void) => {
        removeStored('session');
        setSession(null);
        if (callback) {
            callback();
        }
    };

    const hasPerms = () => {
        return session?.user.role !== Role.DRIVER;
    };

    useEffect(() => {
        fetchStored('session', true).then(((storedSession: AuthSession | null) => {
            if (storedSession) {
                if (VerifyJWT(storedSession.token)) {
                    if (storedSession.user.is_active) {
                        setSession(storedSession);
                        //console.log(storedSession);
                    }
                }
            }
            setIsLoading(false);

        })).catch((error) => {
            setErrorMessage('Login error');
            setIsLoading(false);
        });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                session,
                isLoading,
                login,
                logout,
                role: session?.user.role || null,
                hasPerms,
                errorMessage,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
