import React, { useEffect, useState } from "react";
import { PrinterOutlined } from "@ant-design/icons";
interface StatProps {
  label: string;
  value: string | number | any;
  postfix?: string;
  color: "primary" | "success" | "warning" | "danger" | "info";
  description?: string;
  icon?: any;
  style?: any;
}

const Stat: React.FC<StatProps> = (props: StatProps) => {
  const [value, setValue] = useState<string | number>(props.value);

  useEffect(() => {
    let parsed = parseFloat(props.value);
    if (isNaN(parsed)) {
      setValue(0);
    } else {
      if (parsed % 1 == 0) {
        setValue(parsed);
      } else {
        setValue(parsed.toFixed(2));
      }
    }
  }, [props.value]);
  return (
    <div className={"stat"} style={props.style}>
      <div className={`digit ${props.color}`}>
        <div style={{ display: "flex" }}>
          {props.icon ? (
            <div style={{ flex: 1, alignSelf: "center", marginRight: "5px" }}>
              {props.icon}
            </div>
          ) : null}
          <div style={{ flex: 1 }}>
            <h3>
              {value} {props.postfix}
            </h3>
          </div>
        </div>
      </div>
      <div className={"description"}>
        <h3>{props.label}</h3>
        {props.description && <p>{props.description}</p>}
      </div>
    </div>
  );
};
export default Stat;
