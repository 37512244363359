import { Amendment } from "../../../../shared/Interfaces";

export interface DetailsProps {
  amends?: Amendment[];
}

const Details: React.FC<DetailsProps> = (props: DetailsProps) => {
  return (
    <div>
      <div className="amends">
        {props.amends && (
          <div className="amends">
            {props.amends.map((ammendment, index:number) => {
              return <p key={index}>{ammendment.message}</p>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Details;
