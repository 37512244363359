import React, { useEffect, useState } from "react";
import Layout from "../../shared/components/Layout";
import { useAuth } from "../../shared/providers/AuthProvider";
import { isMobile } from "../../shared/SharedUtils";

import {
  ActionSheet,
  Button,
  FloatingBubble,
  Input,
  Result,
} from "antd-mobile";
import moment from "moment";
import {
  BarChartOutlined,
  CloseOutlined,
  CodeOutlined,
  FilePdfOutlined,
  PrinterOutlined,
  SaveOutlined,
  TableOutlined,
} from "@ant-design/icons";
import Select2 from "../../shared/components/Select2";
import { User } from "../../shared/Interfaces";
import API, { API_BASE_URL } from "../../shared/API";
import { Loading } from "../../shared/components/Loading";
import { PaymentMethod } from "../../shared/SharedTypes";
import { toast } from "../../shared/SharedUtils";
import { Link } from "react-router-dom";
import { Action } from "antd-mobile/es/components/action-sheet";
import { TinyHTML } from "../../shared/TinyHTML";

interface ReportError {
  code: number;
  message: string;
}

type PaymentMethodType = (typeof PaymentMethod)[number];
type PaymentMethodMapping = {
  [P in PaymentMethodType]: number;
};

interface ReportJob {
  job_number: string;
  job_date: string;
  driver_name: string;
  unit: string;
  from_address: string;
  to_address: string;
  paid: string;
  quoted: string;
  charged: string;
  payment_method: string;
  extra: string;
}

interface ReportData {
  reported: ReportJob[];
  unpaid_amount: number;
  payment_methods_amount: PaymentMethodMapping;
  total_collected: number;
}

const shifts = [
  "8AM - 4PM",
  "10AM - 6PM",
  "12PM - 8PM",
  "2PM - 10PM",
  "4PM - 12AM",
  "12AM - 8AM",
];

const shiftValues = [
  { start: "08:00", end: "16:00" },
  { start: "10:00", end: "18:00" },
  { start: "12:00", end: "20:00" },
  { start: "14:00", end: "22:00" },
  { start: "16:00", end: "00:00" },
  { start: "00:00", end: "08:00" },
];

const ReportActions: Action[] = [
  {
    text: (
      <>
        <PrinterOutlined /> Print
      </>
    ),
    key: "print",
  },
  {
    text: (
      <>
        <TableOutlined /> Get as CSV
      </>
    ),
    key: "csv",
  },
  {
    text: (
      <>
        <FilePdfOutlined /> Get as PDF
      </>
    ),
    key: "pdf",
  },
  {
    text: (
      <>
        <CodeOutlined /> Get as JSON
      </>
    ),
    key: "json",
  },
  {
    text: (
      <>
        <CloseOutlined /> Cancel
      </>
    ),
    key: "cancel",
    danger: true,
  },
];

const Reports: React.FC = (props: any) => {
  const { session, hasPerms } = useAuth();
  const [dateStart, setDateStart] = useState<string>(
    moment(new Date()).set("hour", 8).set("minute", 0).format("YYYY-MM-DD"),
  );
  const [dateEnd, setDateEnd] = useState<string>(
    moment(new Date()).set("hour", 18).set("minute", 0).format("YYYY-MM-DD"),
  );
  const [error, setError] = useState<ReportError>({} as ReportError);
  const [shift, setShift] = useState<number>(0);

  const [userId, setUserId] = useState<number>(0);
  useEffect(() => {
    setUserId(session?.user.id || 0);
    generateReport();
  }, []);

  const [reportData, setReportData] = useState<ReportData>({
    reported: [] as ReportJob[],
  } as ReportData);

  const generateReport = () => {
    const api = API.getInstance();
    setLoading(true);

    api
      .getShiftReport(
        dateStart,
        dateEnd,
        shiftValues[shift].start,
        shiftValues[shift].end,
        userId,
      )
      .then((res) => {
        setLoading(false);
        if (res) setReportData(res.data);
      })
      .catch(() => {
        toast("Error fetching report", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [actionsVisible, setActionsVisible] = useState<boolean>(false);

  const doPrint = () => {
    const printWindow = window.open("", "", "height=400,width=800");
    if (printWindow) {
      const content = TinyHTML.replace(
        "%s",
        document.getElementById("printable")?.innerHTML || "",
      );

      printWindow.document.write(content);

      printWindow.document.close();
      printWindow.print();
    }
  };
  const handleAction = (action: Action) => {
    if (action.key === "print") {
      doPrint();
    }

    let reportsUrl = `${API_BASE_URL}/generate/reports/shift/?date_from=${dateStart}&date_to=${dateEnd}&shift_start=${shiftValues[shift].start}&shift_end=${shiftValues[shift].end}&user_id=${userId}&token=${session?.token}`;
    if (action.key === "csv") {
      window.open(reportsUrl + "&export_format=csv", "_blank");
    }

    if (action.key === "pdf") {
      window.open(reportsUrl + "&export_format=pdf", "_blank");
    }

    if (action.key === "json") {
      window.open(reportsUrl + "&export_format=json", "_blank");
    }
  };
  return (
    <Layout title="Reports">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "5px",
          }}
          className={"form"}
        >
          {hasPerms() && (
            <div style={{ flex: 1 }}>
              <label htmlFor="driver">Driver</label>
              <Select2
                async
                asyncSearchType="user"
                label="Driver"
                onChange={(driver) => {
                  if (driver && typeof driver !== "string") {
                    setUserId((driver as User).id);
                  }
                }}
              />
            </div>
          )}
          <div style={{ flex: 1 }}>
            <label htmlFor="start-date">Start Date</label>
            <Input
              type="date"
              className={error && error.code == 0 ? "withErrors" : ""}
              id={"start-date"}
              onChange={(e) => {
                setError({} as ReportError);
                if (moment(e).format("X") > moment(dateEnd).format("x")) {
                  setError({
                    code: 0,
                    message: "Start date cannot be after end date",
                  });
                  setDateStart(e);
                } else {
                  setDateStart(e);
                  setError({} as ReportError);
                }
              }}
              value={moment(dateStart).format("YYYY-MM-DD")}
            />
            {error && error.code === 0 && (
              <span>
                <br />
                {error.message}
              </span>
            )}
          </div>

          <div style={{ flex: 1 }}>
            <label htmlFor={"end-date"}>End Date</label>
            <Input
              id={"end-date"}
              type="date"
              className={error && error.code == 1 ? "withErrors" : ""}
              onChange={(e) => {
                setError({} as ReportError);
                if (moment(e).format("X") < moment(dateStart).format("X")) {
                  setError({
                    code: 1,
                    message: "End date cannot be before start date",
                  });
                  setDateEnd(e);
                } else {
                  setDateEnd(e);
                  setError({} as ReportError);
                }
              }}
              value={moment(dateEnd).format("YYYY-MM-DD")}
            />
            {error && error.code === 1 && <span>{error.message}</span>}
          </div>
          <div style={{ flex: 1 }}>
            <label>Shift</label>
            <Select2
              label="Shift"
              onChange={(shift) => {
                setShift(Number(shift));
              }}
              options={shifts}
            ></Select2>
          </div>
          <div style={{ flex: 1 }}>
            <br />
            <Button
              color="primary"
              style={{ height: "50px" }}
              onClick={generateReport}
              block
            >
              <BarChartOutlined /> Generate Report
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          {loading ? (
            <Loading loading={true} />
          ) : (
            <div>
              {reportData.reported.length !== 0 && (
                <div id={"printable"}>
                  <table
                    className={
                      "table table-striped table-hover table-bordered pure-table"
                    }
                  >
                    <thead>
                      <tr>
                        <td>Job</td>
                        <td>Date</td>
                        <td>Driver</td>
                        <td>Unit</td>
                        <td>From</td>
                        <td>To</td>
                        <td>Paid</td>
                        <td>Quoted</td>
                        <td>Charged</td>
                        <td>VIA</td>
                        <td>Extra</td>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.reported &&
                        reportData.reported.map(
                          (job: ReportJob, index: number) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <Link to={`/jobs/${job.job_number}`}>
                                    {job.job_number}
                                  </Link>
                                </td>
                                <td>{moment(job.job_date).format("lll")}</td>
                                <td>{job.driver_name}</td>
                                <td>{job.unit}</td>
                                <td>{job.from_address}</td>
                                <td>{job.to_address}</td>
                                <td>{job.paid}</td>
                                <td>{job.quoted}</td>
                                <td>{job.charged}</td>
                                <td>{job.payment_method}</td>
                                <td>{job.extra}</td>
                              </tr>
                            );
                          },
                        )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={4}>Total Collected</td>
                        <td colSpan={4}>${reportData.total_collected}</td>
                        <td colSpan={3}>
                          {reportData.payment_methods_amount &&
                            Object.entries(
                              reportData.payment_methods_amount,
                            ).map(([method, amount]) => (
                              <span style={{ marginLeft: "5px" }} key={method}>
                                {method}: <strong> ${amount}</strong>
                              </span>
                            ))}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <FloatingBubble
                    style={{
                      "--initial-position-bottom": "24px",
                      "--initial-position-right": "24px",
                      "--edge-distance": "24px",
                    }}
                  >
                    <SaveOutlined
                      style={{ fontSize: "28px" }}
                      onClick={() => {
                        setActionsVisible(true);
                      }}
                    />
                  </FloatingBubble>
                </div>
              )}
              {reportData.reported.length === 0 && (
                <Result title="No data available" status="warning" />
              )}
            </div>
          )}

          <ActionSheet
            actions={ReportActions}
            onAction={handleAction}
            visible={actionsVisible}
            closeOnAction
            closeOnMaskClick
            onClose={() => {
              setActionsVisible(false);
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Reports;
