import { useJobCreateContext } from "../JobCreate";
import { useNavigate } from "react-router-dom";
import { hid, isNada, toast } from "../../../../../shared/SharedUtils";
import { useEffect, useState } from "react";
import { isAccountValid, isJobValid } from "./Validators";
import FormJob from "../FormJob";
import Account from "../../accounts/Account";
import Unit from "../../units/Unit";
import Payment from "../../payments/Payment";
import Job from "../Job";
import NextOrBackButtons from "./NextOrBack";
import { CheckOutlined } from "@ant-design/icons";
import API from "../../../../../shared/API";
import { JobWithMetadata, Resource } from "../../../../../shared/Interfaces";

const StepDispatch: React.FC = () => {
  const { account, unit, payment, job } = useJobCreateContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isAccountValid(account)) navigate("/jobs/create/step-account");
  }, []);

  const dispatch = () => {
    setLoading(true);
    let api = API.getInstance();

    try {
      let accountId = account.id;
      let unitId = unit.id;
      let userId = job.assigned_to;



      let resource: Resource = {
        account: account,
        unit: unit,
        job: job,
        payment: payment,
        assigned_to: userId,
      };

      if (accountId) {
        resource.account = accountId;
      }

      if (unitId) {
        resource.unit = unitId;
      }

      // sanity checks
      if (isNada(payment.signature)) delete payment.signature;

      if (!userId) {
        toast("User is required", { type: "error" });
        return;
      } else {
        api.createJobResource(resource).then((result) => {
          toast("Job created successfully", { type: "success" });
          navigate(`/jobs/${hid(result.data.job.id)}`);
        });
      }
    } catch (err) {
      toast("Error creating job", { type: "error" }, err as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <div>
        <Account {...account} hideButtons />
        <Unit {...unit} hideButtons />
        <Payment {...payment} hideButtons account={account} job={job} />
        <Job {...(job as JobWithMetadata)} hideButtons />
        <NextOrBackButtons
          loading={loading}
          onCancel={() => {
            navigate(-1);
          }}
          onSubmit={dispatch}
          nextText={
            <>
              <CheckOutlined /> Dispatch{" "}
            </>
          }
        />
      </div>
    </div>
  );
};
export default StepDispatch;
