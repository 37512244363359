import {
  BankOutlined,
  CarOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  LeftOutlined, LogoutOutlined,
  MenuOutlined,
  SearchOutlined, SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Button, Dialog, Input } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { JobIcon2 } from "../../pages/protected/components/jobs/JobIcon";
import { ReactComponent as TowZoneIcon } from "../../shared/assets/job-type-icons/no-parking-svgrepo-com.svg";
import { ReactComponent as TowingIcon } from "../../shared/assets/job-type-icons/tow-truck-svgrepo-com.svg";
import { useAuth } from "../providers/AuthProvider";

export interface Menu2Props {
  onClick?: () => void,
  onBackClick?: () => void,
  isCollapsed?: boolean,
  shouldHaveBackArrow?: boolean,
  title: string
}

export const Menu2 = (props: Menu2Props) => {

  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
  const [hasBackButton, setHasBackButton] = useState<boolean>(true);

  const navigate  = useNavigate();

  const {logout} = useAuth();

  useEffect(() => {
    setHasBackButton( props.title === "Home" ? false : true );
  }, [props]);

  const collapse = () => setMenuCollapsed(!menuCollapsed);

  const isActive = (path: string) => {
    if (path === "/") return window.location.pathname === path ? "active" : "";
    return window.location.pathname.includes(path) ? "active" : "";
  }

  return (
    <div className="menu">
      <div className="menu-header">
        <menu>
          <div className="back element">
            {hasBackButton ? <Button onClick={() => {
              navigate(-1);
            }} fill="none" color="primary" size="large"><LeftOutlined /> <span>Back</span></Button> : ""}
          </div>
          <div className="title element">
            {props.title}
          </div>
          <div className="right element">
            <Button fill="none" color="primary" size="large" onClick={collapse}><MenuOutlined /></Button>
          </div>
        </menu>
      </div>
      <div className={`menu-content ${menuCollapsed ? "collapsed" : ""}`}>
        <nav>
          <Link to={"/"} onClick={collapse} className={isActive('/')}>
            <HomeOutlined /> Main Page
          </Link>
          <Link to={'/search/'} onClick={collapse} className={isActive('/search/')}>
            <SearchOutlined /> Search
          </Link>
          <Link to={'/jobs/'} onClick={collapse} className={isActive('/jobs/')}>
            <EnvironmentOutlined /> Jobs
          </Link>
          <Link to={'/accounts/'} onClick={collapse} className={isActive('/accounts')}>
            <UserOutlined /> Accounts
          </Link>
          <Link to={'/units/'} onClick={collapse}  className={isActive('/units/')}>
            <CarOutlined /> Units
          </Link>
          <Link to={'/fleetunits/'} onClick={collapse}  className={isActive('/fleetunits/')}>
            <span className={'anticon anticon-towtruck'}>
              <TowingIcon focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true" />
            </span> Fleet Units
          </Link>
          <Link to={'/users/'} onClick={collapse}  className={isActive('/users/')}>
            <UserOutlined /> Users
          </Link>
          <Link to={'/impounds/'} onClick={collapse}  className={isActive('/impounds/')}>
            <span className={'anticon'}>
              <TowZoneIcon focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true" />
            </span> Impounds
          </Link>
          <Link to={'/reports/'} onClick={collapse}  className={isActive('/reports/')}>
            <BankOutlined /> Reports
          </Link>
          <Link to={'/settings/'} onClick={collapse}  className={isActive('/settings/')}>
            <SettingOutlined /> Settings
          </Link>
          <Link to={'#'}  onClick={() => {
            Dialog.confirm({
              title: 'Logout',
              content: 'Are you sure you want to logout?',
              confirmText: "Yes",
              cancelText: "No",
              onConfirm: () => {
                logout();
                collapse();
              },
              onCancel: () => {
                console.log('cancel');
              },
            })
          }}>
            <LogoutOutlined /> Logout
          </Link>
        </nav>
      </div>
    </div>
  );
};


