import { Button, Input, NoticeBar } from "antd-mobile";

import { useState } from "react";

import Company from "../../Company";

import { isMobile } from "../../shared/SharedUtils";
import API from "../../shared/API";
import { Link } from "react-router-dom";

import Logo from "../../shared/assets/images/logo.png";
const ForgotPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const signup = () => {
    const api = API.getInstance();

    setError("");

    if (email.length < 5) {
      setError("Invalid email");
      return;
    }
    setLoading(true);

    api
      .forgotPassword(email)
      .then((response: any) => {
        if (response.status === 200) {
          setError("Password reset instructions sent to your email");
        }
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      <div className="form" style={{ width: isMobile() ? "90%" : "45%" }}>
        <img
          src={Logo}
          style={{
            width: "150px",
            display: "block",
            margin: "0 auto",
            marginTop: "150px",
            marginBottom: "40px",
          }}
        />
        <p style={{ textAlign: "center" }}>
          Use this form to request password reset instructions.
        </p>
        <Input
          name="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => {
            setEmail(e);
          }}
        />

        <Button
          onClick={signup}
          loading={loading}
          style={{ width: "100%" }}
          color="primary"
        >
          Request Reset
        </Button>
        {error && (
          <div style={{ margin: "10px 0" }}>
            <NoticeBar content={error} color="alert" closeable />
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "25px",
          }}
        >
          <Link to="/">Log In</Link>
          <Link to="/signup">Signup</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPage;
