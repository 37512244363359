import { useJobCreateContext } from "../JobCreate";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../../../shared/SharedUtils";
import { useEffect, useState } from "react";
import { isAccountValid, isJobValid } from "./Validators";
import FormJob from "../FormJob";
import { User } from "../../../../../shared/Interfaces";
import {useAuth} from "../../../../../shared/providers/AuthProvider";

const StepJob: React.FC = () => {
  const { create, setCreate, job, setJob, account, user, setUser } =
    useJobCreateContext();
  const navigate = useNavigate();

  const {session} = useAuth();

  useEffect(() => {
    if (!isAccountValid(account)) navigate("/jobs/create/step-account");
    if (!job.assigned_to) {
      job.assigned_to = session?.user.id ?? 0;
      job.assigned_to_name = session?.user.name ?? "";
      setJob(job);
    }
  }, []);



  return (
    <div style={{ marginTop: "15px" }}>
      <div>
        <div className={"form"}>
          <FormJob
            loading={false}
            onCancel={() => {
              navigate(-1);
            }}
            onChange={(job) => {
              setJob(job);
            }}
            job={job}
            onSave={(newJob) => {
               console.log(newJob)
              setJob({
                ...job,
                ...newJob,
                id: 0,
              });
              if (isJobValid(newJob)) {
                navigate("/jobs/create/step-dispatch");
              } else {
                toast(
                  "Job information required (pickup address and assigned to).",
                  {
                    type: "error",
                    autoClose: 5000,
                  },
                );
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default StepJob;
