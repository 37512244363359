import Layout from "../../shared/components/Layout";
import React, { useEffect, useState } from "react";
import {
  Account as IAccount,
  FleetUnit as IFleetUnit,
  Impound as IImpound,
  JobListing,
  JobWithMetadata,
  Unit as IUnit,
} from "../../shared/Interfaces";
import { Button, Divider, Input, Result } from "antd-mobile";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { isMobile } from "../../shared/SharedUtils";
import API from "../../shared/API";
import { Loading } from "../../shared/components/Loading";
import Account from "./components/accounts/Account";
import Unit from "./components/units/Unit";
import FleetUnit from "./components/fleetunits/FleetUnit";
import Job from "./components/jobs/Job";
import Impound from "./components/impound/Impound";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CarOutlined,
  EllipsisOutlined,
  GlobalOutlined,
  NumberOutlined,
  SearchOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AxiosError } from "axios";
import Select2 from "../../shared/components/Select2";
import JobSummary from "./components/jobs/JobSummary";
import { Colors } from "../../shared/Colors";
import JobIcon from "./components/jobs/JobIcon";
import { JobType, StatusType } from "../../shared/SharedTypes";
import Chip from "../../shared/components/Chip";
import { statusClassName } from "../../shared/SharedUtils";
import moment from "moment/moment";

const searchableEntities = ["Account", "Unit", "FleetUnit", "Job", "Impound"];

interface JobSearchResultProps extends JobListing {
  onClick: () => void;
}
const JobSearchResult = (props: JobSearchResultProps) => {
  return (
    <div className={"box job-search-result"} onClick={props.onClick}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              marginTop: "15px",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: "1", display: "flex", margin: "5px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                <GlobalOutlined color={Colors.primary} />
              </div>
              <div style={{ flex: 15, marginLeft: "5px" }}>
                {`${props.from_address}`}
              </div>
            </div>
            <div style={{ flex: "1", display: "flex", margin: "5px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                <ArrowRightOutlined color={Colors.primary} />
              </div>
              <div style={{ flex: 15, marginLeft: "5px" }}>
                {`${props.destination_address}`}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              marginTop: "15px",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: "1", display: "flex", margin: "5px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                <CarOutlined color={Colors.primary} />
              </div>
              <div style={{ flex: 15, marginLeft: "5px" }}>
                {`${props.unit_year || ""} ${props.unit_make || ""} ${
                  props.unit_model || ""
                }`}
              </div>
            </div>
            <div style={{ flex: "1", display: "flex", margin: "5px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                <UserOutlined color={Colors.primary} />
              </div>
              <div style={{ flex: 15, marginLeft: "5px" }}>
                {`${props.account_name || ""} ${props.account_phone || ""}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Search: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [entity, setEntity] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [searchType, setSearchType] = useState<"search" | "enter">("search");
  const [jobNumber, setJobNumber] = useState<string>("");

  const { search } = useLocation();

  const navigate = useNavigate();
  const performSearch = (inEntity: number) => {
    if (searchString.length < 3) return;
    let search = searchString;
    let api = API.getInstance();

    setLoading(true);
    setError("");
    setSearchResults([]);
    if (API.getAbortController()) {
      API.getAbortController().abort();
      setLoading(true);
    }

    api
      .search(searchableEntities[inEntity], search)
      .then((result) => {
        setError("");
        setSearchResults(result.data);
        setLoading(false);
        if (result.data.length === 0) {
          throw new Error("No results found");
        }
      })
      .catch((e: AxiosError) => {
        if (e.code !== "ERR_CANCELED") {
          setError(e.message);
          setLoading(false);
        }
      });
  };

  const shouldWeSearch = () => {
    setSearchString(searchParams.get("q") || "");
    if (searchParams.get("entity")) {
      let entity = searchParams.get("entity");
      if (entity === "account") setEntity(0);
      if (entity === "unit") setEntity(1);
      if (entity === "fleetunit") setEntity(2);
      if (entity === "job") setEntity(3);
      if (entity === "impound") setEntity(4);
    }
    if (searchString.length > 2) {
      performSearch(entity);
    }
  };

  useEffect(() => {
    shouldWeSearch();
  }, []);

  useEffect(() => {
    performSearch(entity);
  }, [searchString]);

  useEffect(() => {
    shouldWeSearch();
  }, [search]);

  const elements = () => {
    if (entity === 0) {
      return searchResults.map((account: IAccount, index: number) => {
        return <Account key={index} {...account} />;
      });
    }
    if (entity === 1) {
      return searchResults.map((unit: IUnit, index: number) => {
        return <Unit key={index} {...unit} />;
      });
    }

    if (entity === 2) {
      return searchResults.map((fleetUnit: IFleetUnit, index: number) => {
        return <FleetUnit key={index} {...fleetUnit} />;
      });
    }

    if (entity === 3) {
      return searchResults.map((job: JobListing, index: number) => {
        return (
          <JobSearchResult
            key={index}
            {...job}
            onClick={() => {
              navigate("/jobs/" + job.id);
            }}
          />
        );
      });
    }

    if (entity === 4) {
      return searchResults.map((impound: IImpound, index: number) => {
        return <Impound key={index} {...impound} />;
      });
    }
  };

  const renderResults = () => {
    return (
      <div className={"listing"}>
        <table className={"listing-table"}>
          <tbody>{elements()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <Layout title={"Search"}>
      <div className="z-tabs">
        <div className="z-tabs-wrapper">
          <ul>
            <li
              onClick={() => {
                setSearchType("search");
              }}
              className={searchType === "search" ? "active" : ""}
            >
              <Link to="/jobs/my">
                <SearchOutlined /> Search
              </Link>
            </li>
            <li
              onClick={() => {
                setSearchType("enter");
              }}
              className={searchType === "enter" ? "active" : ""}
            >
              <Link to="/jobs/my">
                <NumberOutlined /> By Number
              </Link>
            </li>
          </ul>
        </div>
        <div className={"z-tabs-content"}>
          {searchType === "search" ? (
            <div style={{ padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile() ? "column" : "row",
                  gap: "5px",
                }}
                className={"form"}
              >
                <div style={{ flex: 1 }}>
                  <Input
                    className={searchString.length < 3 ? "withErrors" : ""}
                    placeholder="Search string"
                    value={searchString}
                    onEnterPress={(e) => {
                      performSearch(entity);
                    }}
                    onChange={(text) => setSearchString(text)}
                  />
                  {searchString.length < 3 && (
                    <span className="error-helper">
                      Query has to be longer than 3.
                    </span>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <Select2
                    value={entity}
                    label={"Search for"}
                    options={searchableEntities}
                    onChange={(entity: any) => {
                      setEntity(entity);
                      setSearchResults([]);
                      performSearch(entity);
                    }}
                  />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      performSearch(entity);
                    }}
                    size="large"
                    block={isMobile()}
                  >
                    <SearchOutlined /> Submit
                  </Button>
                </div>
              </div>
              <div>
                {loading ? <Loading loading={true} /> : renderResults()}
                {error && <Result title={error} status="error" />}
                {searchString.length < 3 && (
                  <Result
                    title="Search string has to be over 3 characters."
                    status="info"
                  />
                )}
              </div>
            </div>
          ) : (
            <div style={{ padding: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile() ? "column" : "row",
                  gap: "5px",
                }}
                className={"form"}
              >
                <div style={{ flex: 4 }}>
                  <Input
                    placeholder="Job Number"
                    value={jobNumber}
                    onChange={(e) => setJobNumber(e)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Button
                    onClick={() => {
                      navigate("/jobs/" + jobNumber);
                    }}
                    block
                    size="large"
                    color="primary"
                  >
                    <SendOutlined />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Search;
