import { Account, Job, Payment, Unit } from "../../../../../shared/Interfaces";

export const isUnitValid = (unit: Unit) => {
  return unit.unit_type !== undefined && unit.make !== undefined;
};

export const isAccountValid = (account: Account) => {
  return (
    account.is_active !== undefined &&
    account.name !== undefined &&
    account.phone !== undefined &&
    account.account_type !== undefined
  );
};

export const isPaymentValid = (payment: Payment) => {
  console.log(payment);
  return payment.payment_type !== undefined && payment.total !== undefined;
};

export const isJobValid = (job: Job) => {
  return (
    job.scheduled_at !== undefined &&
    job.tow_type !== undefined &&
    job.assigned_to !== undefined &&
    job.from_address !== undefined &&
    job.assigned_to !== 0
  );
};
