import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Account as IAccount } from "../../../../shared/Interfaces";
import {
  AccountType,
  COMMON_EMAIL_PROVIDERS,
} from "../../../../shared/SharedTypes";
import { useForm } from "react-hook-form";
import Form from "../../../../shared/components/Form2";
import { generateAutoCompleteEmailList } from "../../../../shared/SharedUtils";
import { PhoneMask } from "../../../../shared/InputMask";

interface FormAccountProps {
  loading: boolean;
  account: IAccount;
  onChange?: (account: IAccount) => void;
  onCancel?: () => void;
  onSave?: (account: IAccount) => void;
  hideButtons?: boolean;
  onSubmitSuccess?: () => void;
}

const FormAccount = forwardRef<HTMLFormElement, FormAccountProps>(
  (props, ref) => {
    const [account, setAccount] = useState<IAccount>({} as IAccount);
    const [emailAutoComplete, setEmailAutoComplete] = useState(
      COMMON_EMAIL_PROVIDERS,
    );

    useEffect(() => {
      setAccount(props.account);
    }, [props.account]);

    const handleFormSubmit = (account: any) => {
      props.onSave && props.onSave(account);
      props.onSubmitSuccess && props.onSubmitSuccess();
    };

    return (
      <Form
        ref={ref}
        initialData={account}
        onSubmit={handleFormSubmit}
        loading={props.loading}
        onCancel={props.onCancel}
        hideButtons={props.hideButtons}
      >
        <Form.Input
          placeholder={"Name"}
          name={"name"}
          rules={{ required: true }}
        />
        <Form.Input
          placeholder={"Phone"}
          name={"phone"}
          rules={{
            required: true,
            min: 12,
            max: 12,
          }}
          transform={PhoneMask}
          type={"tel"}
        />
        <Form.AutoComplete
          placeholder={"Email"}
          name={"email"}
          options={emailAutoComplete}
          onChange={(e: string) => {
            setEmailAutoComplete(generateAutoCompleteEmailList(e));
          }}
          type={"email"}
        />
        <Form.Input placeholder={"Address"} name={"address"} />
        <div style={{  margin: "15px 0" }}>
         <Form.Switch name={"is_active"} label={"Account Enabled"} />
        </div>
        <Form.Select
          label={"Account Type"}
          name="account_type"
          options={AccountType}
        />
        <Form.Input placeholder="Lienholder" name="lienholder" />
        <Form.TextArea label="Notes" name="meta" />
      </Form>
    );
  },
);

FormAccount.defaultProps = {
  hideButtons: false,
};
export default FormAccount;
