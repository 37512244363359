import React from "react";
import { Account as IAccount } from "../../../../shared/Interfaces";
import { AccountType, UnitType } from "../../../../shared/SharedTypes";
import {
  UserOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  BankOutlined,
  PlusOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd-mobile";
import { missingo, isMobile, isNA } from "../../../../shared/SharedUtils";
import Chip from "../../../../shared/components/Chip";
import Avatar from "../../../../shared/components/Avatar";

interface IAccountProps extends IAccount {
  mobileView?: boolean;
  hideButtons?: boolean;
  creatable?: boolean;
  onCreate?: () => void;
  onRefresh?: () => void;
}
const Account: React.FC<IAccountProps> = (props: IAccountProps) => {
  const fixedHeight = props.fixedHeight
    ? { height: props.fixedHeight + "px" }
    : {};
  const { onCreate, onRefresh, ...linkProps } = props;

  const navigate = useNavigate();

  const renderWeb = () => {
    return (
      <tr>
        <td style={{ width: "45px" }}>
          <Avatar initialsOnly name={props.name} size={50} />
        </td>
        <td>
          <UserOutlined />
          {missingo(props.name, "No Name")}
        </td>
        <td>
          <PhoneOutlined />
          {missingo(props.phone, "No Phone")}
        </td>
        <td>
          <MailOutlined />
          {missingo(props.email, "No Email")}
        </td>
        <td>
          <GlobalOutlined />
          {missingo(props.address, "No Address")}
        </td>
        <td>
          <BankOutlined />
          {missingo(props.lienholder, "No Lien holder")}
        </td>
        <td style={{ textAlign: "center" }}>
          <Chip>{AccountType[Number(props.account_type)]}</Chip>
        </td>
        <td style={{ float: "right" }}>
          <Button
            size="small"
            onClick={() => {
              navigate(`/accounts/${props.id}/jobs`);
            }}
          >
            <ForwardOutlined /> Account Jobs
          </Button>
          <Button
            size="small"
            onClick={() => navigate(`/accounts/${props.id}/edit`)}
          >
            <EditOutlined /> Edit
          </Button>
        </td>
      </tr>
    );
  };

  const renderMobile = () => {
    return (
      <div className="box">
        <table className={"inner-table"}>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{missingo(props.name, "No Name")}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                {isNA(props.phone) ? (
                  missingo(props.phone, "No Phone")
                ) : (
                  <a href={`tel:${props.phone}`}>{props.phone}</a>
                )}
              </td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>{missingo(props.email, "No Email")}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {isNA(props.address) ? (
                  missingo(props.address, "No Address")
                ) : (
                  <a
                    href={`https://maps.google.com/?q=${encodeURI(
                      String(props.address),
                    )}`}
                  >
                    {props.address}
                  </a>
                )}
              </td>
            </tr>
            <tr>
              <td>Lien Holder</td>
              <td>{missingo(props.lienholder, "No Lien Holder")}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>
                <Chip>{AccountType[Number(props.account_type)]}</Chip>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p>
            <Button
              block
              onClick={() => navigate(`/accounts/${props.id}/jobs`)}
            >
              <ForwardOutlined /> Account Jobs
            </Button>
          </p>
          <p>
            <Button
              block
              onClick={() => navigate(`/accounts/${props.id}/edit`)}
            >
              <EditOutlined /> Edit
            </Button>
          </p>
        </div>
      </div>
    );
  };

  return isMobile() || props.mobileView ? renderMobile() : renderWeb();
};

Account.defaultProps = {
  editable: true,
};
export default Account;
