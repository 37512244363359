import React, {useEffect, useState} from "react";
import {FleetUnit as IFleetUnit} from "../../../../shared/Interfaces";
import {CALENDAR_FORMAT, FleetUnitType} from "../../../../shared/SharedTypes";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import API from "../../../../shared/API";
import {Loading} from "../../../../shared/components/Loading";
import {Button, DatePicker, Dialog, Form, Input, Picker, Switch, TextArea} from "antd-mobile";
import {ColorMask, PhoneMask, PlateMask, VinMask, YearMask} from "../../../../shared/InputMask";

import moment from "moment";
import FormAccount from "../accounts/FormAccount";
import FormFleetUnit from "./FormFleetUnit";
import {toast} from "../../../../shared/SharedUtils";


const EditFleetUnit: React.FC = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const { id }= useParams();
    const { state } = useLocation();
    const [fleetunit, setFleetUnit] = useState<IFleetUnit>({} as IFleetUnit);
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);
    const [datePicker, setDatePicker] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (state === null) {
            const api = API.getInstance();
            api.fetchFleetUnit(Number(id)).then((res) => {
                setFleetUnit(res.data);
            }).catch((e) => {
                toast("Error fetching fleetunit", {type: "error"},e);
            }).finally(()  => {
                setLoading(false);
            });
        } else {
            setFleetUnit(state);
            setLoading(false);
        }
    }, []);


    const saveEntity = (data: IFleetUnit) => {
        setSending(true);
        const api = API.getInstance();
        api.updateFleetUnit(data).then((res) => {
            Dialog.alert({
                content: "FleetUnit updated successfully",
                onConfirm: () => {
                    navigate("/fleetunits");
                },
                confirmText: "OK"
            });
        }).catch((e) => {
            toast("Error updating fleetunit", {type: "error"},e)
        }).finally(() => {
            setSending(false);
        });
    }

    return  loading ? <Loading /> : <FormFleetUnit fleetunit={fleetunit} loading={sending} onChange={(fleetunit) => { setFleetUnit(fleetunit); }} onCancel={() => { navigate(-1);}}  onSave={saveEntity}/>
}

export default EditFleetUnit;
