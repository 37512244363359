import React, { useContext, useState } from "react";
import {
  Account,
  Job,
  Payment as FPayment,
  Unit,
} from "../../../../shared/Interfaces";
import {
  AccountType,
  COMMON_EMAIL_PROVIDERS,
  JobType,
  PaymentMethod,
} from "../../../../shared/SharedTypes";
import {
  UserOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  EyeOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dialog, Divider, Input, Result } from "antd-mobile";
import API, { API_BASE } from "../../../../shared/API";
import { hid, toast, isMobile } from "../../../../shared/SharedUtils";
import Modal from "../../../../shared/components/Modal";
import { PhoneMask } from "../../../../shared/InputMask";
import AutoComplete from "../../../../shared/components/AutoComplete";
import account from "../accounts/Account";
interface IPayment extends FPayment {
  job?: Job;
  unit?: Unit;
  account?: Account;
  hideButtons?: boolean;
}

const Payment: React.FC<IPayment> = (props: IPayment) => {
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const [phone, setPhone] = useState(props.account?.phone);
  const [email, setEmail] = useState(props.account?.email);
  const [receiptModalShown, setReceiptModalShown] = useState(false);
  const [reviewModalShown, setReviewModalShown] = useState(false);

  const [emailAutoComplete, setEmailAutoComplete] = useState(
    COMMON_EMAIL_PROVIDERS,
  );

  const [sending, setSending] = useState(false);

  const generateAutoComplete = (value: string) => {
    let options = COMMON_EMAIL_PROVIDERS.map((e) => {
      return `${value.replace("@", "")}@${e}`;
    });

    setEmailAutoComplete(options);
  };

  const sendReceipt = () => {
    let api = API.getInstance();
    setSending(true);
    api
      .sendReceipt(Number(props.job?.id), email || "", phone || "")
      .then((res) => {
        setSending(false);
        setReceiptModalShown(false);
        toast("Receipt sent!", { type: "success" });
      })
      .catch((err) => {
        setReceiptModalShown(false);
        toast("Error sending receipt. Try again.", { type: "error" });
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <div className="box">
      {!props.hideButtons && (
        <h3>
          <Link
            style={{ float: "right" }}
            to={`/payments/${props.id}/edit`}
            state={props}
          >
            <EditOutlined />
          </Link>
        </h3>
      )}
      {props.is_paid ? (
        <>
          <Result className={"payment-result"} title="Paid" status="success" />
        </>
      ) : (
        <>
          <Result
            className={"payment-result"}
            title={"Not Paid"}
            description={`But quoted $${props.job?.quoted || "NOTHING"}`}
            status="error"
          />
          {!props.hideButtons && (
            <Button
              block
              fill="outline"
              onClick={() => {
                navigate(`/payments/${props.id}/edit`, { state: props });
              }}
            >
              Collect Payment
            </Button>
          )}
        </>
      )}

      <br />
      {!props.hideButtons && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isMobile() ? "column" : "row",
            }}
          >
            <div
              style={{
                flex: 1,
                marginRight: isMobile() ? "" : "5px",
                marginBottom: isMobile() ? "5px" : "",
              }}
            >
              <Button
                onClick={() => {
                  window.open(
                    API_BASE + "/r/" + hid(Number(props.job?.id)),
                    "_blank",
                  );
                }}
                block
                color="default"
              >
                <EyeOutlined /> See {props.is_paid ? "Receipt" : "Invoice"}
              </Button>
            </div>
            <div style={{ flex: 1 }}>
              <Button
                onClick={() => {
                  setReviewModalShown(true);
                }}
                block
                color="primary"
              >
                <SendOutlined /> Send {props.is_paid ? "Receipt" : "Invoice"}
              </Button>
            </div>
          </div>
          {props.payment_type === 2 && props.is_paid && (
            <a
              target="_blank"
              href="https://www.clover.com/r/{props.job.clover_order_id}"
            >
              See Clover Receipt
            </a>
          )}
        </div>
      )}

      <Modal
        visible={receiptModalShown}
        onClose={() => {
          setReceiptModalShown(false);
        }}
      >
        <div>
          <h3 style={{ textAlign: "center" }}>
            Send {props.is_paid ? "Receipt" : "Invoice"}
          </h3>
          <Input
            value={phone}
            onChange={(e) => {
              setPhone(PhoneMask(e));
            }}
            placeholder={"Phone Number"}
          />
          <Divider>OR / AND</Divider>
          <div style={{ marginTop: "5px" }}>
            <AutoComplete
              options={emailAutoComplete}
              onChange={(e) => {
                setEmail(e);
                generateAutoComplete(e);
              }}
              value={email}
              onSelect={(e) => {
                setEmail(e);
              }}
              placeholder={"Email"}
            />
          </div>
          <br />
          <Button
            loading={sending}
            style={{ marginTop: "5px" }}
            color="primary"
            block
            onClick={() => {
              sendReceipt();
            }}
          >
            Send{" "}
          </Button>
        </div>
      </Modal>
      <Modal
        title={"Review Receipt"}
        onClose={() => {
          setReviewModalShown(false);
        }}
        visible={reviewModalShown}
      >
        <div style={{ maxHeight: "90vh", overflowY: "auto" }}>
          <h3>Review information before sending out please</h3>
          <table className={"bordered-table receipt"}>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{props.account?.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{props.account?.phone}</td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td>Unit</td>
                <td>
                  {props.unit?.year} {props.unit?.make} {props.unit?.model}{" "}
                </td>
              </tr>
              <tr>
                <td>VIN</td>
                <td>{props.unit?.vin}</td>
              </tr>
              <tr>
                <td>License Plate</td>
                <td>{props.unit?.license}</td>
              </tr>
              <tr>
                <td>Color</td>
                <td> {props.unit?.color}</td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td>From</td>
                <td> {props.job?.from_address}</td>
              </tr>
              <tr>
                <td>To</td>
                <td> {props.job?.destination_address}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{JobType[props.job?.tow_type || 0]}</td>
              </tr>
              <tr>
                <td colSpan={2}>&nbsp;</td>
              </tr>
              <tr>
                <td>Paid</td>
                <td>{props.is_paid ? "YES" : "NO"}</td>
              </tr>
              <tr>
                <td>On</td>
                <td>{props.payment_date}</td>
              </tr>
              <tr>
                <td>VIA</td>
                <td>{PaymentMethod[props.payment_type]}</td>
              </tr>
              <tr>
                <td>Total: </td>
                <td>${props.total}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "10px",
          }}
        >
          <div style={{ flex: 1 }}>
            <Button
              block
              onClick={() => {
                setReviewModalShown(false);
                setReceiptModalShown(true);
              }}
              color="primary"
            >
              Looks Good
            </Button>
          </div>
          <div style={{ flex: 1 }}>
            <Button
              block
              onClick={() => {
                setReviewModalShown(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Payment;
