import { useEffect, useState } from "react";
import { decodeJWT } from "./shared/SharedUtils";
import { useAuth } from "./shared/providers/AuthProvider";
import jwt_decode, { JwtPayload } from "jwt-decode";
import moment from "moment";
import { toast, ToastContainer, TypeOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import API from "./shared/API";
import { isMobile } from "./shared/SharedUtils";
import { App } from "@capacitor/app";
import { fetchStored, setStored } from "./shared/AsyncStorage";
import { StatusBar, Style } from "@capacitor/status-bar";
import Firebase2 from "./platform-wrappers/Firebase2";
import { NotificationType } from "./shared/Interfaces";

const Startup = () => {
  const { session } = useAuth();

  const [fcmToken, setFcmToken] = useState<string>("");

  useEffect(() => {}, []);

  const createExpirationNotice = () => {
    if (session) {
      let jwtToken = session.token;
      let decoded = jwt_decode(jwtToken) as JwtPayload;
      if (decoded) {
        let expires = moment(decoded.exp || 0);
        expires.subtract(15, "minutes");
        let now = moment();
        let diff = expires.diff(now, "seconds");
        if (diff > 0) {
          setTimeout(() => {
            alert("Your session will expire in 15 minutes");
          }, diff * 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (session) {
      const api = API.getInstance();

      // Check if we have a list of drivers stored
      fetchStored("drivers").then((drivers) => {
        if (!drivers) {
          api
            .fetchDrivers()
            .then((result) => {
              setStored("drivers", result.data);
            })
            .catch((e) => {
              toast("Failed to load a list of drivers", { type: "error" });
            });
        }
      });

      createExpirationNotice();
    }
  }, [session]);

  useEffect(() => {
    if (!session) return;
    let firebase = new Firebase2();
    let fcmChannel = new BroadcastChannel("fcm-token");

    // When the user logs in, we need to get the FCM token and send it to the server
    fcmChannel.onmessage = (e) => {
      console.log("[SW] Received message from FCM channel: ", e.data);
      let token = e.data;
      let api = API.getInstance();
      if (fcmToken === token) return; // no need to update
      api
        .setUserToken(session?.user.id || 0, token)
        .then((res) => {
          setFcmToken(token);
          console.log("Successfully set user token");
        })
        .catch((e) => {
          alert("Failed to set user token, notifications will not work");
        });
    };

    let notificationChannel = new BroadcastChannel("fcm-message");
    notificationChannel.onmessage = (e) => {
      let title = "Job Updated";
      let message = "A job has been updated";
      let iconType = "info";
      let url = "/";
      switch (e.data.event_type) {
        case NotificationType.JOB_UPDATED:
          title = "Job Updated";
          message = "A job has been updated";
          iconType = "info";
          url = "/jobs/" + e.data.id;
          break;
        case NotificationType.JOB_CREATED:
          title = "New Job";
          message = "A new job has been assigned to you";
          iconType = "success";
          url = "/jobs/" + e.data.id;
          break;
        case NotificationType.JOB_CANCELLED:
          title = "Job Cancelled";
          message = "A job has been cancelled";
          iconType = "error";
          url = "/jobs/" + e.data.id;
          break;
        case NotificationType.JOB_SCHEDULE_REMINDER:
          title = "Job Reminder";
          message = "You have a job scheduled soon";
          iconType = "danger";
          url = "/";
          break;
      }

      toast(
        <div
          onClick={() => {
            return (window.location.href = url);
          }}
        >
          <h2>{title}</h2>
          <p>{message}</p>
        </div>,
        {
          type: iconType as TypeOptions,
          theme: "dark",
          autoClose: false,
        },
      );
    };
  }, [session]);

  useEffect(() => {
    if (isMobile()) {
      App.addListener("backButton", (e) => {
        window.history.back();
      });
    }
    if (isMobile()) {
      addStatusBar().then((e) => {});
    }
  }, []);

  const addStatusBar = async () => {
    await StatusBar.setStyle({ style: Style.Dark });
  };

  useEffect(() => {
    if (session?.token) {
      // Decode the token to get the expiration date
      const decoded = decodeJWT(session.token);
      if (!decoded?.exp) return;
      const expiresAt = new Date(decoded.exp * 1000); // Convert to milliseconds
      const now = new Date();
      const fiveMinutesBefore =
        expiresAt.getTime() - now.getTime() - 5 * 60 * 1000;

      // Only set the timeout if the session has not already expired
      if (fiveMinutesBefore > 0) {
        const timeoutId = setTimeout(() => {
          toast("Your session is about to expire", {
            type: "info",
            theme: "dark",
            autoClose: false,
          });
        }, fiveMinutesBefore);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [session]);

  return (
    <>
      <ToastContainer limit={5} />
    </>
  );
};

export default Startup;
