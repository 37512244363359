import Logo from "../../shared/assets/images/splash.png";
export const TinyHTML = `<!DOCTYPE html>
<html lang="en">
<head>
	<meta charset="UTF-8">
	<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap" rel="stylesheet"> 
<link href="https://api.towingtrack.com/static/mini-default.min.css">
	<style>*{ font-family: "Arimo", sans-serif; }@media print{*,*:before,*:after{text-shadow:none !important;box-shadow:none !important}a:not(.btn){text-decoration:underline}abbr[title]:after{content:" (" attr(title) ")"}pre{white-space:pre-wrap !important}pre,blockquote{border:1px solid #adb5bd;page-break-inside:avoid}tr,img{page-break-inside:avoid}p,h2,h3{orphans:3;widows:3}h2,h3{page-break-after:avoid}@page{size:a3}body{min-width:992px !important}}@media print{@page{margin-top:0;margin-bottom:0}*{font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif}body{padding-top:25px;padding-bottom:72px}}table{width:100%}th{text-align: left;font-size:18px}table,th,td{border: 1px solid;border-collapse:collapse}</style>
</head><body>%s</body></html>`;

export const LaogoHeader = `<div style="display:flex; gap: 10px;margin-bottom: 50px;">
	<div style="flex: 1">
		<h3>{{ company.name }}</h3>
		<p>{{ company.address_line_a }}<br />{{ company.address_line_b }}<br /><strong>{{ company.main_phone }}</strong></p>
	</div>
	{{#qr}}
	<div style="flex: 1">
		<img style="max-width: 150px;" src="{{ company.main_logo_url }}" alt="" />
	</div>
	{{/qr}}
	{{^qr}}
	<div style="margin-left: 0;">
		<img style="max-width: 150px;" src="{{ company.main_logo_url }}" alt="" />
	</div>
	{{/qr}}
	{{#qr}}
	<div style="margin-left: 0; text-align: center">
		<img style="max-width: 150px;" src="{{ job.qr_url }}" alt="" />
		<br /><span><strong >{{ job.hid }}</strong></span>
	</div>
	{{/qr}}
</div>`;


export const LogoHeader = `<table style="border: none; table-layout: fixed;">
	<tbody>
		<tr style="border:none;">
			<td style="border:none; width: 33%;">
				<h4>{{ company.name }}</h4>
				<p>{{ company.address_line_a }}<br />{{ company.address_line_b }}<br /><strong>{{ company.main_phone }}</strong></p>
			</td>
			{{#qr}}
			<td align="center" style=" border: none;width: 33%;">
				
					<img style="max-width: 150px;" src="{{ company.main_logo_url }}" alt="" />
				
			</td>

			<td align="right" style="width: 33%; border:none;">
				<img style="max-width: 150px;" src="{{ job.qr_url }}" alt="" />
				<br /><span style="margin-right: 20px;"><strong >{{ job.hid }}</strong></span>
			</td>

			{{/qr}}
			{{^qr}}
				<td style="border:none;" align="right">
						<img style="max-width: 150px;padding: 20px;" src="{{ company.main_logo_url }}" alt="" />
				</td>
			{{/qr}}
		</tr>
		
	</tbody>
</table>`;

