import React, { useEffect, useState } from "react";
import { Impound as IImpound } from "../../../../shared/Interfaces";

import {
  UserOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../../shared/API";
import { Loading } from "../../../../shared/components/Loading";
import {
  Button,
  Dialog,
  Form,
  Input,
  Picker,
  Switch,
  TextArea,
} from "antd-mobile";
import { PhoneMask } from "../../../../shared/InputMask";
import FormImpound from "./FormImpound";
import {toast} from "../../../../shared/SharedUtils";

const EditImpound: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { state } = useLocation();
  const [impound, setImpound] = useState<IImpound>({} as IImpound);
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (state === null) {
      const api = API.getInstance();
      api
        .fetchImpound(Number(id))
        .then((res) => {
          setImpound(res.data);
        })
        .catch((e) => {
            toast("Error fetching impound", { type: "error" }, e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setImpound(state);
      setLoading(false);
    }
  }, []);

  const saveEntity = (data: IImpound) => {
    setSending(true);
    const api = API.getInstance();


    api
      .updateImpound(data)
      .then((res) => {
        Dialog.alert({
          content: "Impound updated successfully",
          onConfirm: () => {
            navigate("/impounds");
          },
          confirmText: "OK",
        });
      })
      .catch((e) => {
        toast("Error updating impound", { type: "error" }, e);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <FormImpound
      impound={impound}
      loading={sending}
      onChange={(impound) => {
        setImpound(impound);
      }}
      onCancel={() => {
        navigate(-1);
      }}
      onSave={saveEntity}
    />
  );
};

export default EditImpound;
