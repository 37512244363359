import React from "react";
import {DotLoading, Skeleton} from "antd-mobile";
import {LoadingOutlined} from "@ant-design/icons";

export interface LoadingProps {
    loading?: boolean;
}

const OverlayLoading = () => (
    <div
        style={{
            width: "100%",
            height: "100%",
            zIndex: "999999",
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            top: 0,
            left: 0,
        }}
    >
        <div style={{position: "fixed", top: "48%", left: "48%"}}>
            <LoadingOutlined style={{fontSize: "50px", color: "var(--primary)"}}/>
        </div>
    </div>
);

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
    return props.loading ? (
        <span
            style={{
                fontSize: 24,
                display: "block",
                margin: "0 auto",
                width: "50px",
            }}
        >
      <DotLoading/>
    </span>
    ) : (
        <></>
    );
};

export interface LabelledLoadingProps {
    loading?: boolean;
    label?: string;
}

const LabelledLoading: React.FC<LabelledLoadingProps> = (
    props: LabelledLoadingProps,
) => {
    return props.loading ? (
        <div>
            <p style={{textAlign: "center", color: "#9f9f9f", fontStyle: "italic"}}>
                Loading {props.label}
            </p>
            <span
                style={{
                    fontSize: 24,
                    display: "block",
                    margin: "0 auto",
                    width: "50px",
                }}
            >
        <DotLoading/>
      </span>
            <Skeleton.Paragraph animated/>
            <Skeleton.Paragraph animated/>
        </div>
    ) : (
        <></>
    );
};
LabelledLoading.defaultProps = {
    loading: true,
    label: "",
};

export interface MultiLoadingProps {
    loading: {};
}

const MultiLoading: React.FC<MultiLoadingProps> = (props: MultiLoadingProps) => {
    return (Object.values(props.loading).some(value => value === true)) ? (
        <span
            style={{
                fontSize: 24,
                display: "block",
                margin: "0 auto",
                width: "50px",
            }}
        >
      <DotLoading/>
    </span>
    ) : (
        <></>
    );
}


export interface SectionalLoadingProps {
}

const SectionalLoading: React.FC<SectionalLoadingProps> = (props: SectionalLoadingProps) => {
    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            fontSize: "64px", color: "var(--primary)",
            margin: "-10px"
        }}>
            <LoadingOutlined/>
        </div>
    )
}

export {Loading, OverlayLoading, LabelledLoading, MultiLoading, SectionalLoading};
