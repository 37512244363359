import React, { forwardRef, useEffect, useState } from "react";
import { Unit as IUnit } from "../../../../shared/Interfaces";
import { Button, Dialog, Input } from "antd-mobile";
import {
  getModelsFromBrand,
  Makes,
  toast,
} from "../../../../shared/SharedUtils";
import { BarcodeOutlined, LoadingOutlined } from "@ant-design/icons";
import API from "../../../../shared/API";
import Form from "../../../../shared/components/Form2";
import { UnitType } from "../../../../shared/SharedTypes";
import { ColorMask, PlateMask, YearMask } from "../../../../shared/InputMask";

interface FormUnitProps {
  loading: boolean;
  unit: IUnit;
  onChange: (unit: IUnit) => void;
  onCancel?: () => void;
  onSave?: (unit: IUnit) => void;
  hideButtons?: boolean;
  onSubmitSuccess?: () => void;
}

interface DecodedVin {
  make?: string;
  model?: string;
  year?: string;
  error?: string;
}
const FormUnit = forwardRef<HTMLFormElement, FormUnitProps>((props, ref) => {
  const [unit, setUnit] = useState<IUnit>({} as IUnit);

  const [vinDecodingLoading, setVinDecodingLoading] = useState<boolean>(false);
  const [models, setModels] = useState<string[]>([]);

  const [formExternalData, setFormExternalData] = useState<any>({} as any);

  useEffect(() => {
    setUnit(props.unit);
  }, [props.unit]);

  const decodeVin = () => {
    const vin = formExternalData.vin || "";
    if (vin.length < 8) return;
    let api = API.getInstance();
    setVinDecodingLoading(true);
    api
      .decodeVIN(vin)
      .then((res) => {
        if (res.status === 200) {
          let data: DecodedVin = res.data;
          if (data.error) {
            toast("VIN could not be decoded", {
              type: "error",
              autoClose: 5000,
            });
          } else {
            setFormExternalData({
              make: data.make?.toUpperCase() || "",
              model: data.model?.toUpperCase() || "",
              year: data.year || "",
            });
          }
        }
      })
      .catch((err) => {
        console.log(`[ERR] `, err);
        // fail silently
      })
      .finally(() => {
        setVinDecodingLoading(false);
      });
  };

  return (
    <Form
      ref={ref}
      initialData={unit}
      onSubmit={(unit) => {
        props.onSave && props.onSave(unit);
        props.onSubmitSuccess && props.onSubmitSuccess();
      }}
      loading={props.loading}
      onCancel={props.onCancel}
      hideButtons={props.hideButtons}
      externalData={formExternalData}
    >
      <Form.Input
        placeholder="Year"
        name="year"
        rules={{ min: 4, max: 4 }}
        transform={YearMask}
      />
      <Form.AutoComplete
        placeholder="Make"
        name="make"
        options={Makes}
        rules={{
          required: true,
        }}
        onChange={(e) => {
          setModels(getModelsFromBrand(e));
        }}
        uppercase
      />
      <Form.AutoComplete
        placeholder="Model"
        name="model"
        uppercase
        options={models}
      />
      <Form.Input
        placeholder="Color"
        name="color"
        transform={ColorMask}
        uppercase
      />
      <Form.Input
        placeholder="Plate"
        name="license"
        transform={PlateMask}
        uppercase
      />
      <span style={{ color: "gray" }}>
        State Code - License Plate (eg. AK-ABC123, NE-CDF444)
      </span>
      <div style={{ display: "flex", gap: "5px" }}>
        <div style={{ flex: 11 }}>
          <Form.Input
            name="vin"
            className="monospace"
            placeholder="VIN"
            uppercase
            rules={{
              min: 8,
            }}
            onChange={(vin) => {
              setFormExternalData({ vin: vin });
            }}
          />
        </div>
        <div title="Decode VIN" style={{ flex: 1 }}>
          <Button
            onClick={() => {
              decodeVin();
            }}
            disabled={vinDecodingLoading}
            style={{ height: "50px" }}
            block
            fill="none"
          >
            {vinDecodingLoading ? <LoadingOutlined /> : <BarcodeOutlined />}
          </Button>
        </div>
      </div>
      <Form.Input placeholder="Current Location" name="current_location" />
      <Form.Select label="Type" options={UnitType} name="unit_type" />
      <Form.TextArea label="Notes" name="meta" />
    </Form>
  );
});

FormUnit.defaultProps = {
  hideButtons: false,
};
export default FormUnit;
