import React, { useEffect, useState } from "react";
import { FleetUnit as IFleetUnit } from "../../../../shared/Interfaces";
import {
  CALENDAR_DT_INPUT_FORMAT,
  FleetUnitType,
} from "../../../../shared/SharedTypes";
import { Button, Input } from "antd-mobile";

import Form, { FormButton } from "../../../../shared/components/Form2";
import moment from "moment";
import {
  ColorMask,
  PlateMask,
  VinMask,
  YearMask,
} from "../../../../shared/InputMask";

interface FormFleetUnitProps {
  loading: boolean;
  fleetunit: IFleetUnit;
  onChange: (fleetunit: IFleetUnit) => void;
  onCancel: () => void;
  onSave: (fleetunit: IFleetUnit) => void;
}

const FormFleetUnit: React.FC<FormFleetUnitProps> = (
  props: FormFleetUnitProps,
) => {
  const [fleetunit, setFleetUnit] = useState<IFleetUnit>({} as IFleetUnit);

  useEffect(() => {
    if (!props.fleetunit.registration_expires)
      props.fleetunit.registration_expires = moment().format(
        CALENDAR_DT_INPUT_FORMAT,
      );
    setFleetUnit(props.fleetunit);
  }, [props.fleetunit]);

  return (
    <Form
      initialData={fleetunit}
      onSubmit={(data) => {
        props.onSave && props.onSave(data);
      }}
      loading={props.loading}
      onCancel={props.onCancel}
    >
      <Form.Input
        placeholder="Year"
        name="year"
        rules={{ min: 4, max: 4 }}
        transform={YearMask}
      />
      <Form.Input
        placeholder="Make"
        name="make"
        rules={{ required: true }}
        uppercase
      />
      <Form.Input placeholder="Model" name="model" uppercase />
      <Form.Input
        placeholder="Color"
        name="color"
        uppercase
        transform={ColorMask}
      />
      <Form.Input
        placeholder="VIN"
        name="vin"
        rules={{ min: 8 }}
        transform={VinMask}
      />
      <Form.Input placeholder="Plate" name="license" transform={PlateMask} />
      <span style={{ color: "gray" }}>
        State Code - License Plate (eg. AK-ABC123, NE-CDF444)
      </span>
      <Form.Input
        placeholder="ODO"
        name="odo"
        type="text"
        rules={{
          pattern: /^[0-9]*$/gi,
        }}
      />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Form.Input
            transform={(value: string) => {
              return moment(value).format(CALENDAR_DT_INPUT_FORMAT);
            }}
            name="registration_expires"
            placeholder={"Registration Expiration"}
            type="datetime-local"
          />
        </div>
        <div style={{ flex: 0, marginLeft: "15px" }}>
          <FormButton
            name="registration_expires"
            value={moment().format(CALENDAR_DT_INPUT_FORMAT)}
            size="large"
          >
            Today
          </FormButton>
        </div>
      </div>
      <Form.Select label="Unit Type" name="unit_type" options={FleetUnitType} />
      <Form.TextArea label="Notes" name="meta" />
    </Form>
  );
};

export default FormFleetUnit;
