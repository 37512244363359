import React, { useEffect, useState } from "react";
import { User as IUser } from "../../../../shared/Interfaces";
import {
  UserOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../../shared/API";
import { Loading } from "../../../../shared/components/Loading";
import {
  Button,
  Dialog,
  Form,
  Input,
  Picker,
  Switch,
  TextArea,
} from "antd-mobile";
import { PhoneMask } from "../../../../shared/InputMask";
import FormUser from "./FormUser";
import {Role} from "../../../../shared/providers/AuthProvider";
import {toast} from "../../../../shared/SharedUtils";

const namedRoles: string[] = [];

const _roles = Object.values(Role).filter((item) => {
  return typeof item !== "number";
});
for (let i = 0; i < _roles.length; i++) {
  namedRoles.push(_roles[i] as string);
}

const EditUser: React.FC = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { state } = useLocation();
  const [user, setUser] = useState<IUser>({} as IUser);
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (state === null) {
      const api = API.getInstance();
      api
        .fetchUser(Number(id))
        .then((res) => {
          setUser(res.data);
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setUser(state);
      setLoading(false);
    }
  }, []);



  const saveEntity = (data: IUser) => {
    setSending(true);
    const api = API.getInstance();
    user.password_digest = "";
    api
      .updateUser(data)
      .then((res) => {
        Dialog.alert({
          content: "User updated successfully",
          onConfirm: () => {
            navigate("/users");
          },
          confirmText: "OK",
        });
      })
      .catch((e) => {
        toast("Error updating user", { type: "error" }, e)
      })
      .finally(() => {
        setSending(false);
      });
  };

  return loading ? (
    <Loading />
  ) : (
    <FormUser
        create={false}
      loading={loading}
      user={user}
      onChange={(user) => {
        setUser(user);
      }}
      onCancel={() => {
        navigate(-1);
      }}
      onSave={saveEntity}
    />
  );
};

export default EditUser;
