import {Message as IMessage} from "../Interfaces";
import {CloseOutlined, WarningOutlined} from "@ant-design/icons";
import Button from "antd-mobile/es/components/button";
import API from "../API";
import {useAuth} from "../providers/AuthProvider";
import {useState} from "react";
import {toast} from "../SharedUtils";

interface MessageProps extends Partial<IMessage> {
    onDismiss?: () => void;
}

const Message: React.FC<MessageProps> = (props) => {
    const {session} = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const dismissMessage = () => {
        const api = API.getInstance();
        const user_id = session?.user.id || false;
        const message_id = props.id || false;
        if (!user_id || !message_id) return;
        setLoading(true);
        api.patchMessage(message_id, {dismissed_by: user_id}).then((res) => {
        }).catch((err) => {
            toast(err.message, {type: "error"});
        }).finally(() => {
            setLoading(false);
             props.onDismiss && props.onDismiss();
        })
    }
    return (
        <div className={'message-alert'}>
             <div className={'message-alert__icon'}><WarningOutlined /></div>
            <div className={'message-alert__content'}>{props.message}</div>
            <div className={'message-alert__dismiss'}>
                <Button loading={loading} fill="none" onClick={dismissMessage}>Dismiss <CloseOutlined /></Button>
            </div>
        </div>
    )
}
export default Message;
