import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Account as IAccount,
  Job as IJob,
  JobWithMetadata,
  Payment as IPayment,
  Unit as IUnit,
  User as IUser,
} from "../../../../shared/Interfaces";
import { CALENDAR_DT_INPUT_FORMAT } from "../../../../shared/SharedTypes";
import { Outlet, useLocation } from "react-router-dom";
import { OverlayLoading } from "../../../../shared/components/Loading";
import { Divider, Steps } from "antd-mobile";
import Layout from "../../../../shared/components/Layout";
import moment from "moment";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import { isMobile } from "../../../../shared/SharedUtils";

const { Step } = Steps;

export const RequiredAccountFields = {
  is_active: true,
  account_type: 0,
} as IAccount;

export const RequiredUnitFields = {
  unit_type: 0,
  vin: null,
} as IUnit;

export const RequiredPaymentFields = {
  payment_type: 0,
  total: "0.00",
  is_paid: false,
} as IPayment;

export const RequiredJobFields = {
  scheduled_at: moment().format(CALENDAR_DT_INPUT_FORMAT),
  assigned_to: 0,
  tow_type: 0,
  category: 0,
  status: 0,
} as IJob;

interface CreateMode {
  isAccountCreateMode: boolean;
  isUnitCreateMode: boolean;
}

interface IJobCreateContext {
  job: JobWithMetadata;
  setJob: Dispatch<SetStateAction<JobWithMetadata>>;
  account: IAccount;
  setAccount: Dispatch<SetStateAction<IAccount>>;
  unit: IUnit;
  setUnit: Dispatch<SetStateAction<IUnit>>;
  payment: IPayment;
  setPayment: Dispatch<SetStateAction<IPayment>>;
  user: IUser;
  setUser: Dispatch<SetStateAction<IUser>>;
  create: CreateMode;
  setCreate: Dispatch<SetStateAction<CreateMode>>;
}

const JobCreateContext = createContext<IJobCreateContext | null>(null);
export const useJobCreateContext = () => {
  const context = useContext(JobCreateContext);
  if (!context) {
    throw new Error(
      "useJobCreateContext must be used within a JobCreateContext.Provider",
    );
  }
  return context;
};

const JobCreate: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const { session } = useAuth();

  const [job, setJob] = useState<JobWithMetadata>(RequiredJobFields);

  const [account, setAccount] = useState<IAccount>(RequiredAccountFields);
  const [unit, setUnit] = useState<IUnit>(RequiredUnitFields);
  const [payment, setPayment] = useState<IPayment>(RequiredPaymentFields);
  const [user, setUser] = useState<IUser>(session?.user || ({} as IUser));

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [create, setCreate] = useState<CreateMode>({
    isAccountCreateMode: false,
    isUnitCreateMode: false,
  });

  useEffect(() => {
    const handleUnload = (e: any) => {
      if (account.id !== 0) {
        const confirmMessage =
          "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = confirmMessage; // for older browsers
        return confirmMessage;
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  const steps = [
    "step-account",
    "step-unit",
    "step-payment",
    "step-job",
    "step-dispatch",
  ];

  useEffect(() => {
    const currentStep = location.pathname.split("/").pop();
    setCurrentStep(steps.indexOf(currentStep ? currentStep : "step-account"));
  }, [location]);

  return (
    <Layout title="Create Job">
      <Steps current={currentStep}>
        <Step
          title="Account"
          description={isMobile() ? "" : "Create or select an account"}
        />
        <Step
          title="Unit"
          description={isMobile() ? "" : "Create or select a unit"}
        />
        <Step
          title="Payment"
          description={isMobile() ? "" : "Fill in payment information"}
        />
        <Step
          title="Job"
          description={isMobile() ? "" : "Fill in job information"}
        />
        <Step
          title="Dispatch"
          description={isMobile() ? "" : "Review and dispatch job"}
        />
      </Steps>

      <JobCreateContext.Provider
        value={{
          job,
          setJob,
          account,
          setAccount,
          unit,
          setUnit,
          payment,
          setPayment,
          create,
          setCreate,
          user,
          setUser,
        }}
      >
        <Outlet />
      </JobCreateContext.Provider>

      {loading && <OverlayLoading />}
    </Layout>
  );
};

export default JobCreate;
