import Layout from "../../shared/components/Layout";
import { useContext, useEffect, useState } from "react";
import API from "../../shared/API";
import {
  DotLoading,
  FloatingBubble,
  InfiniteScroll,
  NoticeBar,
  SearchBar,
} from "antd-mobile";
import Account from "./components/accounts/Account";
import Unit from "./components/units/Unit";
import FleetUnit from "./components/fleetunits/FleetUnit";
import User from "./components/users/User";
import Impound from "./components/impound/Impound";
import JobSummary from "./components/jobs/JobSummary";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/providers/AuthProvider";
import { isMobile } from "../../shared/SharedUtils";

interface ListingProps {
  type: "Account" | "Unit" | "FleetUnit" | "User" | "Job" | "Impound";
}

const Listing: React.FC<ListingProps> = (props: ListingProps) => {
  const [pageTitle, setPageTitle] = useState<string>(props.type + "s");
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);

  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [items, setItems] = useState<any[]>([]);
  const [error, setError] = useState<string>("");
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [IType, setIType] = useState(props.type);

  const [api, _] = useState<API>(API.getInstance());
  const { hasPerms } = useAuth();
  const navigate = useNavigate();

  const ApiCalls = {
    Account: "fetchAccounts",
    Unit: "fetchUnits",
    FleetUnit: "fetchFleetUnits",
    User: "fetchUsers",
    Job: "fetchJobs",
    Impound: "fetchImpounds",
  };

  const makeApiCall = () => {
    api
      .getApiCallByName(ApiCalls[props.type] as keyof API, skip)
      .then((res) => {
        if (res.status === 200) {
          setItems([...items, ...res.data]);

          setSkip(skip + 25);
          setHasMore(res.data.length > 0);
        } else {
          setError(res.message);
          setHasMore(false);
        }
      })
      .catch((e) => {
        setHasMore(false);
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setItems([]);
    setIType(props.type);
    setPageTitle(props.type + "s");
  }, [props.type]);

  const loadMore = async () => {
    makeApiCall();
  };

  const renderFragment = (item: any) => {
    if (IType === "Account") {
      return <Account key={item.id} {...item} />;
    }
    if (IType === "Unit") {
      return <Unit key={item.id} {...item} />;
    }

    if (IType === "FleetUnit") {
      return <FleetUnit key={item.id} {...item} />;
    }

    if (IType === "User") {
      return <User key={item.id} {...item} />;
    }
    if (IType === "Impound") {
      return <Impound key={item.id} {...item} />;
    }
    if (IType === "Job") {
      return <JobSummary key={item.id} {...item} />;
    }
  };

  const searchEntity = (value: string) => {
    navigate(`/search/?q=${value}&entity=${IType.toLowerCase()}`);
  };

  const renderWebListingHead = () => {
    if (props.type === "Account")
      return (
        <thead>
          <tr>
            <td style={{ width: "45px" }}>&nbsp;</td>
            <td>Name</td>
            <td>Phone</td>
            <td>E-mail</td>
            <td>Address</td>
            <td>Lien Holder</td>
            <td style={{ textAlign: "center" }}>Type</td>
            <td style={{ textAlign: "center" }}>Actions</td>
          </tr>
        </thead>
      );

    if (props.type === "Unit") {
      return (
        <thead>
          <tr>
            <td style={{ width: "60px" }}>Year</td>
            <td>Make</td>
            <td>Model</td>
            <td>License</td>
            <td>VIN</td>
            <td>Color</td>
            <td style={{ textAlign: "center" }}>Type</td>
            <td>Current Address</td>
            <td style={{ textAlign: "center" }}>Actions</td>
          </tr>
        </thead>
      );
    }

    if (props.type === "FleetUnit") {
      return (
        <thead>
          <tr>
            <td style={{ width: "60px" }}>Year</td>
            <td>Make</td>
            <td>Model</td>
            <td>License</td>
            <td>VIN</td>
            <td>Color</td>
            <td style={{ textAlign: "center" }}>Type</td>
            <td>Odometer</td>
            <td>Registration Expires</td>
            <td style={{ textAlign: "center" }}>Actions</td>
          </tr>
        </thead>
      );
    }

    if (props.type === "User")
      return (
        <thead>
          <tr>
            <td style={{ width: "45px" }}>&nbsp;</td>
            <td>Name</td>
            <td>Phone</td>
            <td>E-mail</td>

            <td style={{ textAlign: "center" }}>Active</td>
            <td style={{ textAlign: "center" }}>Actions</td>
          </tr>
        </thead>
      );
  };

  return (
    <Layout title={pageTitle}>
      <div className={"listing-search"}>
        <SearchBar placeholder="Search" onSearch={searchEntity} />
      </div>
      <div className="listing">
        {isMobile() ? (
          <div>
            {items.map((item: any) => {
              return renderFragment(item);
            })}
          </div>
        ) : (
          <div style={{ marginTop: "15px" }}>
            <table className={"listing-table"}>
              {renderWebListingHead()}
              <tbody>
                {items.map((item: any) => {
                  return renderFragment(item);
                })}
              </tbody>
            </table>
          </div>
        )}
        {hasPerms() && (
          <>
            {(IType === "FleetUnit" ||
              IType === "Impound" ||
              IType === "User") && (
              <FloatingBubble
                style={{
                  "--initial-position-bottom": "24px",
                  "--initial-position-right": "24px",
                  "--edge-distance": "24px",
                }}
                axis={"xy"}
                magnetic={"x"}
              >
                <PlusOutlined
                  onClick={() => {
                    navigate("/" + props.type.toLowerCase() + "s/create");
                  }}
                  style={{ fontSize: "32px" }}
                />
              </FloatingBubble>
            )}
          </>
        )}

        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          {hasMore && (
            <>
              <span>Loading</span>
              <DotLoading />
            </>
          )}
        </InfiniteScroll>

        {error && (
          <div style={{ margin: "10px 0" }}>
            <NoticeBar content={error} color="alert" />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Listing;
