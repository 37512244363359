import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "../SharedUtils";
import { Button, CenterPopup, Popup } from "antd-mobile";
import { CloseOutlined } from "@ant-design/icons";
import chip from "./Chip";

interface ModalProps {
  visible: boolean;
  children: any;
  onClose: () => void;
  title?: string;
  style?: {};
  mobileHeight?: string;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const [visible, setVisible] = useState<boolean>(props.visible);
  const [mobileHeight, setMobileHeight] = useState<string>(
    props.mobileHeight || "auto",
  );

  useEffect(() => {
    setMobileHeight(props.mobileHeight || "auto");
  }, [props.mobileHeight]);

  useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);

  return isMobile() ? (
    <Popup
      position={"bottom"}
      onClose={props.onClose}
      onMaskClick={props.onClose}
      visible={props.visible}
      bodyStyle={{ height: mobileHeight }}
      showCloseButton
      closeOnSwipe
      closeOnMaskClick
      maskStyle={{ background: "rgba(0,0,0,0.9)" }}
    >
      <div style={{ padding: "10px" }}>
        <div style={props.style ? { ...props.style } : {}}>
          <h3>{props.title}</h3>
          {props.children}
        </div>
      </div>
    </Popup>
  ) : (
    <CenterPopup
      visible={props.visible}
      onClose={props.onClose}
      onMaskClick={props.onClose}
      showCloseButton
      closeOnMaskClick
      maskStyle={{ background: "rgba(0,0,0,0.9)" }}
    >
      <div style={{ padding: "25px" }} className={"form"}>
        <div style={props.style ? { ...props.style } : {}}>
          <h3>{props.title}</h3>
          {props.children}
        </div>
      </div>
    </CenterPopup>
  );
};
export default Modal;
