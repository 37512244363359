import React, { useContext, useEffect, useState } from "react";
import { User as IUser } from "../../../../shared/Interfaces";
import { COMMON_EMAIL_PROVIDERS } from "../../../../shared/SharedTypes";
import API from "../../../../shared/API";
import { Dialog, Divider } from "antd-mobile";
import { Role, useAuth } from "../../../../shared/providers/AuthProvider";

import Form from "../../../../shared/components/Form2";
import {
  generateAutoCompleteEmailList,
  toast,
} from "../../../../shared/SharedUtils";
import Button from "antd-mobile/es/components/button";
import { LockOutlined } from "@ant-design/icons";
import { PhoneMask } from "../../../../shared/InputMask";

const namedRoles: string[] = [];

const _roles = Object.values(Role).filter((item) => {
  return typeof item !== "number";
});
for (let i = 0; i < _roles.length; i++) {
  namedRoles.push(_roles[i] as string);
}

interface FormUserProps {
  loading: boolean;
  user: IUser;
  create: boolean;
  onChange: (user: IUser) => void;
  onCancel: () => void;
  onSave: (user: IUser) => void;
}

const FormUser: React.FC<FormUserProps> = (props: FormUserProps) => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [emailAutoComplete, setEmailAutoComplete] = useState(
    COMMON_EMAIL_PROVIDERS,
  );

  const { hasPerms } = useAuth();

  const [loading, setLoading] = useState(false);

  const resetPassword = () => {
    Dialog.confirm({
      title: "Reset Password",
      content: "Are you sure you want to reset this users password?",
      confirmText: "Yes",
      cancelText: "No",
      onCancel: () => {},
      onConfirm: () => {
        setLoading(true);
        let api = API.getInstance();
        api
          .forgotPassword(user.email)
          .then((response: any) => {
            if (response.status === 200) {
              toast("Password reset instructions sent to users email", {
                type: "success",
              });
            }
          })
          .catch((e) => {
            toast("Error resetting password", { type: "error" });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  return (
    <>
      <Form
        initialData={user}
        onSubmit={props.onSave}
        loading={props.loading}
        onCancel={props.onCancel}
      >
        <Form.Input
          placeholder={"Name"}
          name={"name"}
          rules={{
            required: true,
          }}
        />
        <Form.Input
          placeholder={"Phone"}
          name={"phone"}
          rules={{
            required: true,
            min: 12,
          }}
          transform={PhoneMask}
          type={"tel"}
        />
        <Form.AutoComplete
          placeholder={"Email"}
          minLength={4}
          rules={{
            required: true,
          }}
          name={"email"}
          options={emailAutoComplete}
          onChange={(value: string) => {
            setEmailAutoComplete(generateAutoCompleteEmailList(value));
          }}
          type={"email"}
        />
        {hasPerms() && (
          <div>
            <div style={{  margin: "15px 0" }}>

                <Form.Switch name="is_active"  label={"User Enabled"}/>

            </div>
            <Form.Select label={"Role"} name="role" options={namedRoles} />

            <Form.TextArea label="Notes" name="meta" />
          </div>
        )}
      </Form>
      {(!props.create && hasPerms()) && (
        <>
          <Divider>OR</Divider>
          <Button
            onClick={() => {
              resetPassword();
            }}
            block
            color="danger"
            loading={loading}
          >
            Reset Password <LockOutlined />
          </Button>
        </>
      )}
    </>
  );
};

export default FormUser;
