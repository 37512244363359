import { Capacitor } from "@capacitor/core";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { PushNotifications } from "@capacitor/push-notifications";
import { INotification, NotificationType } from "../shared/Interfaces";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
};

class Firebase2 {
  public isMobile = Capacitor.isNativePlatform();

  public fcmChannel = new BroadcastChannel("fcm-token");
  public messageChannel = new BroadcastChannel("fcm-message");

  constructor() {
    if (this.isMobile) {
      this.initNative();
    } else {
      this.initWeb();
    }
  }

  public initWeb() {
    console.log("we are called");
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
        }).then((token) => {
          console.log("[asdsad] ", token);
          if (token) {
            this.fcmChannel.postMessage(token);
          } else {
            console.log(
              "No registration token available. Request permission to generate one.",
            );
          }
        });
      }
    });

    onMessage(messaging, (payload) => {
      this.messageChannel.postMessage(this.normalizeMessage(payload));
      console.log(
        "[firebase-messaging-sw.js] Received background message ",
        payload,
      );
    });
  }

  public initNative() {
    PushNotifications.checkPermissions().then((permStatus) => {
      if (permStatus.receive === "prompt") {
        PushNotifications.requestPermissions().then((permStatus) => {
          if (permStatus.receive === "granted") {
            this.nativeAddListeners();
          }

          if (permStatus.receive !== "granted") {
            alert(
              "User denied permissions! Notifications will not be received.",
            );
          }
        });
      }

      if (permStatus.receive === "granted") {
        this.nativeAddListeners();
      }
    });
  }

  public nativeAddListeners() {
    PushNotifications.register().then((token) => {
      PushNotifications.addListener("registration", (token) => {
        console.log("FCM Token", token.value);
        this.fcmChannel.postMessage(token.value);
      });
    });
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        this.messageChannel.postMessage(this.normalizeMessage(notification));
      },
    );
  }

  public normalizeMessage(message: any): INotification {
    let data = !this.isMobile ? message.data : message.body;
    return {
      event_type: data.event_type || NotificationType.JOB_UPDATED,
      data: data.data || {},
      title: data.title || "",
      body: data.body || {},
      id: data.id || "",
    };
  }
}

export default Firebase2;
