import React from "react";

import "./App.css";
import { AuthProvider } from "./shared/providers/AuthProvider";
import Navigator from "./shared/Navigator";
import { ConfigProvider } from "antd-mobile";
import enUS from "antd-mobile/es/locales/en-US";
import Startup from "./Startup";
import { App as CapApp } from "@capacitor/app";

import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater";
import { SplashScreen } from "@capacitor/splash-screen";
import { AppUpdater } from "./AppUpdater";

CapacitorUpdater.notifyAppReady().then((e) => {
  console.log(e);
});

CapApp.addListener("appStateChange", (state) => {
  let updater = new AppUpdater(state);
});

function App() {
  return (
    <AuthProvider>
      <ConfigProvider locale={enUS}>
        <Startup />
        <Navigator />
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
