import React from "react";
import { User as IUser } from "../../../../shared/Interfaces";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  ForwardOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Role } from "../../../../shared/providers/AuthProvider";
import { isMobile, isNA, missingo } from "../../../../shared/SharedUtils";
import Chip from "../../../../shared/components/Chip";
import { UnitType } from "../../../../shared/SharedTypes";
import { Button } from "antd-mobile";
import Avatar from "../../../../shared/components/Avatar";

export const RequiredUserFields = {
  name: "",
  email: "",
  phone: "",
  role: 0,
  is_active: true,
} as IUser;
const User: React.FC<IUser> = (props: IUser) => {
  const navigate = useNavigate();

  const renderMobile = () => {
    return (
      <div className="box">
        <table className={"listing-table condensed"}>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{missingo(props.name, "No Name")}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                {isNA(props.phone) ? (
                  missingo(props.phone, "No Phone")
                ) : (
                  <a href={`tel:${props.phone}`}>{props.phone}</a>
                )}
              </td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>{missingo(props.email, "No Email")}</td>
            </tr>

            <tr>
              <td>Active</td>
              <td>
                <Chip>{props.is_active ? "YES" : "NO"}</Chip>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <p>
            <Button block onClick={() => navigate(`/users/${props.id}/edit`)}>
              <EditOutlined /> Edit
            </Button>
          </p>
        </div>
      </div>
    );
  };

  const renderWeb = () => {
    return (
      <tr>
        <td style={{ width: "45px" }}>
          <Avatar initialsOnly name={props.name} size={50} />
        </td>
        <td>{missingo(props.name, "No Name")}</td>
        <td>
          {isNA(props.phone) ? (
            missingo(props.phone, "No Phone")
          ) : (
            <a href={`tel:${props.phone}`}>{props.phone}</a>
          )}
        </td>
        <td>{missingo(props.email, "No E-mail")}</td>
        <td style={{ textAlign: "center" }}>
          {props.is_active ? (
            <Chip type={"success"}>Active</Chip>
          ) : (
            <Chip type={"danger"}>Inactive</Chip>
          )}
        </td>

        <td style={{ textAlign: "center" }}>
          <Button
            onClick={() => navigate(`/units/${props.id}/edit`)}
            size="small"
          >
            <EditOutlined /> Edit
          </Button>
        </td>
      </tr>
    );
  };

  return isMobile() ? renderMobile() : renderWeb();
};

export default User;
