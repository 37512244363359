import {
  StatusMessageNotComplete,
  StatusMessagesComplete,
} from "../../../../../shared/SharedTypes";
import React, { useEffect, useState } from "react";
import {
  Account,
  Job,
  JobListing,
  Unit,
  User,
} from "../../../../../shared/Interfaces";
import JobSummary from "../JobSummary";
import { hid, sortJob, isMobile } from "../../../../../shared/SharedUtils";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../../shared/components/Loading";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  PlusOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Action } from "antd-mobile/es/components/action-sheet";
import {
  ActionSheet,
  Button,
  Divider,
  DotLoading,
  FloatingBubble,
  InfiniteScroll,
  Input,
  Result,
} from "antd-mobile";
import { getActions } from "./Actions";
import Modal from "../../../../../shared/components/Modal";
import Select2 from "../../../../../shared/components/Select2";
import { toast } from "react-toastify";
import loading = toast.loading;

export type SortDirection =
  | "HID"
  | "From"
  | "To"
  | "Unit"
  | "Account"
  | "Assigned To";

interface ListProps {
  sortBy?: SortDirection;
  sortDirection?: "ASC" | "DESC";
  items: JobListing[];
  loading: boolean;
  hasMore: boolean;
  infiniteScroll?: boolean;
  loadNext: (isRetry: boolean) => Promise<void>;
  onAction: (
    context: JobListing,
    action: Action,
    status?: StatusUpdate,
  ) => void;
  hideAssignedTo?: boolean;
  children?: any;
}

export interface StatusUpdate {
  visible: boolean;
  message: string;
  completed: boolean;
  current_location: string;
  statuses: {
    completed: string[];
    canceled: string[];
  };
  selectValue: number;
}

const Listing: React.FC<ListProps> = (props: ListProps) => {
  const [sortBy, setSortBy] = useState<SortDirection>(props.sortBy ?? "HID");
  const [context, setContext] = useState<JobListing>({} as JobListing);
  const [actionSheetVisible, setActionSheetVisible] = useState<boolean>(false);
  const [statusUpdate, setStatusUpdate] = useState<StatusUpdate>({
    visible: false,
    message: "",
    completed: true,
    statuses: {
      completed: StatusMessagesComplete,
      canceled: StatusMessageNotComplete,
    },
    selectValue: 0,
  } as StatusUpdate);

  const [sortDirection, setSortDirection] = useState<string>(
    props.sortDirection ?? "ASC",
  );

  const [items, setItems] = useState<JobListing[]>(props.items);
  const [unsorted, setUnsorted] = useState<JobListing[]>(props.items);

  const navigate = useNavigate();

  useEffect(() => {
    setItems(props.items);
    setUnsorted(props.items);
  }, [props.items]);

  useEffect(() => {
    sort("HID", false);
    if (!props.infiniteScroll) {
      props.loadNext(false);
    }
  }, []);

  const sort = (sortKey: string, asc: boolean) => {
    const sortDirection = asc ? "ASC" : "DESC";
    if (sortKey === "HID") {
      setItems(
        unsorted.sort((a, b) => {
          return sortDirection === "ASC" ? a.id - b.id : b.id - a.id;
        }),
      );
    } else if (sortKey === "From") {
      setItems(
        unsorted.sort((a, b) => {
          return sortJob(a, b, "from_address", sortDirection as "ASC" | "DESC");
        }),
      );
    } else if (sortKey === "To") {
      setItems(
        unsorted.sort((a, b) => {
          return sortJob(
            a,
            b,
            "destination_address",
            sortDirection as "ASC" | "DESC",
          );
        }),
      );
    } else if (sortKey === "Unit") {
      setItems(
        unsorted.sort((a, b) => {
          const a_unit_name =
            a.unit_year + " " + a.unit_make + " " + a.unit_model;
          const b_unit_name =
            a.unit_year + " " + a.unit_make + " " + a.unit_model;

          return sortJob(
            a_unit_name,
            b_unit_name,
            "make",
            sortDirection as "ASC" | "DESC",
          );
        }),
      );
    } else if (sortKey === "Account") {
      setItems(
        unsorted.sort((a, b) => {
          let tmpA = a.account_name;
          let tmpB = b.account_name;
          return sortJob(tmpA, tmpB, "name", sortDirection as "ASC" | "DESC");
        }),
      );
    } else if (sortKey === "Assigned To") {
      setItems(
        unsorted.sort((a, b) => {
          let tmpA = a.user_name;
          let tmpB = b.user_name;
          return sortJob(tmpA, tmpB, "name", sortDirection as "ASC" | "DESC");
        }),
      );
    }
  };

  const arrowDir = () => {
    if (sortDirection === "ASC") {
      return <CaretUpOutlined />;
    } else if (sortDirection === "DESC") {
      return <CaretDownOutlined />;
    } else {
      return null;
    }
  };

  const toggleSortDirection = (label: string) => {
    sortDirection === "ASC"
      ? setSortDirection("DESC")
      : setSortDirection("ASC");
    setSortBy(label as SortDirection);
    sort(label, sortDirection === "DESC");
  };
  const sortLabel = (label: string) => {
    if (label === sortBy) {
      return (
        <td
          onClick={() => {
            toggleSortDirection(label);
          }}
          className={"active"}
        >
          <span>
            {label} {arrowDir()}
          </span>
        </td>
      );
    } else {
      return (
        <td
          onClick={() => {
            toggleSortDirection(label);
          }}
        >
          <span>{label}</span>
        </td>
      );
    }
  };

  const renderListing = () => {
    if (isMobile()) {
      return items.map((item: any, index: number) => {
        return (
          <JobSummary
            key={item.id}
            hideAssignedTo={props.hideAssignedTo}
            {...item}
            onActionsButtonClicked={() => {
              setContext(item);
              setActionSheetVisible(true);
            }}
          />
        );
      });
    } else {
      return (
        <table className="table table-striped table-hover table-bordered job-list job-sortable">
          <thead>
            <tr>
              {sortLabel("HID")}
              {sortLabel("From")}
              {sortLabel("To")}
              {sortLabel("Unit")}
              {sortLabel("Account")}
              {!props.hideAssignedTo && sortLabel("Assigned To")}
              <td className="ignore">Actions</td>
            </tr>
          </thead>
          <tbody>
            {items.map((item: any, index: number) => {
              return (
                <JobSummary
                  key={item.id}
                  hideAssignedTo={props.hideAssignedTo}
                  {...item}
                  onClick={() => {
                    navigate(`/jobs/${hid(item.id)}`);
                  }}
                  onMiddleMouseClicked={(e) => {
                    if (e.button === 1) {
                      let wh = window.open(`/jobs/${hid(item.id)}`, "_blank");
                      if (wh) {
                        wh.blur();
                        window.focus();
                      }
                    }
                  }}
                  onActionsButtonClicked={() => {
                    setContext(item);
                    setActionSheetVisible(true);
                  }}
                />
              );
            })}

            {items.length === 0 && !props.loading ? (
              <td colSpan={props.hideAssignedTo ? 6 : 7}>
                <Result title="No data"></Result>
              </td>
            ) : null}
          </tbody>
        </table>
      );
    }
  };

  return (
    <div>
      {renderListing()}
      <FloatingBubble
        style={{
          "--initial-position-bottom": "80px",
          "--initial-position-right": "24px",
          "--edge-distance": "48px",
        }}
        axis={"xy"}
        magnetic={"x"}
      >
        <ThunderboltOutlined
          onClick={() => {
            navigate("/jobs/quick-create");
          }}
          style={{ fontSize: "32px" }}
        />
      </FloatingBubble>
      <FloatingBubble
        style={{
          "--initial-position-bottom": "24px",
          "--initial-position-right": "24px",
          "--edge-distance": "24px",
        }}
        axis={"xy"}
        magnetic={"x"}
      >
        <PlusOutlined
          onClick={() => {
            navigate("/jobs/create");
          }}
          style={{ fontSize: "32px" }}
        />
      </FloatingBubble>
      <ActionSheet
        actions={getActions(Number(context.status))}
        visible={actionSheetVisible}
        onClose={() => {
          setActionSheetVisible(false);
        }}
        closeOnAction
        closeOnMaskClick
        onAction={(action) => {
          if (action.key === "close-job") {
            setStatusUpdate({
              ...statusUpdate,
              message: "Completed - " + StatusMessagesComplete[0],
              completed: true,
              visible: true,
              current_location: context.destination_address || "",
            });
          } else if (action.key === "cancel-job") {
            setStatusUpdate({
              ...statusUpdate,
              message: StatusMessageNotComplete[0],
              completed: false,
              visible: true,
              current_location: context.from_address || "",
            });
          } else {
            props.onAction(context, action);
          }
        }}
      />
      <Modal
        visible={statusUpdate.visible}
        onClose={() => {
          setStatusUpdate({ ...statusUpdate, visible: false });
        }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <h3>Status Update</h3>
            <Divider contentPosition="left">Describe the job outcome </Divider>
          </div>
          <Select2
            value={statusUpdate.selectValue}
            options={
              statusUpdate.completed
                ? statusUpdate.statuses.completed.map((status, index) => {
                    return { label: status, value: index };
                  })
                : statusUpdate.statuses.canceled.map((status, index) => {
                    return { label: status, value: index };
                  })
            }
            label=""
            onChange={(e: any) => {
              let selectValue = 0;
              if (context.status === 0) {
                selectValue = e || 0;
              } else {
                selectValue = e || 0;
              }
              let message = statusUpdate.completed
                ? StatusMessagesComplete[e]
                : StatusMessageNotComplete[e];
              message = statusUpdate.completed
                ? "Completed - " + message
                : "Canceled - " + message;
              setStatusUpdate({
                ...statusUpdate,
                message: message,
                selectValue: selectValue,
              });

              console.log(e);
            }}
          ></Select2>
          <Divider contentPosition="left">
            Please confirm the current location of the vehicle
          </Divider>
          <Input
            placeholder="Current Unit Location"
            value={statusUpdate.current_location}
          />
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              gap: "15px",
              width: "100%",
            }}
          >
            <Button
              onClick={() => {
                props.onAction(
                  context,
                  {
                    key: statusUpdate.completed ? "close-job" : "cancel-job",
                  } as Action,
                  statusUpdate,
                );
                setStatusUpdate({
                  ...statusUpdate,
                  visible: false,
                  message: "",
                  selectValue: 0,
                });
              }}
              block
              color={`${statusUpdate.completed ? "primary" : "danger"}`}
            >
              {statusUpdate.completed ? "Complete" : "Cancel"} Job
            </Button>
            <Button
              onClick={() => {
                setStatusUpdate({
                  ...statusUpdate,
                  visible: false,
                  message: "",
                  selectValue: 0,
                });
              }}
              block
              color="default"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {props.loading ? (
        <Loading loading={props.loading} />
      ) : props.infiniteScroll ? (
        <>
          <InfiniteScroll loadMore={props.loadNext} hasMore={props.hasMore}>
            {props.hasMore && (
              <>
                <span>Loading</span>
                <DotLoading />
              </>
            )}
          </InfiniteScroll>
        </>
      ) : null}
    </div>
  );
};

Listing.defaultProps = {
  sortBy: "HID",
  infiniteScroll: true,
};
export default Listing;
