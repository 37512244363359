import React, {useEffect, useState} from "react";
import {Unit as IUnit} from "../../../../shared/Interfaces";
import { UnitType} from "../../../../shared/SharedTypes";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import API from "../../../../shared/API";
import {Loading} from "../../../../shared/components/Loading";
import {Button, Dialog,  Input, Picker,  TextArea} from "antd-mobile";
import FormUnit from "./FormUnit";
import {toast} from "../../../../shared/SharedUtils";


const EditUnit: React.FC = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const { id }= useParams();
    const { state } = useLocation();
    const [unit, setUnit] = useState<IUnit>({} as IUnit);
    const [sending, setSending] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (state === null) {
            const api = API.getInstance();
            api.fetchUnit(Number(id)).then((res) => {
                setUnit(res.data);
            }).catch((e) => {}).finally(()  => {
                setLoading(false);
            });
        } else {
            setUnit(state);
            setLoading(false);
        }
    }, []);



    const saveEntity = (data: IUnit) => {
        setSending(true);
        const api = API.getInstance();
        api.updateUnit(data).then((res) => {
            Dialog.alert({
                content: "Unit updated successfully",
                onConfirm: () => {
                    navigate("/units");
                },
                confirmText: "OK"
            });
        }).catch((e) => {
            toast("Error updating unit", {type: "error"}, e)
        }).finally(() => {
            setSending(false);
        });
    }

    return  loading ? <Loading /> : <FormUnit unit={unit} loading={sending} onChange={(unit) => { setUnit(unit); }} onCancel={() => { navigate(-1) }}  onSave={(data) => {
        saveEntity(data);
    }} />
}

export default EditUnit;
