import Layout from "../../shared/components/Layout";
import { useEffect, useState } from "react";
import API from "../../shared/API";
import { useNavigate } from "react-router-dom";
import {
  FleetUnit as IFleetUnit,
  Impound as IImpound,
  User as IUser,
} from "../../shared/Interfaces";

import { Dialog, NoticeBar } from "antd-mobile";
import FormImpound from "./components/impound/FormImpound";
import FormFleetUnit from "./components/fleetunits/FormFleetUnit";
import FormUser from "./components/users/FormUser";
import { toast } from "../../shared/SharedUtils";
import { RequiredUserFields } from "./components/users/User";
import { RequiredUnitFields } from "./components/jobs/JobCreate";

interface CreateProps {
  type: "FleetUnit" | "Impound" | "User";
}

const Create: React.FC<CreateProps> = (props: CreateProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [entity, setEntity] = useState<IFleetUnit | IImpound | IUser>(
    {} as IFleetUnit | IImpound | IUser,
  );

  const navigate = useNavigate();
  const api = API.getInstance();

  let apiCalls = {
    FleetUnit: "createFleetUnit",
    Impound: "createImpound",
    User: "createUser",
  };

  const makeApiCall = async (entityData?: IFleetUnit | IImpound | IUser) => {
    let data = entityData || entity;
    if (props.type === "User") {
      data = { ...RequiredUserFields, ...data };
    } else if (props.type === "FleetUnit") {
      data = { ...RequiredUnitFields, ...data };
    } else {
      data = { ...{ name: "" }, ...data };
    }

    setLoading(true);
    api
      .getApiCallByName(apiCalls[props.type] as keyof API, data)
      .then((resp) => {
        Dialog.alert({
          title: "Success",
          content: "Successfully created " + props.type,
          confirmText: "OK",
          onConfirm: () => {
            navigate(-1);
          },
        });
      })
      .catch((e) => {
        toast("Error creating " + props.type, { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderFragment = () => {
    if (props.type === "FleetUnit") {
      return (
        <FormFleetUnit
          loading={loading}
          fleetunit={entity as IFleetUnit}
          onChange={(e) => {
            setEntity(e);
          }}
          onCancel={() => {
            navigate(-1);
          }}
          onSave={(e) => {
            setEntity(e);
            setLoading(true);
            makeApiCall().finally(() => {
              setLoading(false);
            });
            // save
          }}
        />
      );
    } else if (props.type === "Impound") {
      return (
        <FormImpound
          loading={loading}
          impound={entity as IImpound}
          onChange={(e) => {
            setEntity(e);
          }}
          onCancel={() => {
            navigate(-1);
          }}
          onSave={() => {
            setLoading(true);
            makeApiCall().finally(() => {
              setLoading(false);
            });
          }}
        />
      );
    } else if (props.type === "User") {
      return (
        <FormUser
          loading={loading}
          user={entity as IUser}
          onChange={(e) => {
            setEntity(e);
          }}
          create={true}
          onCancel={() => {
            navigate(-1);
          }}
          onSave={(user: IUser) => {
            setLoading(true);
            makeApiCall(user).finally(() => {
              setLoading(false);
            });
          }}
        />
      );
    }
    return "";
  };

  return (
    <Layout title={`Create ${props.type}`}>
      <div>
        <div>{renderFragment()}</div>
      </div>
    </Layout>
  );
};

export default Create;
