import { Action } from "antd-mobile/es/components/action-sheet";
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  IssuesCloseOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import React from "react";
import { Dialog } from "antd-mobile";
import API from "../../../../../shared/API";
import { JobListing, User } from "../../../../../shared/Interfaces";
import { StatusUpdate } from "./Listing";
import { hid, toast } from "../../../../../shared/SharedUtils";
import { AuthSession } from "../../../../../shared/providers/AuthProvider";

const actions: Action[] = [
  {
    text: (
      <span style={{ minWidth: "100%" }}>
        <EyeOutlined /> View
      </span>
    ),
    key: "view",
  },
  {
    text: (
      <>
        <CheckOutlined /> Complete Job
      </>
    ),
    key: "close-job",
  },
  {
    text: (
      <>
        <IssuesCloseOutlined /> Cancel Job
      </>
    ),
    key: "cancel-job",
    danger: true,
  },
  {
    text: (
      <>
        <ArrowRightOutlined /> Reopen
      </>
    ),
    key: "reopen",
  },
  {
    text: (
      <>
        <EditOutlined /> Edit{" "}
      </>
    ),
    key: "edit",
  },
  {
    text: (
      <>
        <UserAddOutlined /> Assign to Me{" "}
      </>
    ),
    key: "assign-to-me",
  },
  {
    text: (
      <>
        <PlusCircleOutlined /> Duplicate{" "}
      </>
    ),
    danger: true,
    key: "duplicate",
  },
  {
    text: (
      <>
        <CloseOutlined /> Cancel{" "}
      </>
    ),
    key: "cancel",
  },
];

const getActions = (status: number) => {
  let _actions: Action[] = actions;
  return _actions.filter((action) => {
    if (status === 2 || status === 3) {
      if (action.key === "cancel-job") {
        return false;
      }
      return action.key !== "close-job";
    } else {
      return action.key !== "reopen";
    }
  });
};

export type ActionHandlerResponse = {
  job?: JobListing;
  data?: any;
  status?: number;
  session?: AuthSession;
};

export type UseHandleActionDeps = {
  items: JobListing[];
  session: AuthSession | null;
  setItems: React.Dispatch<React.SetStateAction<JobListing[]>>;
  setOverlayLoading: React.Dispatch<React.SetStateAction<boolean>>;
  navigationRef: any;
};

const useHandleAction = ({
  items,
  session,
  setItems,
  setOverlayLoading,
  navigationRef,
}: UseHandleActionDeps) => {
  const actionHandler = async (
    context: JobListing,
    action: Action,
    status?: StatusUpdate,
  ): Promise<ActionHandlerResponse> => {
    return new Promise((resolve, reject) => {
      const api = API.getInstance();
      if (action.key === "cancel") return;
      if (action.key === "view") {
        navigationRef(`/jobs/${hid(context.id)}`);
        return;
      }
      if (action.key === "edit") {
        navigationRef(`/jobs/${hid(context.id)}/edit`);
        return;
      }
      if (
        action.key === "close-job" ||
        action.key === "reopen" ||
        action.key === "cancel-job"
      ) {
        setOverlayLoading(true);

        // If action is close job, set status to 3 (closed), else set to 2 (cancelled)
        const new_status = action.key === "close-job" ? 3 : 2;

        api
          .patchJob(context.id, {
            status: new_status,
            status_notes: status?.message,
          })
          .then((job_res) => {
            api
              .patchUnit(context.unit_id, {
                current_location: status?.current_location,
              })
              .then(() => {
                toast("Job updated successfully", { type: "success" });
                resolve({
                  job: job_res.data,
                  status: new_status,
                } as ActionHandlerResponse);
              })
              .catch((err) => {
                toast("Error updating job", { type: "error" });
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            setOverlayLoading(false);
          });
      }

      if (action.key === "assign-to-me") {
        setOverlayLoading(true);
        api
          .patchJob(context.id, { assigned_to: session?.user?.id as number })
          .then((res) => {
            Dialog.alert({
              title: "Success",
              content: `JobListing assigned successfully`,
              confirmText: "OK",
            });
            if (session?.user) {
              resolve({ data: res.data, session: session as AuthSession });
            }
          })
          .catch((err) => {
            toast("Error updating job", { type: "error" }, err);
            reject(err);
          })
          .finally(() => {
            setOverlayLoading(false);
          });
      }

      if (action.key === "duplicate") {
        Dialog.confirm({
          title: "Duplicate JobListing",
          content: "Are you sure you want to duplicate this job?",
          confirmText: "Yes",
          cancelText: "No",
          onConfirm: () => {
            setOverlayLoading(true);
            api
              .duplicateJob(context.id)
              .then((res) => {
                Dialog.alert({
                  title: "Success",
                  content: `JobListing duplicated successfully`,
                  confirmText: "OK",
                });
                resolve({ job: res.data } as ActionHandlerResponse);
              })
              .catch((err) => {
                toast("Error duplicating job", { type: "error" }, err);
                reject(err);
              })
              .finally(() => {
                setOverlayLoading(false);
              });
          },
        });
      }
    });
  };

  return async (context: JobListing, action: Action, status?: StatusUpdate) => {
    try {
      const data = (await actionHandler(
        context,
        action,
        status,
      )) as ActionHandlerResponse;
      if (
        action.key === "close-job" ||
        action.key === "reopen" ||
        action.key === "cancel-job"
      ) {
        let _items = [...items];
        let index = _items.findIndex((item) => item.id === context.id);

        if (index > -1) {
          _items[index].status = Number(data.status);
          setItems(_items);
        }
      } else if (action.key === "assign-to-me") {
        let _items = [...items];
        let index = _items.findIndex((item) => item.id === context.id);
        if (index > -1) {
          if (_items[index]) {
            _items[index].user_name = session?.user?.name || "";
          }
          setItems(_items);
        }
      } else if (action.key === "duplicate") {
        if (data.job) {
          let _items = [...items];
          _items.unshift(data.job);
          setItems(_items);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setOverlayLoading(false);
    }
  };
};

export { actions, getActions, useHandleAction };
