import { Flags as IFlags, Flag, Account, User } from "../Interfaces";
import React, { useEffect, useState } from "react";
import { Button, Divider, Input, Switch } from "antd-mobile";
import Select2 from "./Select2";
import moment from "moment";
import API from "../API";
import { toast } from "../SharedUtils";
import { CALENDAR_DB_FORMAT } from "../SharedTypes";
import { useAuth } from "../providers/AuthProvider";

type FlagDisplay = {
  name: keyof IFlags;
  code: string;
  human_readable: string;
  status: boolean;
  needsAccount: boolean;
  needsUser: boolean;
  at?: string;
  by_user?: number;
  by_user_name?: string;
  by_account?: number;
  by_account_name?: string;
};

const FlagsArray: FlagDisplay[] = [
  {
    name: "seen",
    code: "S",
    human_readable: "Seen",
    status: false,
    needsAccount: false,
    needsUser: true,
  },
  {
    name: "en_route",
    code: "E",
    human_readable: "En Route",
    status: false,
    needsAccount: false,
    needsUser: true,
  },
  {
    name: "on_scene",
    code: "O",
    human_readable: "On Scene",
    status: false,
    needsAccount: false,
    needsUser: true,
  },
  {
    name: "completed",
    code: "C",
    human_readable: "Completed",
    status: false,
    needsAccount: false,
    needsUser: true,
  },
  {
    name: "cancelled",
    code: "X",
    human_readable: "Cancelled",
    status: false,
    needsAccount: false,
    needsUser: true,
  },
  {
    name: "mailed_a",
    code: "M1",
    human_readable: "Mailed A",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
  {
    name: "response_a",
    code: "R1",
    human_readable: "Response A",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
  {
    name: "mailed_b",
    code: "M2",
    human_readable: "Mailed B",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
  {
    name: "response_b",
    code: "R2",
    human_readable: "Response B",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
  {
    name: "damage_waiver",
    code: "D",
    human_readable: "Damage Waiver",
    status: false,
    needsAccount: true,
    needsUser: true,
  },
  {
    name: "released",
    code: "R",
    human_readable: "Released",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
  {
    name: "keys",
    code: "K",
    human_readable: "Keys",
    status: false,
    needsAccount: false,
    needsUser: false,
  },
  {
    name: "title",
    code: "T",
    human_readable: "Title",
    status: false,
    needsAccount: false,
    needsUser: false,
  },
  {
    name: "lien",
    code: "L",
    human_readable: "Lien",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
  {
    name: "ready",
    code: "S",
    human_readable: "Sale Ready",
    status: false,
    needsAccount: false,
    needsUser: false,
  },
  {
    name: "reported",
    code: "P",
    human_readable: "Reported",
    status: false,
    needsAccount: false,
    needsUser: true,
  },
  {
    name: "moved",
    code: "M",
    human_readable: "Moved",
    status: false,
    needsAccount: true,
    needsUser: false,
  },
];

export interface FlagsProps {
  flags: IFlags | undefined;
  onSaved: (flags: IFlags) => void;
  jobId: number;
}

export const Flags: React.FC<FlagsProps> = (props: FlagsProps) => {
  const [flags, setFlags] = useState<FlagDisplay[]>(FlagsArray);
  const [backendFlags, setBackendFlags] = useState<IFlags>({} as IFlags);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { session } = useAuth();

  const validateFlags = (flags: FlagDisplay[]): boolean => {
    for (let flag of flags) {
      if (flag.status) {
        if (flag.needsAccount && (!flag.by_account || !flag.by_account_name)) {
          return false; // by_account or by_account_name is missing
        }

        if (flag.needsUser && (!flag.by_user || !flag.by_user_name)) {
          return false; // by_user or by_user_name is missing
        }
      }
    }
    return true; // all flags are valid
  };

  const toFlagsDisplay = (backendFlags: IFlags): FlagDisplay[] => {
    return FlagsArray.map((displayFlag) => {
      const dbFlag = backendFlags[displayFlag.name];
      // This check ensures that you have a matching flag in your database flags.
      // If not, it will use the default values from FlagsArray.

      if (dbFlag) {
        return {
          ...displayFlag,
          status: dbFlag.status ?? false,
          at: dbFlag.at ?? undefined,
          by_user: dbFlag.by_user ?? session?.user?.id,
          by_user_name: dbFlag.by_user_name ?? session?.user?.name,
          by_account: dbFlag.by_account ?? undefined,
          by_account_name: dbFlag.by_account_name ?? undefined,
        };
      } else {
        return displayFlag;
      }
    });
  };

  const toBackendFlags = (displayFlags: FlagDisplay[]): IFlags => {
    const backendFlags: Partial<IFlags> = {};

    displayFlags
      .filter((displayFlag) => displayFlag.status) // Only consider flags with a status of true
      .forEach((displayFlag) => {
        backendFlags[displayFlag.name] = {
          status: displayFlag.status,
          at: moment(displayFlag.at).format(CALENDAR_DB_FORMAT) ?? undefined,
          by_user: displayFlag.by_user ?? session?.user?.id,
          by_user_name: displayFlag.by_user_name ?? session?.user?.name,
          by_account: displayFlag.by_account ?? undefined,
          by_account_name: displayFlag.by_account_name,
        };
      });

    return backendFlags as IFlags;
  };

  useEffect(() => {
    const newFlags = toFlagsDisplay(props.flags ?? {});
    setFlags(newFlags);
  }, [props.flags]);

  const saveFlags = () => {
    const newFlags = toBackendFlags(flags);
    const api = API.getInstance();

    if (!validateFlags(flags)) {
      toast("Please fill out all required fields", { type: "error" });
      return false;
    }

    setLoading(true);
    api
      .patchFlags(props.jobId, newFlags)
      .then((res) => {
        if (res.status === 200) {
          props.onSaved(newFlags);
          setIsEditing(false);
          toast("Flags saved successfully", { type: "success" });
        }
      })
      .catch(() => {
        toast("Failed to save flags", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {!isEditing ? (
        <div
          onClick={() => {
            setIsEditing(true);
          }}
          className={"flags-display"}
        >
          {flags.map((flag, index) => {
            return (
              <span key={index} className={flag.status ? "yes" : "no"}>
                {flag.code}
              </span>
            );
          })}
        </div>
      ) : (
        <div className={"flags-edit form"}>
          {flags.map((flag, index) => {
            return (
              <div style={{ borderBottom: "1px solid #3d3d3d" }} key={index}>
                <div className={"flag"}>
                  <div className={"label"}>
                    <label
                      onClick={() => {
                        const updatedFlags = flags.map((f) =>
                          f.name === flag.name
                            ? { ...f, status: !f.status }
                            : f,
                        );
                        setFlags(updatedFlags);
                      }}
                    >
                      ({flag.code}) {flag.human_readable}
                    </label>
                  </div>
                  <div className={"switch"}>
                    <Switch
                      checked={flag.status}
                      onChange={(value) => {
                        const updatedFlags = flags.map((f) =>
                          f.name === flag.name
                            ? { ...f, status: !f.status }
                            : f,
                        );
                        setFlags(updatedFlags);
                      }}
                    />
                  </div>
                </div>
                {flag.status && (
                  <div className={"flag-details"}>
                    {flag.needsUser && (
                      <div className={"user"}>
                        <Divider contentPosition={"left"}>By User</Divider>
                        <div className={`${flag.by_user ? "" : "has-errors"}`}>
                          <Select2
                            async
                            asyncSearchType="account"
                            label={`${flag.by_user_name || "Select User"}`}
                            onChange={(value) => {
                              const updatedFlags = flags.map((f) =>
                                f.name === flag.name
                                  ? {
                                      ...f,
                                      by_user: (value as User).id,
                                      by_user_name: (value as User).name,
                                    }
                                  : f,
                              );
                              setFlags(updatedFlags);
                            }}
                          ></Select2>
                        </div>
                        {flag.by_user ? null : (
                          <span style={{ color: "var(--adm-color-danger)" }}>
                            This field is required
                          </span>
                        )}
                      </div>
                    )}
                    {flag.needsAccount && (
                      <div className={"user"}>
                        <Divider contentPosition={"left"}>By Account</Divider>
                        <div
                          className={`${flag.by_account ? "" : "has-errors"}`}
                        >
                          <Select2
                            async
                            asyncSearchType="account"
                            label={`${
                              flag.by_account_name || "Select Account"
                            }`}
                            onChange={(value) => {
                              const updatedFlags = flags.map((f) =>
                                f.name === flag.name
                                  ? {
                                      ...f,
                                      by_account: (value as Account).id,
                                      by_account_name: (value as Account).name,
                                    }
                                  : f,
                              );
                              setFlags(updatedFlags);
                            }}
                          ></Select2>
                          {flag.by_account ? null : (
                            <span style={{ color: "var(--adm-color-danger)" }}>
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {flag.needsAccount || flag.needsUser ? (
                      <Input
                        type="date"
                        onChange={(value) => {
                          const updatedFlags = flags.map((f) =>
                            f.name === flag.name ? { ...f, at: value } : f,
                          );
                          setFlags(updatedFlags);
                        }}
                        value={moment(flag.at).format("YYYY-MM-DD")}
                      ></Input>
                    ) : null}
                  </div>
                )}
              </div>
            );
          })}
          <div style={{ display: "flex", gap: "10px" }}>
            <Button block color="primary" loading={loading} onClick={saveFlags}>
              Save
            </Button>
            <Button
              block
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
