import Layout from "../../../../shared/components/Layout";
import React, { useEffect, useState } from "react";
import API from "../../../../shared/API";

import { JobListing as IJobListing } from "../../../../shared/Interfaces";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  filterOptionsByStatusTypes,
  getStatusTypesWithChips,
  toast,
} from "../../../../shared/SharedUtils";
import Listing from "./listing/Listing";
import { useHandleAction } from "./listing/Actions";
import { OverlayLoading } from "../../../../shared/components/Loading";
import { Categories, StatusType } from "../../../../shared/SharedTypes";
import Select2 from "../../../../shared/components/Select2";
import { fetchStored } from "../../../../shared/AsyncStorage";
import {
  CarOutlined,
  CompassOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { PullToRefresh } from "antd-mobile";
import { isMobile } from "../../../../shared/SharedUtils";

interface JobListingProps {
  type: "my" | "all" | "scheduled" | "public";
  children?: any;
}

const CategoriesOptions = Categories.map((value, index) => {
  return { label: value, value: index };
});

const StatusOptions = StatusType.map((value, index) => {
  return { label: value, value: index };
});

const StatusWithAny = [{ label: "Any", value: -1 }, ...StatusOptions];
const CategoriesWithAny = [{ label: "Any", value: -1 }, ...CategoriesOptions];

const JobListing: React.FC<JobListingProps> = (props: JobListingProps) => {
  const [pageTitle, setPageTitle] = useState<string>("Jobs");
  const [loading, setLoading] = useState<boolean>(false);

  const [currentTab, setCurrentTab] = useState<JobListingProps["type"]>(
    props.type,
  );
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [scheduledCount, setScheduledCount] = useState(0);
  const [items, setItems] = useState<IJobListing[]>([] as IJobListing[]);
  const [overlayLoading, setOverlayLoading] = useState<boolean>(false);

  const { role, hasPerms, session } = useAuth();
  const navigate = useNavigate();

  const [status, setStatus] = useState<number>(-1);
  const [category, setCategory] = useState<number>(-1);

  const handleAction = useHandleAction({
    items,
    session,
    setItems,
    setOverlayLoading,
    navigationRef: navigate,
  });

  const ApiCalls = {
    my: "fetchMyJobs",
    all: "fetchJobs",
    scheduled: "fetchScheduledJobs",
    public: "fetchPublicJobs",
  };

  useEffect(() => {
    fetchStored("defaultCategory").then((res) => {
      setCategory(res || 0);
    });

    getScheduledCountJobs();
  }, []);

  useEffect(() => {
    setCurrentTab(props.type);
  }, [props.type]);

  useEffect(() => {
    setItems([]);
    setHasMore(true);
    setSkip(0);
  }, [status, category]);

  const loadMore = async () => {
    let append = (await makeApiCall()) as [];
    setItems([...items, ...append]);
    setSkip(skip + 25);
    setHasMore(append.length > 0);
  };

  useEffect(() => {
    setItems([]);
    setHasMore(true);
    setSkip(0);
  }, [currentTab]);

  const makeApiCall = () => {
    return new Promise((resolve, reject) => {
      let api = API.getInstance();

      interface Params {
        skip: number;
        limit: number;
        status?: number;
        category?: number;
      }

      const params = {
        skip: skip,
        limit: 25,
        category: 0,
      } as Params;

      if (status !== -1) {
        params.status = status;
      }

      if (category !== -1) {
        params.category = category;
      }

      if (currentTab) {
        api
          .getApiCallByName(ApiCalls[currentTab] as keyof API, params)
          .then((res) => {
            if (res.status === 200) {
              resolve(res.data);
            } else {
              toast("Error fetching jobs", { type: "error" });
              setHasMore(false);
            }
          })
          .catch((err) => {
            toast("Error fetching jobs", { type: "error" }, err);
            reject(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const getScheduledCountJobs = () => {
    setLoading(true);
    let api = API.getInstance();
    api
      .fetchScheduledJobs()
      .then((res) => {
        setScheduledCount(res.data.length);
      })
      .catch((e) => {
        toast("Error fetching scheduled jobs", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout title={pageTitle}>
      {currentTab === "all" ? (
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "20px 10px",
          }}
        >
          <div style={{ flex: 1, height: "40px" }}>
            <Select2
              value={status}
              onChange={(e: any) => {
                setStatus(e);
              }}
              options={StatusWithAny}
              style={{ flex: 1 }}
            ></Select2>
          </div>
          <div style={{ flex: 1, height: "40px" }}>
            <Select2
              value={category}
              onChange={(e: any) => {
                setCategory(e);
              }}
              options={CategoriesWithAny}
              style={{ flex: 1 }}
            ></Select2>
          </div>
        </div>
      ) : null}
      <div className="z-tabs">
        <div className="z-tabs-wrapper">
          <ul>
            <li
              onClick={() => {
                navigate("/jobs/my");
              }}
              className={props.type === "my" ? "active" : ""}
            >
              <Link to="/jobs/my">
                <UserOutlined /> My
              </Link>
            </li>
            <li
              onClick={() => {
                navigate("/jobs/");
              }}
              className={props.type === "all" ? "active" : ""}
            >
              <Link to="/jobs/">
                <CarOutlined /> All
              </Link>
            </li>
            <li
              onClick={() => {
                navigate("/jobs/scheduled");
              }}
              className={props.type === "scheduled" ? "active" : ""}
            >
              <Link to="/jobs/scheduled">
                <WarningOutlined /> {!isMobile() ? "Scheduled " : "S "}
                {scheduledCount !== 0 && <span>({scheduledCount})</span>}
              </Link>
            </li>
            <li
              onClick={() => {
                navigate("/jobs/public");
              }}
              className={props.type === "public" ? "active" : ""}
            >
              <Link to="/jobs/public">
                <CompassOutlined /> Public
              </Link>
            </li>
          </ul>
        </div>
        <div className={"z-tabs-content"}>
          <PullToRefresh
            onRefresh={async () => {
              setItems([]);
              setHasMore(true);
              setSkip(0);
              loadMore();
            }}
          >
            <Listing
              loadNext={loadMore}
              hasMore={hasMore}
              loading={loading}
              items={items}
              hideAssignedTo={currentTab === "public" || currentTab === "my"}
              onAction={handleAction}
            />
          </PullToRefresh>
        </div>
      </div>
      {overlayLoading && <OverlayLoading />}
    </Layout>
  );
};

export default JobListing;
