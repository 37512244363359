import React, { forwardRef, useEffect, useState } from "react";
import { Button, ButtonProps, Divider, Input, Switch } from "antd-mobile";
import {
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Account, Charge, Job, Payment } from "../../../../shared/Interfaces";
import {
  CALENDAR_DT_INPUT_FORMAT,
  PaymentMethod,
} from "../../../../shared/SharedTypes";
import moment from "moment";
import Select2 from "../../../../shared/components/Select2";
import { FallbackPriceGroups } from "../../../../shared/SharedFallbackValues";
import EntitySelector3 from "../../../../shared/components/EntitySelector3";
import {
  distributeSum,
  formatCurrency,
  isNada,
  isMobile,
} from "../../../../shared/SharedUtils";
import { SectionalLoading } from "../../../../shared/components/Loading";
import SignaturePad, {
  SignatureObject,
} from "../../../../shared/components/SignaturePad";
import API from "../../../../shared/API";
import Modal from "../../../../shared/components/Modal";
import Chip from "../../../../shared/components/Chip";

interface ChargeEntryProps {
  index: number;
  name: string;
  amount: string;
  onDelete: () => void;
  onNameChange: (value: string) => void;
  onAmountChange: (value: string) => void;
}

const ChargeEntry: React.FC<ChargeEntryProps> = (props: ChargeEntryProps) => {
  return (
    <tr key={props.index}>
      <td>{props.index + 1}</td>
      <td>
        <Input
          placeholder="Charge Name"
          value={props.name}
          onChange={(value) => {
            props.onNameChange(value);
          }}
        />
      </td>
      <td>
        <Input
          placeholder="Amount"
          value={props.amount}
          type="number"
          onChange={(value) => {
            props.onAmountChange(formatCurrency(value));
          }}
        />
      </td>
      <td>
        <Button fill="none" color="danger" onClick={props.onDelete}>
          <DeleteOutlined />
        </Button>
      </td>
    </tr>
  );
};

export function toCloverNumber(amount: string) {
  return Number(amount.replace(".", ""));
}

interface FormPaymentProps {
  loading: boolean;
  payment: Payment;
  account: Account;
  job: Job;
  onChange?: (payment: Payment) => void;
  onCancel?: () => void;
  onSave?: (payment: Payment) => void;
  hideButtons?: boolean;
  onSubmitSuccess?: () => void;
  onVerify?: () => void;
}

export interface StorageCharge {
  visible: boolean;
  days: number;
  perDay: number;
}

const FormPayment = forwardRef<HTMLFormElement, FormPaymentProps>(
  (props, ref) => {
    const [payment, setPayment] = useState<Payment>(props.payment as Payment);
    const [charges, setCharges] = useState<Charge[]>([]);
    const [total, setTotal] = useState<string>("0.00");
    const [account, setAccount] = useState<Account>(props.account as Account);

    const [job, setJob] = useState<Job>(props.job as Job);
    const [signature, setSignature] = useState<SignatureObject>({
      fullName: account.name,
    } as SignatureObject);
    const [padShown, setPadShown] = useState<boolean>(false);
    const [selectedPriceGroup, setSelectedPriceGroup] = useState<number>(0);

    const api = API.getInstance();

    const [storageCharge, setStorageCharge] = useState<StorageCharge>({
      visible: false,
      days: Math.abs(
        moment(moment(job?.scheduled_at).toDate()).diff(moment(), "days"),
      ),
      perDay: 30,
    });

    const [cloverLoading, setCloverLoading] = useState<boolean>(false);

    const hasErrors = () => {
      let hasCloverOrderId = !isNada(payment.clover_order_id);
      const hasSignature = !isNada(signature.signature);
      const hasAccount = !isNada(account);

      console.log(hasCloverOrderId, hasSignature, hasAccount);
      if (payment.is_paid) {
        // If payment is anything but credit card, pretend there is a clover_order_id
        if (payment.payment_type !== 2) {
          hasCloverOrderId = true;
        }
        return !hasCloverOrderId || !hasSignature || !hasAccount;
      }
    };

    useEffect(() => {
      setPayment({ ...payment, paid_by: account.id, paid_by_data: account });
      setSignature({ ...signature, fullName: account.name } as SignatureObject);
    }, [account]);

    useEffect(() => {
      setPayment(props.payment);
      setCharges(
        payment.charges || [{ name: "Towing", amount: "0.00" } as Charge],
      );
    }, [props.payment]);

    useEffect(() => {
      setAccount(props.account);
    }, [props.account]);

    // Ensure charges are never blank
    useEffect(() => {
      if (charges.length === 0) {
        setCharges([{ name: "Towing", amount: "0.00" } as Charge]);
      }
    }, [charges]);

    // Super function that will attempt to breakdown large sums if requested by a button
    const attemptBreakdown: React.MouseEventHandler<HTMLSpanElement> = (e) => {
      if (e.detail !== 2) return;
      const total_sum = parseFloat(total);
      const percentages = [30, 16, 45.7, 9];
      const storageFee = storageCharge.days * 40;
      const small_sum = total_sum - storageFee - 100 - total_sum * 0.16;

      const distributedSum = distributeSum(small_sum, percentages);

      const callOutFee = distributedSum[0];
      const fuelSurcharge = (total_sum * 0.16).toFixed(2);
      let newCharges = [
        { name: "Call Out", amount: callOutFee.toFixed(2) } as Charge,
        { name: "Mileage", amount: distributedSum[1].toFixed(2) } as Charge,
        { name: "Fuel Surcharge", amount: fuelSurcharge } as Charge,
        { name: "Labor", amount: distributedSum[2].toFixed(2) } as Charge,
        {
          name: "Special Equipment",
          amount: distributedSum[3].toFixed(2),
        } as Charge,
        { name: "Gate Fee", amount: "100.00" } as Charge,
        { name: "Storage", amount: storageFee.toFixed(2) } as Charge,
      ];
      setCharges(newCharges);
      setPayment({ ...payment, charges: newCharges });
      props.onChange && props.onChange({ ...payment, charges: newCharges });
    };

    const handleFormSubmit = (payment: any) => {
      props.onSave && props.onSave(payment);
      props.onSubmitSuccess && props.onSubmitSuccess();
    };

    // Add charges or modify charges
    const handleAddCharge = (charge: Charge) => {
      setCharges([...charges, charge]);
      setPayment({ ...payment, charges: [...charges, charge] });
      props.onChange &&
        props.onChange({ ...payment, charges: [...charges, charge] });
    };

    const handleDeleteCharge = (index: number) => {
      if (charges.length > 1) {
        const newCharges = [...charges];
        newCharges.splice(index, 1);
        setCharges(newCharges);
        setPayment({ ...payment, charges: newCharges });
        props.onChange && props.onChange({ ...payment, charges: newCharges });
      }
    };

    const handleAmountChange = (amount: string, index: number) => {
      const updatedCharges = [...charges];
      updatedCharges[index].amount = amount;
      setCharges(updatedCharges);
      setPayment({ ...payment, charges: updatedCharges });
      props.onChange && props.onChange({ ...payment, charges: updatedCharges });
    };

    const handleJobTypeChange = (value: string, index: number) => {
      const updatedCharges = [...charges];
      updatedCharges[index].name = value;
      setCharges(updatedCharges);
      setPayment({ ...payment, charges: updatedCharges });
      props.onChange && props.onChange({ ...payment, charges: updatedCharges });
    };

    useEffect(() => {
      let total = 0;
      charges.forEach((charge) => {
        total += Number(charge.amount);
      });
      setTotal(total.toFixed(2));
      setPayment({ ...payment, total: total.toFixed(2) });
    }, [charges]);

    return (
      <form>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "15px",
            }}
          >
            <div style={{ flex: 2 }}>
              <table className={"charges-table complex"}>
                <thead>
                  <tr>
                    <td>#</td>
                    <td>Service</td>
                    <td>Cost</td>
                    <td>&nbsp;</td>
                  </tr>
                </thead>
                <tbody className={"form"}>
                  {charges.map((charge, index) => {
                    return (
                      <ChargeEntry
                        key={index}
                        index={index}
                        name={charge.name}
                        amount={charge.amount}
                        onDelete={() => {
                          handleDeleteCharge(index);
                        }}
                        onNameChange={(value) => {
                          handleJobTypeChange(value, index);
                        }}
                        onAmountChange={(value) => {
                          handleAmountChange(value, index);
                        }}
                      />
                    );
                  })}
                </tbody>
              </table>
              <Divider>Actions</Divider>
              <div
                style={{
                  display: "flex",
                  flexDirection: !isMobile() ? "row" : "column",
                  gap: "15px",
                }}
              >
                <Button
                  onClick={() => {
                    handleAddCharge({
                      name: "Towing",
                      amount: "0.00",
                    } as Charge);
                  }}
                  style={{ flex: 1 }}
                  size={"large"}
                >
                  Add Charge <PlusOutlined />
                </Button>
                <Button
                  onClick={() => {
                    const credit_card_fee = parseFloat(total) * 0.05;
                    handleAddCharge({
                      name: "Credit Card Fee",
                      amount: credit_card_fee.toFixed(2),
                    });
                  }}
                  style={{ flex: 1 }}
                  size="large"
                >
                  <CreditCardOutlined /> Credit Card Fee <PlusOutlined />
                </Button>
                <Button
                  onClick={() => {
                    setStorageCharge({ ...storageCharge, visible: true });
                  }}
                  style={{ flex: 1 }}
                  size={"large"}
                >
                  <CalendarOutlined /> Storage <PlusOutlined />
                </Button>
                <div style={{ flex: 1 }}>
                  <Select2
                    value={selectedPriceGroup}
                    options={FallbackPriceGroups.map((item, index) => {
                      return { label: item.name, value: index };
                    })}
                    label="Charges"
                    onChange={(e) => {
                      setCharges(FallbackPriceGroups[Number(e)].charges);
                      setPayment({
                        ...payment,
                        charges: FallbackPriceGroups[Number(e)].charges,
                      });
                      props.onChange &&
                        props.onChange({
                          ...payment,
                          charges: FallbackPriceGroups[Number(e)].charges,
                        });
                      setSelectedPriceGroup(Number(e));
                    }}
                    style={{ height: "40px" }}
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ marginTop: "33px" }}>
                <Divider>
                  <span onClick={attemptBreakdown}>Summary</span>
                </Divider>
                <table className={"transaction-table"}>
                  <tbody>
                    {charges.map((charge, index) => {
                      return (
                        <tr key={index}>
                          <td>{charge.name}</td>
                          <td>${charge.amount || "0.00"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <Divider />
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <h1>Total</h1>
                    <h3>Quoted</h3>
                  </div>
                  <div style={{ flex: 1 }}>
                    <h1 style={{ color: "var(--primary)", textAlign: "right" }}>
                      ${total}
                    </h1>
                    <h3 style={{ textAlign: "right" }}>
                      {job.quoted ? `$${job.quoted}` : "Nothing"}
                    </h3>
                  </div>
                </div>

                <Divider>Transaction Information</Divider>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: "15px",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <p
                        onClick={() => {
                          setPayment({ ...payment, is_paid: !payment.is_paid });
                          props.onChange &&
                            props.onChange({
                              ...payment,
                              is_paid: !payment.is_paid,
                            });
                        }}
                        className={"hoverable"}
                        style={{ fontWeight: "bold" }}
                      >
                        Job Paid
                      </p>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Switch
                        {...props}
                        onChange={(value) => {
                          setPayment({
                            ...payment,
                            is_paid: !payment.is_paid,
                          });
                          props.onChange &&
                            props.onChange({
                              ...payment,
                              is_paid: !payment.is_paid,
                            });
                        }}
                        checked={payment.is_paid}
                      />
                    </div>
                  </div>
                  {payment.is_paid && (
                    <div className={"form"}>
                      <Select2
                        label={"Paid Via"}
                        options={PaymentMethod}
                        onChange={(e) => {
                          setPayment({ ...payment, payment_type: Number(e) });
                          props.onChange &&
                            props.onChange({
                              ...payment,
                              payment_type: Number(e),
                            });
                        }}
                      ></Select2>
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <Input
                            value={moment(payment.payment_date).format(
                              "YYYY-MM-DDTHH:mm",
                            )}
                            placeholder={"Payment Date"}
                            type="datetime-local"
                            onChange={(e) => {
                              setPayment({
                                ...payment,
                                payment_date: moment(e).format(
                                  CALENDAR_DT_INPUT_FORMAT,
                                ),
                              });

                              props.onChange &&
                                props.onChange({
                                  ...payment,
                                  payment_date: moment(e).format(
                                    CALENDAR_DT_INPUT_FORMAT,
                                  ),
                                });
                            }}
                          />
                        </div>
                        <div style={{ flex: 0, marginLeft: "15px" }}>
                          <Button
                            onClick={() => {
                              const today = moment().format("YYYY-MM-DDTHH:mm");
                              setPayment({ ...payment, payment_date: today });
                              props.onChange &&
                                props.onChange({
                                  ...payment,
                                  payment_date: today,
                                });
                            }}
                            size="large"
                          >
                            {isMobile() ? <ClockCircleOutlined /> : "Today"}
                          </Button>
                        </div>
                      </div>
                      <div>
                        <div className={isNada(account) ? "has-errors" : ""}>
                          <EntitySelector3
                            entityType="account"
                            label="Paid By"
                            editable
                            clearable
                            defaultValue={props.account}
                            value={account}
                            onChange={(e) => {
                              if (e) {
                                setAccount(e);
                              } else {
                                setAccount({} as Account);
                              }

                              setSignature({
                                ...signature,
                                fullName: "",
                                signature: null,
                              });
                            }}
                          />
                        </div>
                      </div>

                      {payment.payment_type === 2 && (
                        <div style={{ display: "block" }}>
                          <div style={{ display: "flex" }}>
                            <div
                              className={
                                isNada(payment.clover_order_id)
                                  ? "has-errors"
                                  : ""
                              }
                              style={{ flex: 1 }}
                            >
                              <Input
                                placeholder="Clover Order ID"
                                value={payment.clover_order_id}
                                onChange={(e) => {
                                  setPayment({
                                    ...payment,
                                    clover_order_id: e,
                                  });
                                  props.onChange &&
                                    props.onChange({
                                      ...payment,
                                      clover_order_id: e,
                                    });
                                }}
                              />
                            </div>
                            <div style={{ flex: 0, marginLeft: "15px" }}>
                              <Button
                                onClick={props.onVerify}
                                size="large"
                                disabled={hasErrors()}
                              >
                                <CheckOutlined />
                              </Button>
                            </div>
                          </div>
                          {isNada(payment.clover_order_id) && (
                            <div style={{ height: "30px" }}>
                              <span className={"error-helper"}>
                                If paid by credit card Clover Order ID is
                                required.
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <Button
                    color={
                      payment.is_paid
                        ? isNada(signature.signature)
                          ? "danger"
                          : "success"
                        : "success"
                    }
                    onClick={() => setPadShown(true)}
                    block
                  >
                    {" "}
                    {signature.signature ? (
                      <span>
                        <EyeOutlined /> Review
                      </span>
                    ) : (
                      <span>
                        <EditOutlined /> Get
                      </span>
                    )}{" "}
                    Signature
                  </Button>
                  {cloverLoading && <SectionalLoading />}
                  {payment.is_paid && isNada(signature.signature) && (
                    <div style={{ height: "30px" }}>
                      <span className={"error-helper"}>
                        If paid, signature is required.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!props.hideButtons && (
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "30px" }}
                >
                  <Button
                    onClick={() => {
                      props.onCancel && props.onCancel();
                    }}
                    color="danger"
                    block
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={hasErrors()}
                    onClick={() => {
                      let paymentObject = {
                        ...payment,
                        paid_by: account.id,
                        paid_by_data: account,
                      };
                      console.log(paymentObject);
                      props.onSave && props.onSave(paymentObject);
                    }}
                    color="primary"
                    block
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          <SignaturePad
            onClose={() => {
              setPadShown(false);
            }}
            visible={padShown}
            onSign={(e) => {
              setSignature(e);
              setPadShown(false);
              setPayment({ ...payment, signature: e });

              props.onChange && props.onChange({ ...payment, signature: e });
            }}
            onChange={(e) => {
              setSignature(e);
              setPayment({ ...payment, signature: e });
              props.onChange && props.onChange({ ...payment, signature: e });
            }}
            signature={signature}
          />
        </div>

        <Modal
          visible={storageCharge.visible}
          onClose={() => {
            setStorageCharge({ ...storageCharge, visible: false });
          }}
        >
          <>
            <p>
              Tow date reported as{" "}
              <Chip>{moment(job.scheduled_at).format("MM/DD/YYYY")}</Chip> up
              until today <Chip>{moment(new Date()).format("MM/DD/YYYY")}</Chip>
            </p>
            <p>
              Days in storage <Chip>{+storageCharge.days}</Chip>
            </p>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, margin: "5px" }}>
                Per day
                <Input
                  value={`${storageCharge.perDay}`}
                  placeholder="Per day?"
                  type="number"
                  onChange={(e) => {
                    setStorageCharge({ ...storageCharge, perDay: Number(e) });
                  }}
                />
              </div>
              <div style={{ flex: 1, margin: "5px" }}>
                Days
                <Input
                  value={`${storageCharge.days}`}
                  placeholder="Per day?"
                  type="number"
                  onChange={(e) => {
                    setStorageCharge({ ...storageCharge, days: Number(e) });
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, margin: "5px" }}>
                <Button
                  onClick={() => {
                    handleAddCharge({
                      name: "Storage Fee",
                      amount: String(storageCharge.perDay * storageCharge.days),
                    });
                    setStorageCharge({ ...storageCharge, visible: false });
                  }}
                  block
                  color="primary"
                >
                  Add
                </Button>
              </div>
              <div style={{ flex: 1, margin: "5px" }}>
                <Button
                  onClick={() => {
                    setStorageCharge({ ...storageCharge, visible: false });
                  }}
                  block
                  color="default"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        </Modal>
      </form>
    );
  },
);

FormPayment.defaultProps = {
  hideButtons: false,
};
export default FormPayment;
