import React, { forwardRef, useEffect, useState } from "react";
import { CheckOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { toast } from "../SharedUtils";
import { Account, Unit } from "../Interfaces";

import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
} from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import API from "../API";
import { AxiosError } from "axios";

export interface Option {
  label: string | React.ReactElement | null;
  value: any;
}

type BaseSelectProps = {
  value?: number | Option;
  onChange?: (
    value: string | Option | null | Account | Unit,
    index?: number,
  ) => void;
  label?: string;
  style?: any;
  asyncSearchType?: "unit" | "account" | "impound" | "job" | "user";
};

type AsyncSelectProps = BaseSelectProps & {
  async: true;
  options?: never; // 'options' should not be provided when 'async' is true
  transformInput?: (input: string) => string;
  onCreate?: (input: string) => void;
  creatable?: boolean;
  clearable?: boolean;
  autofocus?: boolean;
  value?: Option;
};

type SyncSelectProps = BaseSelectProps & {
  async?: false; // Optional or explicitly set to false
  options: Option[] | string[]; // 'options' are required
  filter?: (options: Option, searchString: string) => Option[];
};

export type SelectProps = AsyncSelectProps | SyncSelectProps;

type ValueType = Option | null;

const CustomDropdownIndicator = (props: DropdownIndicatorProps<any, false>) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <div
      style={{ color: "var(--primary)", margin: "0 15px" }}
      onClick={() =>
        props.selectProps.menuIsOpen
          ? props.selectProps.onMenuClose()
          : props.selectProps.onMenuOpen()
      }
    >
      {menuIsOpen ? <UpOutlined /> : <DownOutlined />}
    </div>
  );
};

function customFilter({ label, value }: Option, str: string) {
  if (typeof label === "string") {
    return label.toLowerCase().includes(str.toLowerCase());
  } else if (
    React.isValidElement(label) &&
    "props" in label &&
    "data-label" in (label as React.ReactElement<any>).props
  ) {
    return (label as React.ReactElement<any>).props["data-label"]
      .toLowerCase()
      .includes(str.toLowerCase());
  }
  return true;
}

const CustomOption: React.FC<OptionProps<Option, false>> = (props) => {
  const { label, isSelected, innerProps, selectProps } = props;

  let textToMatch = "";
  let highlightedLabel = "";
  let content;

  if (typeof label === "string") {
    textToMatch = label;
    const regex = new RegExp(`(${selectProps.inputValue})`, "ig");
    highlightedLabel = textToMatch.replace(
      regex,
      '<strong style="color: var(--primary);">$1</strong>',
    );

    content = <div dangerouslySetInnerHTML={{ __html: highlightedLabel }} />;
  } else if (React.isValidElement(label)) {
    if ("data-label" in (label as React.ReactElement<any>).props) {
      textToMatch = (label as React.ReactElement<any>).props["data-label"];
    }
    content = label;
  }

  return (
    <components.Option {...props}>
      <div>
        {isSelected && (
          <span style={{ display: "inline-block", color: "var(--primary)" }}>
            <CheckOutlined />
          </span>
        )}
        <div
          style={{
            display: "inline-block",
            color: isSelected ? "var(--primary)" : "white",
            marginLeft: isSelected ? "10px" : "25px",
          }}
        >
          {content}
        </div>
      </div>
    </components.Option>
  );
};

const SelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: "none",
    background: "var(--background)",
    height: "50px",
    border: "1px solid var(--boxBackgroundColor)",
    borderRadius: "var(--radius)",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "var(--primary)",
    fontSize: "16px",
    fontWeight: "bold",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "var(--primary)",
    fontSize: "16px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: "var(--boxBackgroundColor)",
    padding: "15px",
    fontSize: "16px",
    borderBottom: "1px solid #8f8f8f",
    height: "50px",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    background: "var(--boxBackgroundColor)",
    borderRadius: "var(--radius)",
    marginTop: "0px",
    borderTopLeftRadius: "0",
    borderTopRightRadius: "0",
    border: "1px solid var(--primary)",
    borderTop: "none",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: "var(--primary)",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "var(--primary)",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "var(--primary)",
    fontSize: "16px",
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    color: "var(--primary)",
  }),
};

const SyncSelect2 = forwardRef<HTMLSelectElement, SyncSelectProps>(
  ({ options, onChange, value, label, style }: SyncSelectProps, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const isOptionArray = (array: any[]): array is Option[] => {
      return (
        array.length > 0 &&
        typeof array[0] === "object" &&
        array[0] !== null &&
        "value" in array[0] &&
        "label" in array[0]
      );
    };

    const normalizedOptions = isOptionArray(options)
      ? options
      : options.map((option, index) => ({ label: option, value: index }));

    useEffect(() => {
      const newSelectedOption = isOptionArray(options)
        ? normalizedOptions.find((opt) => opt.value === value) ||
          normalizedOptions[0]
        : normalizedOptions[value as number] || normalizedOptions[0];

      setSelectedOption(newSelectedOption);
    }, [value, options]);

    const initialSelectedOption = isOptionArray(options)
      ? normalizedOptions.find((opt) => opt.value === value) ||
        normalizedOptions[0]
      : normalizedOptions[value as number] || normalizedOptions[0];

    const [selectedOption, setSelectedOption] = useState<ValueType>(
      initialSelectedOption,
    );

    return (
      <div style={style}>
        <div
          style={{
            height: "60px",
          }}
        >
          <Select<Option, false>
            classNamePrefix="tw"
            menuIsOpen={isOpen}
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            isSearchable
            placeholder={label}
            options={normalizedOptions as Option[]}
            components={{
              Option: CustomOption,
              DropdownIndicator: CustomDropdownIndicator,
            }}
            onChange={(value) => {
              setSelectedOption(value);
              onChange && onChange(value ? value.value : 0);
            }}
            value={selectedOption}
            defaultValue={selectedOption}
            filterOption={customFilter}
            styles={SelectStyles}
          ></Select>
        </div>
      </div>
    );
  },
);

const AsyncSelect2 = forwardRef<HTMLSelectElement, AsyncSelectProps>(
  (
    {
      onChange,
      asyncSearchType,
      label,
      transformInput,
      style,
      onCreate,
      creatable,
      clearable,
      autofocus,
      value,
    }: AsyncSelectProps,
    ref,
  ) => {
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [searchString, setSearchString] = useState<string>("");
    const [options, setOptions] = useState<Option[]>([]);
    const [rawData, setRawData] = useState<[]>([]);

    useEffect(() => {
      if (value) {
        setSelectedOption(value);
      }
    }, [value]);
    const getDisplayLabel = (data: any): Option => {
      if (asyncSearchType === "account") {
        return {
          label: `${data.phone || ""} - ${data.name || ""}`,
          value: data.id,
        } as Option;
      } else if (asyncSearchType === "unit") {
        return {
          label: `${data.year || ""} ${data.make || ""} ${data.model || ""} - ${
            data.license || ""
          }`,
          value: data.id,
        };
      } else {
        return {
          label: data.name,
          value: data.id,
        };
      }
    };

    const getRawValueById = (id: number) => {
      return rawData.find((data: any) => data.id === id) as
        | Account
        | Unit
        | any;
    };

    const loadOptions = (
      inputValue: string,
      callback: (options: Option[]) => void,
    ) => {
      if (inputValue.length < 3) return callback([]);

      const api = API.getInstance();
      const on = asyncSearchType ? asyncSearchType : "account";
      api
        .search(on, inputValue)
        .then((res) => {
          let data = res.data;
          let options = data.map((data: any) => {
            return getDisplayLabel(data);
          }) as Option[];
          callback(options);
          setOptions(options);
          setRawData(data);
          return options;
        })
        .catch((err: AxiosError) => {
          if (err.code !== "ERR_CANCELED") {
            toast("Error loading accounts", { type: "error" });
          }
          return callback([]);
        });
    };

    return (
      <div style={style}>
        {creatable ? (
          <AsyncCreatableSelect
            key={selectedOption ? selectedOption.value : ""}
            classNamePrefix="tw"
            styles={SelectStyles}
            placeholder={label}
            cacheOptions={false}
            defaultOptions={options}
            loadOptions={loadOptions}
            options={[{ label: "Test", value: "test" }]}
            autoFocus={autofocus}
            onChange={(option, test: any) => {
              setSelectedOption(option);
              if (onChange) {
                console.log(option);
                if (option) {
                  onChange(getRawValueById(option.value));
                  if ("__isNew__" in option && option.__isNew__) {
                    onCreate && onCreate(searchString);
                  }
                } else {
                  onChange(null);
                  onCreate && onCreate(searchString);
                }
              }
            }}
            onInputChange={(value) => {
              if (transformInput) {
                value = transformInput(value);
              }
              setSearchString(value);
            }}
            inputValue={searchString}
            isClearable={clearable}
            value={selectedOption || value}
            components={{
              Option: CustomOption,
              DropdownIndicator: CustomDropdownIndicator,
            }}
          />
        ) : (
          <AsyncSelect
            key={selectedOption ? selectedOption.value : ""}
            classNamePrefix="tw"
            styles={SelectStyles}
            placeholder={label}
            cacheOptions={false}
            defaultOptions={options}
            loadOptions={loadOptions}
            options={[{ label: "Test", value: "test" }]}
            autoFocus={autofocus}
            onChange={(option, test: any) => {
              setSelectedOption(option);
              if (onChange) {
                console.log(option);
                if (option) {
                  onChange(getRawValueById(option.value));
                  if ("__isNew__" in option && option.__isNew__) {
                    onCreate && onCreate(searchString);
                  }
                } else {
                  onChange(null);
                  onCreate && onCreate(searchString);
                }
              }
            }}
            onInputChange={(value) => {
              if (transformInput) {
                value = transformInput(value);
              }
              setSearchString(value);
            }}
            inputValue={searchString}
            isClearable={clearable}
            value={selectedOption}
            components={{
              Option: CustomOption,
              DropdownIndicator: CustomDropdownIndicator,
            }}
          />
        )}
      </div>
    );
  },
);
const Select2 = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  if (props.async) return <AsyncSelect2 {...props} ref={ref} />;
  return <SyncSelect2 {...props} ref={ref} />;
});

Select2.defaultProps = {
  options: [],
  onChange: () => {},
  label: "Pick an option",
};
export default Select2;
