import React from "react";
import { JobType } from "../../../../shared/SharedTypes";

import { Colors } from "../../../../shared/Colors";

import { ReactComponent as TowingIcon } from "../../../../shared/assets/job-type-icons/tow-truck-svgrepo-com.svg";
import { ReactComponent as AccidentIcon } from "../../../../shared/assets/job-type-icons/car-collision-svgrepo-com.svg";
import { ReactComponent as ArrestIcon } from "../../../../shared/assets/job-type-icons/police-vest-svgrepo-com.svg";
import { ReactComponent as UnregisteredIcon } from "../../../../shared/assets/job-type-icons/license-plate-number-svgrepo-com.svg";
import { ReactComponent as TowZoneIcon } from "../../../../shared/assets/job-type-icons/no-parking-svgrepo-com.svg";
import { ReactComponent as SnowRemovalIcon } from "../../../../shared/assets/job-type-icons/snow-plow-svgrepo-com.svg";
import { ReactComponent as AbandonedIcon } from "../../../../shared/assets/job-type-icons/car-repair-svgrepo-com.svg";
import { ReactComponent as BreakDownIcon } from "../../../../shared/assets/job-type-icons/car-breakdown-svgrepo-com.svg";
import { ReactComponent as LockOutIcon } from "../../../../shared/assets/job-type-icons/car-rental-svgrepo-com.svg";
import { ReactComponent as JumpStartIcon } from "../../../../shared/assets/job-type-icons/car-battery-solid-svgrepo-com.svg";
import { ReactComponent as FuelDeliveryIcon } from "../../../../shared/assets/job-type-icons/map-car-fuel-svgrepo-com.svg";
import { ReactComponent as WinchOutIcon } from "../../../../shared/assets/job-type-icons/car-falling-svgrepo-com.svg";
import { ReactComponent as TireChangeIcon } from "../../../../shared/assets/job-type-icons/wheel-repair-svgrepo-com.svg";
import { ReactComponent as StolenIcon } from "../../../../shared/assets/job-type-icons/investigation-14px-svgrepo-com.svg";
import { ReactComponent as HeavyDuty } from "../../../../shared/assets/job-type-icons/truck-svgrepo-com.svg";
import { ReactComponent as Motorcycle } from "../../../../shared/assets/job-type-icons/motorcycle-so-svgrepo-com.svg";
import { ReactComponent as Boat } from "../../../../shared/assets/job-type-icons/boat-svgrepo-com.svg";
import { ReactComponent as RV } from "../../../../shared/assets/job-type-icons/real-estate-caravan-svgrepo-com.svg";
import { ReactComponent as Exotic } from "../../../../shared/assets/job-type-icons/sports-car-top-down-silhouette-svgrepo-com.svg";
import { ReactComponent as OffRoad } from "../../../../shared/assets/job-type-icons/road-weather-svgrepo-com.svg";
import { ReactComponent as Repo } from "../../../../shared/assets/job-type-icons/bank-svgrepo-com.svg";



export const Icons = [
  TowingIcon,
  AccidentIcon,
  ArrestIcon,
  UnregisteredIcon,
  TowZoneIcon,
  SnowRemovalIcon,
  AbandonedIcon,
  BreakDownIcon,
  LockOutIcon,
  JumpStartIcon,
  FuelDeliveryIcon,
  TowZoneIcon,
  WinchOutIcon,
  WinchOutIcon,
  TireChangeIcon,
  StolenIcon,
  HeavyDuty,
  Motorcycle,
  Boat,
  RV,
  Exotic,
  OffRoad,
  Repo,
];


interface JobIconProps {
  type: keyof typeof JobType;
  width: number;
  prefix?: any;
  postfix?: any;
  height?: number;
  color?: string;
  style?: any;
  borderLine?: boolean;
}

const JobIcon: React.FC<JobIconProps> = (props: JobIconProps) => {
  const color = props.color ? props.color : Colors.primary;
  const height = props.height ? props.height : props.width;
  const borderLine = props.borderLine !== false;


  const _props = {
    width: props.width,
    height: height,
    fill: color,
    style: props.style,
  };
  const type = props.type || 0;
  const Icon = Icons[Number(type)];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        lineHeight: height + "px"
      }}
    >
      {props.prefix && <div style={{ flex: 1 }}>{props.prefix}</div>}
      <div style={{ marginRight: borderLine ? "15px" : "unset",  marginTop: "5px", paddingRight: borderLine ? "15px" : "unset", borderRight: borderLine ? "1px solid #333":  "none"}}>
        <Icon {..._props} />
      </div>
      {props.postfix && <div style={{ flex: 1 }}>{props.postfix}</div>}
    </div>
  );
};

interface JobIcon2Props {
  type: keyof typeof JobType;
  width: number;
  height?: number;
  color?: string;
  style?: any;
  postfix?: any;
  icon?: any;
}
const JobIcon2: React.FC<JobIcon2Props> = (props: JobIcon2Props) => {
  const height = props.height ? props.height : props.width;
  const color = props.color ? props.color : Colors.primary;
  const Icon = props.icon ? props.icon : Icons[Number(props.type)];

  const _props = {
    width: props.width,
    height: height,
    fill: color,
    style: {
      ...props.style,
      display: "block",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ width: "20px", borderRight:"1px solid #333333", marginRight: "10px", paddingRight: "10px"}}>
        {props.icon ? props.icon : <Icon {..._props} />}
      </div>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        {props.postfix}
      </div>
    </div>
  );
};

export { JobIcon2 };
export default JobIcon;
