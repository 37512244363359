import React, {ForwardedRef, useEffect, useRef} from "react";
import {ContentState, Editor, EditorState, Modifier} from "draft-js";
import "draft-js/dist/Draft.css";
import {CloseOutlined, CodeOutlined, LineOutlined, OrderedListOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {marked} from "marked";

export interface TinyMarkdownProps {
    value?: string;
    onChange?: (value: string) => void;
    label?: string;
    isEditing?: boolean;
    display?: string;
    onEditModeChange?: (value: boolean) => void;
}


export const useClickOutsideEditorState = (ref: any, callback: (editorState: EditorState) => void, editorState: EditorState) => {
    const handleClick = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(editorState);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick, true);

        return () => document.removeEventListener("click", handleClick, true);
    }, [editorState]);
};


export const ParseMarkdown = (value: string) => {
    return marked(value, {
        mangle: false,
        headerIds: false,
        gfm: true,
        breaks: true,
    });
}

const TinyMarkdown = React.forwardRef((props: TinyMarkdownProps, ref: ForwardedRef<any>) => {

    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createWithContent(ContentState.createFromText(props.value || "")));
    const wrapperRef = useRef<HTMLDivElement>(null);
    const editorStateRef = useRef<EditorState>(editorState);

    useEffect(() => {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(props.value || "")));
    }, [props.value]);

    useEffect(() => {
        editorStateRef.current = editorState;
    }, [editorState]);


    useClickOutsideEditorState(wrapperRef, (es) => {
        if (wrapperRef.current) {
            if (!wrapperRef.current.classList.contains("in-edit")) return;
        }
        props.onChange && props.onChange(es.getCurrentContent().getPlainText());
        setIsEditing(false);
        props.onEditModeChange && props.onEditModeChange(false);
    }, editorState);


    const toggleHeading = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, insert: number) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();

        if (selectedText.startsWith('###')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(3)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        if (selectedText.startsWith('##')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(2)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        if (selectedText.startsWith('#')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(1)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        const newContent = Modifier.replaceText(contentState, selection, `${'#'.repeat(insert)} ${selectedText}`);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }

    const toggleBold = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();

        if (selectedText.startsWith('**') && selectedText.endsWith('**')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(2, -2)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        const newContent = Modifier.replaceText(contentState, selection, `**${selectedText}**`);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }

    const toggleItalic = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();

        if (selectedText.startsWith('*') && selectedText.endsWith('*')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(1, -1)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        const newContent = Modifier.replaceText(contentState, selection, `*${selectedText}*`);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }

    const toggleUnderlined = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();

        if (selectedText.startsWith('<u>') && selectedText.endsWith('</u>')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.trim().slice(3, -4)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        const newContent = Modifier.replaceText(contentState, selection, `<u>${selectedText}</u>`);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }

    const toggleStrikeThrough = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();

        if (selectedText.startsWith('~~') && selectedText.endsWith('~~')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(2, -2)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        const newContent = Modifier.replaceText(contentState, selection, `~~${selectedText}~~`);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }


    const insertList = (type: string) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();
        let newContent;
        if (type === "ol") {
            newContent = Modifier.replaceText(contentState, selection, `\n\n1. Item 1\n2. Item 2\n3. Item 3`);
        } else {
            newContent = Modifier.replaceText(contentState, selection, `\n\n- Item 1\n- Item 2\n- Item 3`);
        }
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }


    const toggleCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();

        if (selectedText.startsWith('```') && selectedText.endsWith('```')) {
            const newContent = Modifier.replaceText(
                contentState, selection, selectedText.slice(3, -3)
            );
            const newState = EditorState.push(editorState, newContent, "delete-character");
            setEditorState(newState);
            return;
        }

        const newContent = Modifier.replaceText(contentState, selection, `\`\`\`${selectedText}\`\`\``);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }

    const insertHorizontalRule = () => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const selectedText = contentState.getBlockForKey(selection.getStartKey()).getText().slice(selection.getStartOffset(), selection.getEndOffset()).trim();
        const newContent = Modifier.replaceText(contentState, selection, `\n----\n`);
        const newState = EditorState.push(editorState, newContent, "insert-characters");
        setEditorState(newState);
    }

    const showDisplayValue = () => {
        if (props.display) {
            if (props.display === "" || props.display === null) return <div onClick={() => {
                setIsEditing(true);
            }} className='preview' style={{color: "rgba(255,255,255, 0.5)"}}>Add more details...</div>
        }

        if (props.value === "" || props.value === null) {
            return <div onClick={() => {
                setIsEditing(true);
            }} className='preview' style={{color: "rgba(255,255,255, 0.5)"}}>Add more details...</div>
        }

        return <div onClick={() => {
            setIsEditing(true);
        }} className='preview'
                    dangerouslySetInnerHTML={{__html: ParseMarkdown(props.display ? props.display : (props.value || ""))}}/>
    }

    return <div id={`tiny-md-${Math.random()}`} ref={wrapperRef} className={`tiny-md ${isEditing ? "in-edit" : ""}`}>
        <h3>{props.label}</h3>
        {isEditing ? <div className={'edit'}>
            <div className={'toolbar'}>
                <button type="button" title="Large Heading" onClick={(e) => {
                    toggleHeading(e, 1)
                }}><span>Aa</span>
                </button>
                <button type="button" title="Medium Heading" onClick={(e) => {
                    toggleHeading(e, 2)
                }}><span style={{fontSize: "16px"}}>Aa</span></button>
                <button type="button" title="Small Heading" onClick={(e) => {
                    toggleHeading(e, 3)
                }}><span style={{fontSize: "12px"}}>Aa</span></button>
                <span className={'separator'}/>
                <button type="button" title="Bold" onClick={(e) => toggleBold(e)}><span style={{fontWeight: "bold"}}>B</span></button>
                <button type="button" title="Italic" onClick={(e) => {
                    toggleItalic(e)
                }}><span style={{fontStyle: "italic"}}>I</span></button>
                <button type="button" title="Underline" onClick={(e) => toggleUnderlined(e)}><span
                    style={{borderBottom: "1px solid var(--primary)"}}>U</span></button>
                <button type="button" title="Strike Through" onClick={(e) => toggleStrikeThrough(e)}><span
                    style={{color: "red", textDecoration: "line-through"}}><span
                    style={{color: "var(--primary)"}}>S</span></span></button>
                <span className={'separator'}/>
                <button type="button" title="Unordered List" onClick={(e) => {
                    insertList("ul")
                }}>
                    <UnorderedListOutlined/>
                </button>
                <button type="button" title="Unordered List" onClick={(e) => {
                    insertList("ol")
                }}>
                    <OrderedListOutlined/>
                </button>
                <button type="button" title="Separator" onClick={insertHorizontalRule}><LineOutlined/></button>
                <span className={'separator'}/>
                <button type="button" title="Raw Code" onClick={(e) => {
                    toggleCode(e)
                }}><CodeOutlined/></button>
                <button type="button" onClick={() => {
                    props.onChange && props.onChange(editorState.getCurrentContent().getPlainText());
                    setIsEditing(false);
                    props.onEditModeChange && props.onEditModeChange(false);
                }}>
                    <CloseOutlined/>
                </button>
            </div>
            <div className={'editor'}><Editor editorState={editorState} onChange={(e) => {
                setEditorState(e);
            }}/></div>
        </div> : showDisplayValue()}
    </div>
});

TinyMarkdown.defaultProps = {
    isEditing: false,
}
export default TinyMarkdown;

