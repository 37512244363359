import React, {useEffect, useState} from "react";
import {Account as IAccount} from "../../../../shared/Interfaces";
import {AccountType} from "../../../../shared/SharedTypes";
import {
    UserOutlined,
    EditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    PhoneOutlined,
    MailOutlined, GlobalOutlined
} from "@ant-design/icons";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import API from "../../../../shared/API";
import {Loading} from "../../../../shared/components/Loading";
import {Button, Dialog, Form, Input, Picker, Switch, TextArea} from "antd-mobile";
import {PhoneMask} from "../../../../shared/InputMask";
import FormAccount from "./FormAccount";
import {toast} from "../../../../shared/SharedUtils";



const EditAccount: React.FC<IAccount> = (props: IAccount) => {

    const [loading, setLoading] = useState<boolean>(false);
    const { id }= useParams();
    const { state } = useLocation();
    const [account, setAccount] = useState<IAccount>({} as IAccount);
    const [pickerVisible, setPickerVisible] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        if (state === null) {
            const api = API.getInstance();
            api.fetchAccount(Number(id)).then((res) => {
                setAccount(res.data);
            }).catch((e) => {
                toast("Error fetching account", {type: "error"},e);
            }).finally(()  => {
                setLoading(false);
            });
        } else {

            setAccount(state);
            setLoading(false);
        }

    }, []);



    const saveEntity = (formAccount: IAccount) => {
        setSending(true);
        const api = API.getInstance();
        api.updateAccount(formAccount).then((res) => {
            Dialog.alert({
                content: "Account updated successfully",
                onConfirm: () => {
                    navigate("/accounts");
                },
                confirmText: "OK"
            });
        }).catch((e) => {
            toast("Error updating account", {type: "error"},e)
        }).finally(() => {
            setSending(false);
        });
    }

    return  loading ? <Loading /> : <FormAccount account={account} loading={sending} onChange={(account: IAccount) => { setAccount(account); }} onCancel={() => { navigate(-1);}}  onSave={saveEntity}/>
}

export default EditAccount;
