import React, { forwardRef, useEffect, useState } from "react";
import {
  Account as IAccount,
  Job as IJob,
  JobWithMetadata,
  User as IUser,
} from "../../../../shared/Interfaces";
import {
  CALENDAR_DB_FORMAT,
  CALENDAR_DT_INPUT_FORMAT,
  EquipmentType,
  SharedLotAddresses,
} from "../../../../shared/SharedTypes";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import {
  filterJobTypeOptions,
  filterOptionsByStatusTypes,
  getJobTypeOptionsWithIcons,
  getStatusTypesWithChips,
} from "../../../../shared/SharedUtils";
import Form from "../../../../shared/components/Form2";
import moment from "moment";
import Chip from "../../../../shared/components/Chip";
import { isMobile } from "../../../../shared/SharedUtils";
import { Switch } from "antd-mobile";
import {
  BellOutlined,
  CloseOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  WarningOutlined,
} from "@ant-design/icons";

interface FormJobProps {
  loading: boolean;
  job: JobWithMetadata;
  onChange: (job: JobWithMetadata) => void;
  onCancel?: () => void;
  onSave?: (job: JobWithMetadata, extra?: any) => void;
  hideButtons?: boolean;
  onSubmitSuccess?: () => void;
}

const FormJob = forwardRef<HTMLFormElement, FormJobProps>((props, ref) => {
  const [equipment, setEquipment] = useState<number[]>([]);
  const [job, setJob] = useState<JobWithMetadata>(props.job);

  const [driverChanged, setDriverChanged] = useState<boolean>(false);
  const { session, hasPerms } = useAuth();

  useEffect(() => {
    setEquipment(job.equipment || []);
  }, []);

  return (
    <Form
      ref={ref}
      initialData={job}
      onSubmit={(job) => {
        props.onSave &&
          props.onSave({ ...job, equipment: equipment }, { driverChanged });
        props.onSubmitSuccess && props.onSubmitSuccess();
      }}
      onCancel={props.onCancel}
      hideButtons={props.hideButtons}
    >
      <Form.AutoComplete
        placeholder={"Pickup Address"}
        options={SharedLotAddresses}
        name="from_address"
        rules={{
          required: true,
        }}
      />
      <Form.AutoComplete
        placeholder={"Destination Address"}
        options={SharedLotAddresses}
        name="destination_address"
      />
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ flex: 3 }}>
          <p style={{ lineHeight: "40px" }}>Job Type</p>
        </div>
        <div style={{ flex: 11 }}>
          <Form.Select
            options={getJobTypeOptionsWithIcons()}
            name="tow_type"
            label="Type"
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ flex: 3 }}>
          <p style={{ lineHeight: "40px" }}>Reason</p>
        </div>
        <div style={{ flex: 11 }}>
          <Form.Select
            options={getJobTypeOptionsWithIcons()}
            name="reason"
            label="Type"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile() ? "column" : "row",
          gap: "10px",
        }}
      >
        <div style={{ flex: 1 }}>
          <Form.Select
            options={getStatusTypesWithChips()}
            name="status"
            label="Status"
          />
        </div>

        <div style={{ flex: 1 }}>
          <Form.Input
            type="datetime-local"
            name="scheduled_at"
            placeholder="Schedule Time"
            transform={(value: string) =>
              moment(value).format(CALENDAR_DT_INPUT_FORMAT)
            }
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile() ? "column" : "row",
          gap: "10px",
        }}
      >
        <span style={{ fontSize: "24px", lineHeight: "70px" }}>$</span>
        <div style={{ flex: 1 }}>
          <Form.Input placeholder="Quoted Price" name="quoted" type="number" />
        </div>
        <div style={{ flex: 1 }}>
          <Form.Input type="text" placeholder="Reference" name="reference" />
        </div>
      </div>

      <div className="equipment-list">
        {EquipmentType.map((label, index) => {
          const className = equipment.includes(index)
            ? "selected"
            : "deselected";
          const icon = equipment.includes(index) ? (
            <PlusSquareOutlined />
          ) : (
            <MinusSquareOutlined />
          );
          return (
            <Chip
              onClick={() => {
                if (index === 0) {
                  setEquipment([]);
                  return;
                }
                if (equipment.includes(index)) {
                  setEquipment(equipment.filter((e) => e !== index));
                } else {
                  setEquipment([...equipment, index]);
                }
              }}
              size="large"
              type={className}
              key={index}
            >
              {index === 0 ? <CloseOutlined /> : icon} {label}
            </Chip>
          );
        })}
      </div>

      <Form.TextArea label="Public Notes" name="public_notes" />
      {hasPerms() && (
        <>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              gap: "10px",
              flexDirection: isMobile() ? "column" : "row",
            }}
          >
            <div style={{ flex: 1 }}>
              <Form.Switch
                name={"send_reminder"}
                defaultChecked
                label={"Send Reminders"}
              />
            </div>
          </div>

          <div style={{ marginTop: "15px" }}>
            <Form.TextArea label="Private Notes" name="private_notes" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "15px",
            }}
          >
            <div style={{ flex: 1 }}>
              <Form.EntitySelector3
                entityType="user"
                name="assigned_to"
                label="Assigned To"
                rules={{ required: true }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Form.EntitySelector3
                entityType="account"
                name="requested_by"
                label="Requested By"
                editable
                clearable
                defaultValue={
                  {
                    value: job.account_name || "",
                    id: job.account_id,
                    is_active: true,
                  } as IAccount
                }
              />
            </div>

            <div style={{ flex: 1 }}>
              <Form.EntitySelector3
                entityType="account"
                name="released_to"
                label="Released To"
                editable
                clearable
                defaultValue={
                  {
                    value: job.account_name || "",
                    id: job.account_id,
                    is_active: true,
                  } as IAccount
                }
              />
            </div>
          </div>
        </>
      )}
    </Form>
  );
});
FormJob.defaultProps = {
  hideButtons: false,
};

export default FormJob;
