import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater";
import { App, AppState } from "@capacitor/app";
import { SplashScreen } from "@capacitor/splash-screen";
import { set } from "react-hook-form";

class AppUpdater {
  private appState: AppState;
  private appData: BundleInfo | undefined;

  constructor(state: AppState) {
    this.appState = state;
    console.log("[AppUpdater] constructor", state);
    if (state.isActive) {
      this.checkForUpdates().then(() => {
        //
      });
    }

    if (!state.isActive && this.appData) {
      SplashScreen.show().then(() => {});
      try {
        const setBundle = async () => {
          console.log("[AppUpdater] setBundle", this.appData?.version);
          if (this.appData) await CapacitorUpdater.set({ id: this.appData.id });
        };

        setBundle().then(() => {
          console.log("[AppUpdater] setBundle done", this.appData?.version);
        });
      } catch (e) {
        console.error("[AppUpdater] setBundle error", e);
      }
    }
  }

  async checkForUpdates() {
    console.log("[AppUpdater] checkForUpdates");
    const latest = await CapacitorUpdater.getLatest();
    if (latest.url) {
      console.log("[AppUpdater] download", latest.url);
      this.appData = await CapacitorUpdater.download({
        url: latest.url,
        version: latest.version,
      });
    }
  }
}

export { AppUpdater };
