import React, { useState } from "react";
import { JobListing } from "../../../../shared/Interfaces";
import { JobType, StatusType } from "../../../../shared/SharedTypes";
import { Colors } from "../../../../shared/Colors";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CarOutlined,
  EllipsisOutlined,
  GlobalOutlined,
  UserOutlined,
} from "@ant-design/icons";

import JobIcon from "./JobIcon";
import { isMobile, isNA, missingo } from "../../../../shared/SharedUtils";
import {
  getMapProviderUrl,
  hid,
  statusClassName,
} from "../../../../shared/SharedUtils";
import { Button, Tag } from "antd-mobile";
import moment from "moment";
import Chip from "../../../../shared/components/Chip";

interface JobSummaryProps extends JobListing {
  onClick: () => void;
  hideAssignedTo?: boolean;
  onMiddleMouseClicked?: (e: any) => void;
  onActionsButtonClicked: (job: JobListing) => void;
}

const JobSummaryMobile = (props: JobSummaryProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const isCancelOrCompleted =
    Number(props.status) === 2 || Number(props.status);

  return (
    <div className={`box`}>
      <div
        className={isCancelOrCompleted ? "canceled-or-completed" : ""}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div style={{ display: "flex", textAlign: "center" }}>
          <div style={{ flex: 1 }}>
            {props.unit_year && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5px",
                }}
              >
                <div style={{ flex: 1, alignSelf: "center" }}>
                  <CarOutlined
                    style={{
                      fontSize: "20px",
                      height: "25px",
                      color: Colors.primary,
                    }}
                    color={Colors.primary}
                  />
                </div>
                <div style={{ flex: 11, marginLeft: "5px", marginTop: "5px" }}>
                  {`${props.unit_year || ""} ${props.unit_make || ""} ${
                    props.unit_model || ""
                  }`}
                </div>
              </div>
            )}
          </div>
          {!props.hideAssignedTo && (
            <div style={{ flex: 1 }}>
              {props.user_name && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "5px",
                  }}
                >
                  <div style={{ flex: 1, alignSelf: "center" }}>
                    <UserOutlined
                      style={{
                        fontSize: "20px",
                        height: "25px",
                        color: Colors.primary,
                      }}
                      color={Colors.primary}
                    />
                  </div>
                  <div
                    style={{
                      flex: 11,
                      marginLeft: "5px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                  >
                    {`${props.user_name || ""} `}
                  </div>
                </div>
              )}
            </div>
          )}
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "5px",
              }}
            >
              <div style={{ flex: 1, alignSelf: "center" }}>
                <JobIcon
                  type={Number(props.tow_type)}
                  width={25}
                  borderLine={false}
                />
              </div>
              <div
                style={{ flex: 11, margin: "5px 10px", textAlign: "center" }}
              >
                {JobType[Number(props.tow_type)]}
              </div>
            </div>
          </div>
          <div style={{ alignSelf: "center" }}>
            {expanded ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
          </div>
        </div>
        {(props.status === 4 || props.status === 5) && (
          <Chip
            size="small"
            type="info"
            style={{ position: "absolute", top: "-8px", right: "-9px" }}
          >
            {props.status === 4 ? "Scheduled" : "Public"}
          </Chip>
        )}
        {expanded && (
          <div
            style={{
              borderTop: "1px solid var(--primary)",
              marginTop: "15px",
              marginBottom: "5px",
            }}
          >
            <div style={{ display: "flex", marginTop: "15px" }}>
              <div style={{ flex: "1", display: "flex", margin: "5px" }}>
                <div style={{ flex: 1, alignSelf: "center" }}>
                  <GlobalOutlined color={Colors.primary} />
                </div>
                <div style={{ flex: 11, marginLeft: "5px" }}>
                  {`${props.from_address}`}
                </div>
              </div>
              <div style={{ flex: "1", display: "flex", margin: "5px" }}>
                <div style={{ flex: 1, alignSelf: "center" }}>
                  <ArrowRightOutlined color={Colors.primary} />
                </div>
                <div style={{ flex: 11, marginLeft: "5px" }}>
                  {`${props.destination_address}`}
                </div>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <Button
                fill="none"
                size="mini"
                block
                className={statusClassName(Number(props.status))}
                style={{ margin: "5px" }}
              >
                {moment(props.scheduled_at).fromNow()}
              </Button>
              <Button
                fill="none"
                size="mini"
                block
                className={statusClassName(Number(props.status))}
                style={{ margin: "5px" }}
              >
                {StatusType[Number(props.status)]}
              </Button>
            </div>
          </div>
        )}
      </div>
      {expanded && (
        <div style={{ marginTop: "15px" }}>
          <Button
            className="actions-button"
            style={{ textDecoration: "none" }}
            fill="solid"
            color="primary"
            block
            onClick={() => {
              props.onActionsButtonClicked?.(props);
            }}
          >
            Actions <EllipsisOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};

const JobSummaryDesktop = (props: JobSummaryProps) => {
  return (
    <tr
      className={
        props.status === 2 || props.status === 3 ? "tr-status-closed" : ""
      }
    >
      <td
        className={statusClassName(Number(props.status))}
        onClick={props.onClick}
        onMouseDown={props.onMiddleMouseClicked}
      >
        <div style={{ display: "flex" }}>
          <JobIcon
            type={Number(props.tow_type)}
            width={25}
            borderLine={false}
            postfix={
              <div>
                <span
                  style={{ color: "var(--infoTextColor)" }}
                  className={"chip medium"}
                >
                  {hid(props.id)}
                </span>
                {props.status === 4 && (
                  <Tag
                    style={{ marginLeft: "10px", marginTop: "5px" }}
                    color="blue"
                  >
                    Scheduled
                  </Tag>
                )}
              </div>
            }
          />
        </div>
      </td>
      <td onClick={props.onClick}>
        {!isNA(props.from_address) ? (
          <a
            href={getMapProviderUrl(props.from_address || "")}
            target="_blank"
            rel="noreferrer"
          >
            <GlobalOutlined /> {props.from_address}
          </a>
        ) : (
          missingo(props.from_address, "No Address")
        )}
      </td>
      <td onClick={props.onClick}>
        {!isNA(props.destination_address) ? (
          <a
            href={getMapProviderUrl(props.destination_address || "")}
            target="_blank"
            rel="noreferrer"
          >
            <GlobalOutlined /> {props.destination_address}
          </a>
        ) : (
          missingo(props.destination_address, "No Address")
        )}
      </td>
      <td onMouseDown={props.onMiddleMouseClicked} onClick={props.onClick}>
        <span>
          <CarOutlined />{" "}
          {`${props.unit_year} ${props.unit_make} ${props.unit_model}`}{" "}
          {props.unit_license && <> | {props.unit_license}</>}
        </span>
      </td>
      <td onMouseDown={props.onMiddleMouseClicked} onClick={props.onClick}>
        <span>
          <UserOutlined /> {`${props.account_name}`}{" "}
          {props.account_phone && <> | {props.account_phone}</>}
        </span>
      </td>
      {!props.hideAssignedTo && (
        <td onMouseDown={props.onMiddleMouseClicked} onClick={props.onClick}>
          <span>
            <UserOutlined /> {`${props.user_name}`}
          </span>
        </td>
      )}
      <td>
        <Button
          className="actions-button"
          style={{ textDecoration: "none" }}
          fill="none"
          color="primary"
          block
          onClick={() => {
            props.onActionsButtonClicked?.(props);
          }}
        >
          <EllipsisOutlined />
        </Button>
      </td>
    </tr>
  );
};

const JobSummary: React.FC<JobSummaryProps> = (props: JobSummaryProps) => {
  return (
    <>
      {isMobile() ? (
        <JobSummaryMobile {...props} />
      ) : (
        <JobSummaryDesktop {...props} />
      )}
    </>
  );
};

export default JobSummary;
