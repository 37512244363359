import {RequiredUnitFields, useJobCreateContext} from "../JobCreate";
import Select2 from "../../../../../shared/components/Select2";
import {PhoneMask} from "../../../../../shared/InputMask";
import {Unit as IUnit} from "../../../../../shared/Interfaces";
import Button from "antd-mobile/es/components/button";
import {PlusOutlined} from "@ant-design/icons";
import {Dialog, Divider} from "antd-mobile";
import FormUnit from "../../units/FormUnit";
import Unit from "../../units/Unit";
import NextOrBackButtons from "./NextOrBack";
import {useNavigate} from "react-router-dom";
import {toast} from "../../../../../shared/SharedUtils";
import {useEffect} from "react";
import {isAccountValid, isUnitValid} from "./Validators";

const StepUnit: React.FC = () => {
    const {create, setCreate, unit, setUnit, account} = useJobCreateContext();
    const navigate = useNavigate();


    useEffect(() => {
        if (!isAccountValid(account)) navigate('/jobs/create/step-account')
    }, []);




    const goToNextStep = (newUnit: IUnit) => {
        if (!isUnitValid(newUnit)) {
             toast("Unit information (make) required, select existing or add new.", {
                type: "error",
                autoClose: 5000
            });
        } else {

            if (unit.id === undefined) setUnit({...unit, id: 0});
            navigate('/jobs/create/step-payment');
        }
    }


    return <div style={{ marginTop: "15px"}}>
        <div>
                        <div className={"form"}>
                            <Select2
                                async
                                asyncSearchType="unit"
                                label="Search or add new"
                                onChange={(option) => {
                                    if (option) {
                                        setUnit(option as IUnit);
                                        setCreate({...create, isUnitCreateMode: false});
                                    }
                                }}
                                onCreate={(make) => {

                                    setCreate({...create, isUnitCreateMode: true});
                                }}
                            />
                            <Button
                                onClick={() => {
                                    setCreate({...create, isUnitCreateMode: true});

                                }}
                                fill={"none"}
                            >
                                <PlusOutlined/>
                                Add New Unit
                            </Button>

                            {create.isUnitCreateMode ? (
                                <>
                                    <Divider/>
                                    <FormUnit
                                        loading={false}
                                        unit={unit}
                                        onChange={(unit) => {
                                            setUnit(unit);
                                        }}
                                        onSave={(newUnit) => {
                                                goToNextStep(newUnit);
                                        }}
                                        onCancel={() => {
                                            navigate(-1)
                                        }}
                                    /></>
                            ) : <>
                                {unit.id && unit.id !== 0 ? <Unit {...unit} editable={false}/> : null}
                                <NextOrBackButtons onCancel={() => {
                                    navigate(-1)
                                }} onSubmit={() => {
                                    goToNextStep(unit);
                                }}/>
                            </>}
                        </div>
                    </div>
    </div>
}
export default StepUnit;
