import {Preferences} from "@capacitor/preferences";



export const setStored = (key: string, value: any, expires?: Date) => {
  return new Promise<void>((resolve, reject) => {
    const item = JSON.stringify({ value, expires: expires?.toISOString() });
    Preferences.set({ key, value: item })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const fetchStored = (key: string, verifyExpiration?: boolean) => {
  return new Promise<any>((resolve, reject) => {
    Preferences.get({ key })
      .then(({ value: item }) => {
        if (!item) return resolve(null);

        const { value, expires } = JSON.parse(item);

        if (verifyExpiration && expires) {
          const currentDate = new Date();
          const expiresDate = new Date(expires);

          if (currentDate > expiresDate) {
            Preferences.remove({ key })
              .then(() => resolve(null))
              .catch((error) => reject(error));
            return;
          }
        }

        resolve(value);
      })
      .catch((error) => reject(error));
  });
};

export const removeStored = (key: string) => {
  return new Promise<void>((resolve, reject) => {
    Preferences.remove({ key })
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};
/*

export const fetchStoredExpiring = async (key: string) => {
  let data = await fetchStored(key);

  if (data && data.expires && data.expires < Date.now()) {
    await removeStored(key);
    return null;
  } else {
    return data;
  }
};

/!**
 *
 * @param key
 * @param value
 * @param expires in milliseconds
 *!/
export const setStoredExpiring = async (
  key: string,
  value: any,
  expires: number,
) => {
  return await setStored(key, { value: value, expires: Date.now() + expires });
};

export const fetchStoredExpiredOrSet = async (
  key: string,
  func: () => Promise<any>,
  expires: number,
) => {
  let data = await fetchStoredExpiring(key);
  if (!data) {
    let newData = await func();
    await setStoredExpiring(key, newData, expires);
    return newData;
  } else {
    return data.value;
  }
};
*/
