import React, { useEffect, useState } from "react";
import { Job as IJob, Unit as IUnit } from "../../../../shared/Interfaces";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../../shared/API";
import { Loading } from "../../../../shared/components/Loading";
import { Dialog } from "antd-mobile";
import FormJob from "./FormJob";
import { getRealId, toast } from "../../../../shared/SharedUtils";

const EditJob: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const { state } = useLocation();
  const [job, setJob] = useState<IJob>(state || ({} as IJob));
  const [unit, setUnit] = useState<IUnit>({} as IUnit);
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const api = API.getInstance();
    setLoading(true);
    const real_id = getRealId(id);
    api
      .fetchJob(Number(real_id))
      .then((res) => {
        setJob(res.data);
        api
          .fetchUnit(res.data.unit_id)
          .then((res) => {
            setUnit(res.data);
          })
          .catch((e) => {
            console.log(e);
            toast("Error fetching unit", { type: "error" });
          });
      })
      .catch((e) => {
        console.log(e);
        toast("Error fetching job", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const updateUnitLocation = (location: string) => {
    const api = API.getInstance();
    unit.current_location = location;
    setUnit(unit);
    api
      .updateUnit(unit)
      .then((res) => {
        toast("Unit location updated successfully", { type: "success" });
      })
      .catch((e) => {
        toast("Error updating unit location", { type: "error" });
      })
      .finally(() => {
        setSending(false);
      });
  };

  const saveEntity = (_job: IJob, extra?: any) => {
    setSending(true);
    const api = API.getInstance();
    api
      .patchJob(_job.id, _job)
      .then((res) => {
        toast("Job updated successfully", { type: "success" });
        if (extra && extra.updateCurrentLocation) {
          updateUnitLocation(extra.location);
        }
      })
      .catch((e) => {
        toast("Error updating job", { type: "error" });
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    //console.log('job changed', job);
  }, [job]);
  return loading ? (
    <Loading />
  ) : (
    <FormJob
      job={job}
      loading={sending}
      onChange={(job) => {
        setJob(job);
      }}
      onCancel={() => {
        navigate(-1);
      }}
      onSave={(_job) => {
        if (_job.destination_address !== unit.current_location) {
          Dialog.confirm({
            title: "Confirm",
            content: (
              <p>
                The vehicle is currently not at the job's drop-off address.
                Would you like to set the vehicle's location to this drop-off
                address?
              </p>
            ),
            confirmText: "Yes",
            cancelText: "No",
            onConfirm: () => {
              saveEntity(_job, {
                updateCurrentLocation: true,
                location: _job.destination_address,
              });
            },
            onCancel: () => {
              saveEntity(_job);
            },
          });
        } else {
          saveEntity(_job);
        }
      }}
    />
  );
};

export default EditJob;
