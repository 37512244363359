import React from "react";
import { FleetUnit as IFleetUnit } from "../../../../shared/Interfaces";
import { FleetUnitType, UnitType } from "../../../../shared/SharedTypes";
import {
  EditOutlined,
  CarOutlined,
  CalendarOutlined,
  BarcodeOutlined,
  ForwardOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { isMobile, isNA, missingo } from "../../../../shared/SharedUtils";
import Chip from "../../../../shared/components/Chip";
import { Button } from "antd-mobile";

const FleetUnit: React.FC<IFleetUnit> = (props: IFleetUnit) => {
  const navigate = useNavigate();
  const renderMobile = () => {
    return (
      <div className="box">
        <table className={"listing-table condensed"}>
          <tbody>
            <tr>
              <td>Unit</td>
              <td>
                {`${props.year || ""} ${props.make || ""} ${props.model || ""}`}
              </td>
            </tr>
            <tr>
              <td>License</td>
              <td>{missingo(props.license, "No License Plate")}</td>
            </tr>
            <tr>
              <td>VIN</td>
              <td>{missingo(props.vin, "No VIN")}</td>
            </tr>
            <tr>
              <td>Color</td>
              <td>{missingo(props.color, "No Color")}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>
                <Chip>{FleetUnitType[Number(props.unit_type)]}</Chip>
              </td>
            </tr>
            <tr>
              <td>Registration Expires</td>
              <td>{missingo(props.registration_expires, "No Expiration")}</td>
            </tr>
            <tr>
              <td>Odometer</td>
              <td>{missingo(props.odo, "No Odometer")}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <p>
            <Button
              block
              onClick={() => navigate(`/accounts/${props.id}/jobs`)}
            >
              <ForwardOutlined /> Account Jobs
            </Button>
          </p>
          <p>
            <Button
              block
              onClick={() => navigate(`/accounts/${props.id}/edit`)}
            >
              <EditOutlined /> Edit
            </Button>
          </p>
        </div>
      </div>
    );
  };

  const renderWeb = () => {
    return (
      <tr>
        <td style={{ width: "60px" }}>{missingo(props.year, "No Year")}</td>
        <td>{missingo(props.make, "No Make")}</td>
        <td>{missingo(props.model, "No Model")}</td>
        <td>{missingo(props.license, "No License")}</td>
        <td>{missingo(props.vin, "No VIN")}</td>
        <td>{missingo(props.color, "No Color")}</td>
        <td style={{ textAlign: "center" }}>
          <Chip>{UnitType[Number(props.unit_type)]}</Chip>
        </td>
        <td>{props.odo}</td>
        <td>{props.registration_expires}</td>
        <td style={{ float: "right" }}>
          <Button
            onClick={() => {
              navigate(`/units/${props.id}/jobs`);
            }}
            size="small"
          >
            <ForwardOutlined /> Unit Jobs
          </Button>
          <Button
            onClick={() => navigate(`/units/${props.id}/edit`)}
            size="small"
          >
            <EditOutlined /> Edit
          </Button>
        </td>
      </tr>
    );
  };

  return isMobile() ? renderMobile() : renderWeb();
};

export default FleetUnit;
