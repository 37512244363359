import React, { useEffect, useState } from "react";
import {
  Account as IAccount,
  Charge,
  Job as IJob,
  Payment as IPayment,
  Unit as IUnit,
  User as IUser,
  Resource,
} from "../../../../shared/Interfaces";
import {
  CALENDAR_DB_FORMAT,
  Categories,
  JobType,
  PaymentMethod,
  SharedLotAddresses,
} from "../../../../shared/SharedTypes";
import { useNavigate } from "react-router-dom";
import { OverlayLoading } from "../../../../shared/components/Loading";
import {
  getJobTypeOptionsWithIcons,
  getModelsFromBrand,
  getStatusTypesWithChips,
  hid,
  Makes,
  toast,
  formatCurrency,
} from "../../../../shared/SharedUtils";

import Layout from "../../../../shared/components/Layout";

import { isMobile } from "../../../../shared/SharedUtils";
import moment from "moment";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import { Button, Divider, Input, Switch } from "antd-mobile";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import AutoComplete from "../../../../shared/components/AutoComplete";
import {
  ColorMask,
  PhoneMask,
  PlateMask,
  VinMask,
  YearMask,
} from "../../../../shared/InputMask";
import TinyMarkdown from "../../../../shared/components/TinyMarkdown";
import API from "../../../../shared/API";
import Select2, { Option } from "../../../../shared/components/Select2";
import { RequiredAccountFields, RequiredUnitFields } from "./JobCreate";
import { AsyncLocalStorage } from "async_hooks";
import { fetchStored } from "../../../../shared/AsyncStorage";

const JobQuickCreate: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [collapsedUnit, setCollapsedUnit] = useState<boolean>(false);
  const [jobCollapsed, setJobCollapsed] = useState<boolean>(false);
  const [models, setModels] = useState<string[]>([]);

  const [sending, setSending] = useState<boolean>(false);
  const navigate = useNavigate();

  const [drivers, setDrivers] = useState<IUser[]>([] as IUser[]);

  const { hasPerms, session } = useAuth();
  const [account, setAccount] = useState(RequiredAccountFields);
  const [unit, setUnit] = useState(RequiredUnitFields);
  const [payment, setPayment] = useState({
    payment_type: 0,
    charges: [{ name: JobType[0], amount: "0.00" } as Charge] as Charge[],
    is_paid: false,
    total: "0.00",
    payment_received_by: session?.user?.id,
    payment_date: moment().format(CALENDAR_DB_FORMAT),
  } as IPayment);

  const [job, setJob] = useState({
    reason: 0,
    status: 0,
    category: 0,
    quoted: "0.00",
    tow_type: 0,
    scheduled_at: moment().format(CALENDAR_DB_FORMAT),
    send_reminder: true,
  } as IJob);
  const [user, setUser] = useState({} as IUser);
  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    make: false,
    user: false,
    clover: false,
  });
  const [reason, setReason] = useState<number>(0);

  useEffect(() => {
    const cloverHasErrors: boolean =
      ((payment.payment_type === 2 && payment.is_paid) as boolean) &&
      (!payment.clover_order_id || payment.clover_order_id.length < 1);
    console.log(cloverHasErrors);
    setErrors({
      ...errors,
      name: (account.name?.length ?? 0) < 3,
      phone: (account.phone?.length ?? 0) < 12,
      make: (unit.make?.length ?? 0) < 3,
      user: (user.id ?? 0) < 1,
      clover: cloverHasErrors,
    });
  }, [account, unit, user, payment]);

  useEffect(() => {
    setPayment({
      ...payment,
      charges: [{ name: JobType[reason], amount: payment.total }],
    });
  }, [payment.total]);

  useEffect(() => {
    if (payment.charges) {
      let charges = payment.charges[0];
      charges.name = JobType[reason];
      setPayment({ ...payment, charges: [charges] });
    }
  }, [reason]);

  // Set the drivers array if it exists in storage
  // Set selected user by default currently logged-in user
  useEffect(() => {
    fetchStored("drivers").then((result) => {
      if (result) {
        setDrivers(result);
        setUser({ ...user, id: Number(session?.user?.id) });
      }
    });
  }, []);
  const isError = () => {
    return Object.values(errors).filter((e) => e).length > 0;
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    setSending(true);
    let api = API.getInstance();

    try {
      let accountId = account.id;
      let unitId = unit.id;
      let userId = user.id;

      let resource: Resource = {
        account: account,
        unit: unit,
        job: job,
        payment: payment,
        assigned_to: userId,
      };

      if (accountId) {
        resource.account = accountId;
      }

      if (unitId) {
        resource.unit = unitId;
      }

      if (!userId || userId === 0) {
        toast("User is required", { type: "error" });
        return;
      } else {
        api.createJobResource(resource).then((result) => {
          toast("Job created successfully", { type: "success" });
          navigate(`/jobs/${hid(result.data.job.id)}`);
        });
      }
    } catch (err) {
      toast("Error creating job", { type: "error" }, err as Error);
    } finally {
      setSending(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Layout title="Quick Create Job">
      <form className="form" onSubmit={onSubmit}>
        <Divider
          style={{ flex: 1 }}
          contentPosition={isMobile() ? "center" : "left"}
        >
          Account
        </Divider>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "10px",
          }}
        >
          <div style={{ flex: 1 }}>
            <div className={errors.name ? "has-errors" : ""}>
              <Select2
                async
                asyncSearchType="account"
                creatable
                onChange={(option: any) => {
                  setAccount(option as IAccount);
                }}
                onCreate={(value: string) => {
                  setAccount({
                    ...RequiredAccountFields,
                    name: value,
                    phone: account.phone,
                  });
                }}
                value={{ label: account.name, value: 0 } as Option}
                label="Account Name"
              />
            </div>
            {errors.name && (
              <span className={`error-helper`}>Name is required</span>
            )}
          </div>
          <div style={{ flex: 1 }}>
            <div className={errors.phone ? "has-errors" : ""}>
              <Select2
                async
                asyncSearchType="account"
                creatable
                onChange={(option: any) => {
                  setAccount(option as IAccount);
                }}
                onCreate={(value: string) => {
                  setAccount({
                    ...RequiredAccountFields,
                    phone: value,
                    name: account.name,
                  });
                }}
                transformInput={(value: string) => {
                  return PhoneMask(value);
                }}
                value={{ label: account.phone, value: 0 } as Option}
                label="Account Phone"
              />
            </div>
            {errors.phone && (
              <span className={`error-helper`}>
                Phone is required, has to be 12 digits.
              </span>
            )}
          </div>
        </div>
        <Divider
          style={{ flex: 1 }}
          contentPosition={isMobile() ? "center" : "left"}
        >
          Unit
        </Divider>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "10px",
          }}
        >
          <div style={{ flex: 1 }}>
            <Input
              placeholder="Year"
              value={unit.year}
              name="year"
              onChange={(e) => {
                setUnit({ ...unit, year: YearMask(e) });
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <div className={errors.make ? "has-errors" : ""}>
              <AutoComplete
                placeholder="Make"
                options={Makes}
                value={unit.make}
                onChange={(e: string) => {
                  setUnit({ ...unit, make: e.toUpperCase(), model: "" });
                  setModels(getModelsFromBrand(e));
                }}
                onSelect={(e) => {
                  if (e) {
                    setUnit({ ...unit, make: e.toUpperCase(), model: "" });
                    setModels(getModelsFromBrand(e));
                  }
                }}
                hint="Make"
              />
              {errors.make && (
                <span className={`error-helper`}>Make is required</span>
              )}
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <AutoComplete
              value={unit.model}
              placeholder="Model"
              options={models}
              onChange={(e) => {
                setUnit({ ...unit, model: e.toUpperCase() });
              }}
              onSelect={(e) => {
                setUnit({ ...unit, model: e.toUpperCase() });
              }}
            />
          </div>
        </div>
        {collapsedUnit && (
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <Select2
              async
              asyncSearchType="unit"
              creatable
              onChange={(option: any) => {
                setUnit(option as IUnit);
              }}
              onCreate={(value: string) => {
                setUnit({ ...RequiredUnitFields, license: value });
              }}
              value={
                unit.license
                  ? ({ label: unit.license, value: 0 } as Option)
                  : ({ label: "License Plate", value: 0 } as Option)
              }
              label="License Plate"
              transformInput={(value) => PlateMask(value.toUpperCase())}
              style={{ flex: 1 }}
            />

            <Select2
              async
              asyncSearchType="unit"
              creatable
              onChange={(option: any) => {
                setUnit(option as IUnit);
              }}
              onCreate={(value: string) => {
                setUnit({ ...RequiredUnitFields, vin: value });
              }}
              value={
                unit.vin
                  ? ({ label: unit.vin, value: 0 } as Option)
                  : ({ label: "VIN", value: 0 } as Option)
              }
              label="VIN"
              transformInput={(value) => VinMask(value.toUpperCase())}
              style={{ flex: 1 }}
            />

            <Input
              placeholder="Color"
              name={"color"}
              style={{ flex: 1 }}
              value={unit.color}
              onChange={(e) => {
                setUnit({ ...unit, color: ColorMask(e.toUpperCase()) });
              }}
            />
          </div>
        )}
        <Button
          onClick={() => {
            setCollapsedUnit(!collapsedUnit);
          }}
          style={{ width: "100%" }}
          color="primary"
          fill="none"
        >
          {collapsedUnit ? (
            <>
              {" "}
              <UpOutlined /> Show Less <UpOutlined />{" "}
            </>
          ) : (
            <>
              {" "}
              <DownOutlined /> Show More <DownOutlined />
            </>
          )}
        </Button>
        <Divider
          style={{ flex: 1 }}
          contentPosition={isMobile() ? "center" : "left"}
        >
          Job
        </Divider>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "10px",
          }}
        >
          <AutoComplete
            options={SharedLotAddresses}
            placeholder={"From Address"}
            value={job.from_address}
            onSelect={(value) => {
              setJob({ ...job, from_address: value });
            }}
            onChange={(value) => {
              setJob({ ...job, from_address: value });
            }}
            style={{ flex: 1 }}
          />
          <AutoComplete
            options={SharedLotAddresses}
            placeholder={"Destination Address"}
            value={job.destination_address}
            onSelect={(value) => {
              setJob({ ...job, destination_address: value });
            }}
            onChange={(value) => {
              setJob({ ...job, destination_address: value });
            }}
            style={{ flex: 1 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isMobile() ? "column" : "row",
            gap: "10px",
          }}
        >
          <Select2
            onChange={(value: any) => {
              setJob({ ...job, status: Number(value) });
              setReason(Number(value));
            }}
            label={"Service"}
            options={getJobTypeOptionsWithIcons()}
            value={reason}
            style={{ flex: 1 }}
          />
          <Select2
            options={getStatusTypesWithChips()}
            label="Status"
            style={{ flex: 1 }}
            value={job.status}
            onChange={(value: any) => {
              setJob({ ...job, status: Number(value) });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: isMobile() ? "column" : "row",
          }}
        >
          {hasPerms() && (
            <div style={{ flex: 1 }}>
              <div className={`${errors.user && "has-errors"}`}>
                <Select2
                  options={drivers.map((driver: IUser) => {
                    return { label: driver.name, value: driver.id };
                  })}
                  onChange={(option: any) => {
                    const selectedDriver = drivers.find(
                      (driver: IUser) => driver.id === option,
                    );
                    setUser(selectedDriver as IUser);
                  }}
                  value={user.id}
                  label="Assigned To"
                  style={{ flex: 1 }}
                />
              </div>
              {errors.user && (
                <span className={`error-helper`}>User is required</span>
              )}
            </div>
          )}

          <Input
            placeholder="Quoted"
            name="quoted"
            type="number"
            style={{ flex: 1 }}
            value={String(job.quoted)}
            onChange={(e) => {
              setJob({ ...job, quoted: formatCurrency(e) });
            }}
          />
        </div>
        {jobCollapsed && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              marginTop: "15px",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: "flex",

                  gap: "10px",
                }}
              >
                <div style={{ flex: 1 }}>
                  <Input
                    value={moment(job.scheduled_at).format("YYYY-MM-DDTHH:mm")}
                    name="scheduled_at"
                    placeholder={"Schedule At"}
                    type="datetime-local"
                    onChange={(e) => {
                      setJob({ ...job, scheduled_at: e });
                    }}
                  />
                </div>
                <div style={{ flex: 0 }}>
                  <Button
                    onClick={() => {
                      const today = moment().format("YYYY-MM-DDTHH:mm");
                      setJob((prevState) => {
                        return {
                          ...prevState,
                          scheduled_at: today,
                        };
                      });
                    }}
                    size="large"
                  >
                    Today
                  </Button>
                </div>
                <div style={{ flex: 1 }}>
                  <Select2
                    options={Categories.map((item, index) => {
                      return { label: item, value: index };
                    })}
                    label={"In"}
                    value={job.category}
                    onChange={(value: any) => {
                      setJob({ ...job, category: Number(value) });
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <TinyMarkdown
                label="Notes"
                value={job.public_notes}
                onChange={(e) => {
                  setJob({ ...job, public_notes: e });
                }}
              />
            </div>
          </div>
        )}

        <Button
          onClick={() => {
            setJobCollapsed(!jobCollapsed);
          }}
          style={{ width: "100%" }}
          color="primary"
          fill="none"
        >
          {jobCollapsed ? (
            <>
              {" "}
              <UpOutlined /> Show Less <UpOutlined />{" "}
            </>
          ) : (
            <>
              {" "}
              <DownOutlined /> Show More <DownOutlined />
            </>
          )}
        </Button>
        <Divider
          style={{ flex: 1 }}
          contentPosition={isMobile() ? "center" : "left"}
        >
          Payment
        </Divider>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, lineHeight: "40px" }}>Paid ?</div>
          <div style={{ flex: 1 }}>
            <Switch
              onChange={(paid) => {
                setPayment({ ...payment, is_paid: paid });
              }}
            />
          </div>
        </div>
        {payment.is_paid && (
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
              gap: "10px",
            }}
          >
            <h2 style={{ flex: 1 }}>{JobType[reason]}</h2>
            <div style={{ flex: 1 }}>
              <Input
                placeholder="Total"
                name="total"
                type="number"
                onChange={(e) => {
                  setPayment({ ...payment, total: formatCurrency(e) });
                }}
                disabled={payment.payment_type === 2}
                value={payment.total}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Select2
                label={"Paid via"}
                options={PaymentMethod.map((item, value) => {
                  return { label: item, value: value };
                })}
                value={payment.payment_type}
                onChange={(value: any) => {
                  setPayment({ ...payment, payment_type: Number(value) });
                }}
              />
              {payment.payment_type === 2 && (
                <div
                  style={{ marginBottom: "10px" }}
                  className={errors.clover ? "has-errors" : ""}
                >
                  <Input
                    value={payment.clover_order_id}
                    name="clover_order_id"
                    placeholder="Clover Order ID"
                    onChange={(e) => {
                      setPayment({ ...payment, clover_order_id: e });
                    }}
                  />
                  {errors.clover && (
                    <span className="error-helper">
                      If credit card is selected, order ID is required.
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            disabled={isError()}
            block
            type="submit"
            color="primary"
            loading={sending}
          >
            Submit {sending ? "Loading" : ""}
          </Button>
          <Button block type="button" onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
        </div>
      </form>
      {loading && <OverlayLoading />}
    </Layout>
  );
};

export default JobQuickCreate;
