import { Button, Dialog, Input } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { CloseOutlined, FilePdfOutlined } from "@ant-design/icons";
import API from "../API";
import { toast } from "../SharedUtils";
import { isDisabled } from "@testing-library/user-event/dist/utils";

export interface DragUpload {
  job_id: number;
  onUpload: (files: DragFile[]) => void;
}

export interface DragFile {
  file: File;
  title: string;
  progress?: number;
  index: number;
}

export interface FileItemProps extends DragFile {
  onRemove: (file: File) => void;
  onTitleChange: (title: string) => void;
  blobUrl?: string;
}

interface BlobUrls {
  [key: string]: string; // This means the object can have any number of string properties
}
interface ProgressMap {
  [key: string]: number; // This means the object can have any number of string properties with number values
}

const FileItem: React.FC<FileItemProps> = (props: FileItemProps) => {
  const { file, progress, title, index, onRemove, onTitleChange, blobUrl } =
    props;
  let progressNumber = progress || 0;

  if (progressNumber >= 99) progressNumber = Math.random() * (99 - 85) + 85; // Prevent 100% progress from showing

  const out = file.type.startsWith("image") ? (
    <img src={blobUrl} alt={file.name} />
  ) : (
    <FilePdfOutlined style={{ fontSize: "150px", color: "var(--primary)" }} />
  );
  return (
    <div key={index} className={`item`}>
      <div
        onClick={() => {
          onRemove(file);
        }}
        className="removable-preview"
      >
        {out}
        <div className="remove-button">
          <CloseOutlined />
        </div>
      </div>
      <Input
        placeholder="Title"
        value={title}
        style={{ height: "40px" }}
        onChange={onTitleChange}
      />
      <div className={"progress-wrapper"}>
        <div
          className="progress"
          style={{
            width: progressNumber + "%",
            borderColor: progressNumber > 0 ? "gray" : "rgba(0,0,0,0)",
            background:
              progressNumber > 0
                ? "var(--adm-color-background)"
                : "transparent",
          }}
        ></div>
        {progressNumber > 0 && (
          <div className="progress-text">{progressNumber.toFixed(0)}%</div>
        )}
      </div>
    </div>
  );
};

const DragUpload: React.FC<DragUpload> = (props: DragUpload) => {
  const [dragActive, setDragActive] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [visible, setVisible] = useState<boolean>(true);
  const [files, setFiles] = useState<FileList | null>(null);
  const [displayFiles, setDisplayFiles] = useState<DragFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [blobUrls, setBlobUrls] = useState<BlobUrls>({});
  const [progress, setProgress] = useState<ProgressMap>({}); // Object to track progress of each file

  const updateProgress = (fileName: string, fileProgress: number) => {
    setProgress((currentProgress) => ({
      ...currentProgress,
      [fileName]: fileProgress,
    }));
  };

  const api = API.getInstance();

  useEffect(() => {
    if (files) {
      let newFiles: DragFile[] = [];
      for (let i = 0; i < files.length; i++) {
        newFiles.push({ file: files[i], title: files[i].name, index: i });
      }
      setDisplayFiles(newFiles);
    }
  }, [files]);

  useEffect(() => {
    if (files) {
      let newFiles = [];
      let newBlobUrls: BlobUrls = {};
      let initialProgress: ProgressMap = {};
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        newFiles.push({ file, title: file.name, index: i });
        newBlobUrls[file.name] = URL.createObjectURL(file);
        initialProgress[file.name] = 0; // Initialize progress as 0
      }
      setDisplayFiles(newFiles);
      setBlobUrls(newBlobUrls);
      setProgress(initialProgress);
    }
  }, [files]);

  useEffect(() => {
    return () => {
      // Clean up blob URLs
      Object.values(blobUrls).forEach((url) => URL.revokeObjectURL(url));
    };
  }, [blobUrls]);

  const updateTitle = (index: number, newTitle: string) => {
    setDisplayFiles((currentFiles) =>
      currentFiles.map((file, idx) =>
        idx === index ? { ...file, title: newTitle } : file,
      ),
    );
  };

  const blobToFile = (blob: Blob, fileName: string) => {
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });
  };

  const resizeImage = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const worker = new Worker(
        new URL("./imageResizerWorker.js", import.meta.url),
        { type: "module" },
      );

      worker.postMessage([file]);

      worker.onmessage = (e: MessageEvent<Blob>) => {
        resolve(e.data);
        worker.terminate();
      };

      worker.onerror = (error) => {
        console.error("Worker error:", error);
        reject(error);
        worker.terminate();
      };
    });
  };

  const uploadFile = async (
    file: File,
    title: string,
    jobId: number,
    onProgress: (progress: number) => void,
  ) => {
    return api.uploadAttachment(file, title, jobId, onProgress);
  };

  const isImageFile = (file: File) => {
    return file.type.startsWith("image/");
  };

  const uploadFiles = async () => {
    setLoading(true);

    const uploadPromises = displayFiles.map(async (file) => {
      try {
        let fileToUpload;

        if (isImageFile(file.file)) {
          const resizedBlob = await resizeImage(file.file);
          fileToUpload = blobToFile(resizedBlob, file.file.name);
        } else {
          fileToUpload = file.file;
        }

        await uploadFile(fileToUpload, file.title, props.job_id, (progress) => {
          updateProgress(file.file.name, progress);
        });
      } catch (e) {
        toast("Error uploading file: " + file.file.name, { type: "error" });
        // Optionally handle individual file upload error here
      }
    });

    try {
      await Promise.all(uploadPromises);

      props.onUpload(displayFiles);
      setLoading(false);
      setVisible(true);
      setFiles(null);
    } catch (e) {
      toast("Error with file upload process", { type: "error" });
    } finally {
      setLoading(false);
    }
  };

  /*
  const uploadFiles = async () => {
    setLoading(true);
    const api = API.getInstance();
    let formData = new FormData();

    if (displayFiles) {
      for (let i = 0; i < displayFiles.length; i++) {
        formData.append(
          "files",
          (await resizeImage(displayFiles[i].file, 2000)) as File,
        );

        console.log(formData);

        formData.append("titles", displayFiles[i].title);
      }
    }
    api
      .uploadAttachments(formData, props.job_id)
      .then((res) => {
        props.onUpload(displayFiles);
        setLoading(false);
        setVisible(true);
        setFiles(null);
      })
      .catch((e) => {
        toast("Error uploading files", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };*/

  const removeAttachment = (file: File) => {
    if (displayFiles) {
      let newFiles = displayFiles.filter((f) => f.file.name !== file.name);
      setDisplayFiles(newFiles);
      if (newFiles.length === 0) {
        setVisible(true);
        setFiles(null);
        if (inputRef.current) {
          inputRef.current.value = ""; // reset
        }
      }
    }
  };

  return (
    <div>
      <label
        style={{ display: visible ? "flex" : "none" }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDragActive("drag-active");
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDragActive("");
          if (inputRef.current) {
            inputRef.current.files = e.dataTransfer.files;
            setFiles(e.dataTransfer.files);
            setVisible(false);
          }
          setFiles(e.dataTransfer.files);
        }}
        onDragEnd={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDragActive("");
        }}
        htmlFor="files"
        className={`drop-container ${dragActive}`}
        ref={labelRef}
        id="dropcontainer"
      >
        <span className="drop-title">Drop files here</span> or
        <input
          type="file"
          id="files"
          name="files"
          multiple
          accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ref={inputRef}
          onChange={(e) => {
            e.target.files && setFiles(e.target.files);
            setVisible(false);
          }}
        />
      </label>

      <div
        style={{ display: visible ? "none" : "block" }}
        className="draggable-upload file-list"
      >
        {displayFiles && (
          <div>
            <div className="file-list">
              {displayFiles.map((draggedFile: DragFile, index: number) => {
                return (
                  <FileItem
                    file={draggedFile.file}
                    title={draggedFile.title}
                    key={index}
                    index={index}
                    progress={progress[draggedFile.file.name]}
                    onRemove={removeAttachment}
                    onTitleChange={(title) => updateTitle(index, title)}
                    blobUrl={blobUrls[draggedFile.file.name]}
                  />
                );
              })}
            </div>
            <div style={{ display: "flex", gap: "15px", margin: "10px" }}>
              <Button
                loading={loading}
                style={{ textAlign: "center" }}
                block
                color="primary"
                onClick={uploadFiles}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setVisible(true);
                  setFiles(null);
                }}
                style={{ textAlign: "center" }}
                block
                color="default"
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DragUpload;
