import axios, { AxiosError } from "axios";
import {
  Account,
  Flags,
  FleetUnit,
  Impound,
  Job,
  JobPatch,
  Message,
  PaymentPatch,
  Unit,
  UnitPatch,
  User,
} from "./Interfaces";

import { fetchStored, setStored } from "./AsyncStorage";
import { isNada } from "./SharedUtils";

export const API_DEFAULT_PER_PAGE = 25;
export const API_BASE = "https://api.towingtrack.com";
export const API_VERSION = "v1";
export const API_BASE_URL = `${API_BASE}/${API_VERSION}`;
export const APP_BASE_URL = "https://app.towingtrack.com";

export interface ApiError extends AxiosError {}

export default class API {
  private static instance: API;
  private static abortController: AbortController;

  private constructor() {}

  public static getInstance(): API {
    if (!API.instance) {
      API.instance = new API();
    }
    return API.instance;
  }

  public static setAbortController(abortController: AbortController) {
    API.abortController = abortController;
  }

  public static getAbortController(): AbortController {
    return API.abortController;
  }

  async getToken() {
    return await fetchStored("session").then((data) => {
      const { token } = data;
      if (token) {
        return token;
      } else {
        throw new Error("No token found");
      }
    });
  }

  async getHeaderConfigDefault() {
    return {
      headers: {
        Authorization: `Bearer ${await this.getToken()}`,
      },
    };
  }

  async fetchAccounts(skip = 0, limit = API_DEFAULT_PER_PAGE) {
    if (limit === 0) {
      limit = 25;
    }
    return await axios.get(
      API_BASE_URL + "/accounts/?skip=" + skip + "&limit=" + limit,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchAccount(id: number) {
    return axios.get(
      API_BASE_URL + "/accounts/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async createAccount(account: Account) {
    console.log("[API] createAccount", account);
    return axios.post(
      API_BASE_URL + "/accounts/",
      account,
      await this.getHeaderConfigDefault(),
    );
  }

  async updateAccount(account: Account) {
    return axios.patch(
      API_BASE_URL + "/accounts/" + account.id + "/",
      account,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchImpounds(skip = 0, limit = API_DEFAULT_PER_PAGE) {
    if (limit === 0) {
      limit = 25;
    }
    return axios.get(
      API_BASE_URL + "/impounds/?skip=" + skip + "&limit=" + limit,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchImpound(id: number | any) {
    return axios.get(
      API_BASE_URL + "/impounds/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async createImpound(impound: Impound) {
    return axios.post(
      API_BASE_URL + "/impounds/",
      impound,
      await this.getHeaderConfigDefault(),
    );
  }

  async updateImpound(impound: Impound) {
    return axios.patch(
      API_BASE_URL + "/impounds/" + impound.id + "/",
      impound,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchUnits(skip = 0, limit = API_DEFAULT_PER_PAGE) {
    if (limit === 0) {
      limit = 25;
    }
    return axios.get(
      API_BASE_URL + "/units/?skip=" + skip + "&limit=" + limit,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchUnit(id: number) {
    return axios.get(
      API_BASE_URL + "/units/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async createUnit(unit: Unit) {
    return axios.post(
      API_BASE_URL + "/units/",
      unit,
      await this.getHeaderConfigDefault(),
    );
  }

  async updateUnit(account: Unit) {
    return axios.patch(
      API_BASE_URL + "/units/" + account.id + "/",
      account,
      await this.getHeaderConfigDefault(),
    );
  }

  async patchUnit(id: number | string, data: UnitPatch) {
    return axios.patch(
      API_BASE_URL + "/units/" + id + "/",
      data,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchJobs(args: any = {}) {
    return axios.get(API_BASE_URL + "/jobs/", {
      params: args,
      ...(await this.getHeaderConfigDefault()),
    });
  }

  async fetchMyJobs(args: any = {}) {
    return axios.get(API_BASE_URL + "/jobs/my/", {
      params: args,
      ...(await this.getHeaderConfigDefault()),
    });
  }

  async fetchPublicJobs(args: any = {}) {
    return axios.get(API_BASE_URL + "/jobs/public/", {
      params: args,
      ...(await this.getHeaderConfigDefault()),
    });
  }

  async fetchScheduledJobs(args: any = {}) {
    return axios.get(API_BASE_URL + "/jobs/scheduled/", {
      params: args,
      ...(await this.getHeaderConfigDefault()),
    });
  }

  async fetchUrgentJobs(args: any = {}) {
    return axios.get(API_BASE_URL + "/jobs/urgent/", {
      params: args,
      ...(await this.getHeaderConfigDefault()),
    });
  }

  async fetchJob(id: any) {
    return axios.get(
      API_BASE_URL + "/jobs/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchConstrainedJobs(
    constraints: {},
    skip = 0,
    limit = API_DEFAULT_PER_PAGE,
  ) {
    const params = {
      ...(await this.getHeaderConfigDefault()),
      params: {
        ...constraints,
        skip: skip,
        limit: limit,
      },
    };

    if ("account_id" in params.params) {
      return axios.get(
        API_BASE_URL + "/accounts/" + params.params.account_id + "/jobs/",
        await this.getHeaderConfigDefault(),
      );
    } else if ("unit_id" in params.params) {
      return axios.get(
        API_BASE_URL + "/units/" + params.params.unit_id + "/jobs/",
        await this.getHeaderConfigDefault(),
      );
    } else {
      return axios.get(API_BASE_URL + "/jobs/", params);
    }
  }

  async createJob(job: Job) {
    return axios.post(
      API_BASE_URL + "/jobs/",
      job,
      await this.getHeaderConfigDefault(),
    );
  }

  async patchJob(id: number | string, data: JobPatch) {
    return axios.patch(
      API_BASE_URL + "/jobs/" + id + "/",
      data,
      await this.getHeaderConfigDefault(),
    );
  }

  async patchFlags(job_id: number, data: Flags) {
    return axios.patch(
      API_BASE_URL + "/jobs/" + job_id + "/flags/",
      data,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchJobAttachments(id: number) {
    return axios.get(
      API_BASE_URL + "/jobs/" + id + "/attachments/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchFleetUnit(id: any) {
    return axios.get(
      API_BASE_URL + "/fleetunits/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchFleetUnits(skip = 0, limit = API_DEFAULT_PER_PAGE) {
    if (limit === 0) {
      limit = 25;
    }
    return axios.get(
      API_BASE_URL + "/fleetunits/?skip=" + skip + "&limit=" + limit,
      await this.getHeaderConfigDefault(),
    );
  }

  async updateFleetUnit(fleetUnit: FleetUnit) {
    return axios.patch(
      API_BASE_URL + "/fleetunits/" + fleetUnit.id + "/",
      fleetUnit,
      await this.getHeaderConfigDefault(),
    );
  }

  async createFleetUnit(fleetUnit: FleetUnit) {
    return axios.post(
      API_BASE_URL + "/fleetunits/",
      fleetUnit,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchPaymentInfo(id?: any) {
    return axios.get(
      API_BASE_URL + "/payments/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async updatePaymentInfo(id: number | undefined, payment: any) {
    return axios.patch(
      API_BASE_URL + "/payments/" + id + "/",
      payment,
      await this.getHeaderConfigDefault(),
    );
  }

  async patchPaymentInfo(id: number, payment: PaymentPatch) {
    return axios.patch(
      API_BASE_URL + "/payments/" + id + "/",
      payment,
      await this.getHeaderConfigDefault(),
    );
  }
  async createPayment(payment: any) {
    return axios.post(
      API_BASE_URL + "/payments/",
      payment,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchCloverStatus(order_id: string) {
    return axios.get(
      API_BASE_URL + "/payments/status/" + order_id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchOptions(name: string) {
    return axios.get(
      API_BASE_URL + "/options/" + name,
      await this.getHeaderConfigDefault(),
    );
  }

  async updateOptions(name: string, obj: any) {
    return axios.patch(
      API_BASE_URL + "/options/" + name,
      obj,
      await this.getHeaderConfigDefault(),
    );
  }

  async createOptions(name: string, obj: any) {
    return axios.post(
      API_BASE_URL + "/options/" + name,
      { name: name, value: obj },
      await this.getHeaderConfigDefault(),
    );
  }

  async createUser(user: User) {
    interface InterimUser extends User {
      password: string;
    }

    let _user = user as InterimUser;
    _user.password = "<encrypted>";

    return axios.post(
      API_BASE_URL + "/users/",
      user,
      await this.getHeaderConfigDefault(),
    );
  }

  async signupUser(user: {
    email: string;
    name: string;
    phone: string;
    role: number;
    is_active: boolean;
    password: string;
  }) {
    console.log(user);
    return axios.post(API_BASE + "/auth/signup/", user);
  }

  login(email: string, password: string, fcm_token: string | null = null) {
    console.log("axios post " + API_BASE + "/login/");
    return axios.post(API_BASE + "/login/", { email, password, fcm_token });
  }

  async setUserToken(user_id: number, fcm_token: string) {
    if (user_id === 0) return null;
    if (isNada(fcm_token)) return null;

    return axios.patch(
      API_BASE_URL + "/users/" + user_id + "/",
      { fcm_token: fcm_token },
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchUser(id: number) {
    return axios.get(
      API_BASE_URL + "/users/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchUsers(skip = 0, limit = API_DEFAULT_PER_PAGE) {
    if (limit === 0) {
      limit = 25;
    }
    return axios.get(
      API_BASE_URL + "/users/?skip=" + skip + "&limit=" + limit,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchDrivers(skip = 0, limit = API_DEFAULT_PER_PAGE) {
    if (limit === 0) {
      limit = 25;
    }
    return axios.get(
      API_BASE_URL + "/drivers/?skip=" + skip + "&limit=" + limit,
      await this.getHeaderConfigDefault(),
    );
  }

  async updateUser(user: User) {
    // Sanity check
    user = { ...user, password_digest: "<the missile knows where it is>" };
    return axios.patch(
      API_BASE_URL + "/users/" + user.id + "/",
      user,
      await this.getHeaderConfigDefault(),
    );
  }

  async forgotPassword(email: string) {
    return axios.post(API_BASE + "/auth/reset-password/", { email: email });
  }

  async changePassword(currentPassword: string, newPassword: string) {
    return axios.post(
      API_BASE_URL + "/users/change-password/",
      {
        current_password: currentPassword,
        new_password: newPassword,
      },
      await this.getHeaderConfigDefault(),
    );
  }

  async deleteAccount() {
    return axios.delete(
      API_BASE_URL + "/users/me/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchMessages() {
    return axios.get(
      API_BASE_URL + "/messages/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchMessage(id: number) {
    return axios.get(
      API_BASE_URL + "/message/" + id + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async updateMessage(message: Message) {
    return axios.patch(
      API_BASE_URL + "/messages/" + message.id + "/",
      message,
      await this.getHeaderConfigDefault(),
    );
  }

  async patchMessage(id: number, message: Partial<Message>) {
    return axios.patch(
      API_BASE_URL + "/messages/" + id + "/",
      message,
      await this.getHeaderConfigDefault(),
    );
  }

  async createMessage(message: Partial<Message>) {
    return axios.post(
      API_BASE_URL + "/messages/",
      message,
      await this.getHeaderConfigDefault(),
    );
  }

  async search(
    entity: string,
    value: string,
    limit = API_DEFAULT_PER_PAGE,
    skip = 0,
  ) {
    if (limit === 0) {
      limit = 25;
    }
    API.setAbortController(new AbortController());

    let searchEntity = entity.toLowerCase() + "s";
    console.log(
      `GET ${API_BASE_URL}/search/${searchEntity}/?query=${value}&skip=${skip}&limit=${limit}`,
    );
    return axios.get(
      `${API_BASE_URL}/search/${searchEntity}/?query=${value}&skip=${skip}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
        signal: API.getAbortController().signal,
      },
    );
  }

  async createJobResource(resource: any) {
    // Sanity checks
    if (resource.job.assigned_to_name) delete resource.job.assigned_to_name;
    if (resource.job.released_to_name) delete resource.job.released_to_name;
    if (resource.job.requested_by_name) delete resource.job.requested_by_name;
    return axios.post(
      API_BASE_URL + "/jobs/resource/",
      resource,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchDocumentTemplate(template: string, job_id: number = 0) {
    return axios.get(
      `${API_BASE_URL}/generate/documents/pdf/${template}/${job_id}/`,
      await this.getHeaderConfigDefault(),
    );
  }

  async sendReceipt(job_id: number, email: string, phone: string) {
    return axios.post(
      API_BASE_URL + "/receipts/send/",
      {
        job_id: job_id,
        email: email,
        phone: phone,
      },
      await this.getHeaderConfigDefault(),
    );
  }

  async sendTextToDriver(job_id: number) {
    return axios.post(
      `${API_BASE_URL}/jobs/${job_id}/notification/sms/`,
      {},
      await this.getHeaderConfigDefault(),
    );
  }

  async decodeVIN(vin: string) {
    return axios.get(
      API_BASE_URL + "/decode-vin/?vin=" + vin + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async duplicateJob(id: number) {
    return axios.post(
      API_BASE_URL + `/jobs/${id}/duplicate/`,
      { id: id },
      await this.getHeaderConfigDefault(),
    );
  }

  async uploadAttachment(
    file: File,
    title: string,
    jobId: number,
    onProgress: (progress: number) => void,
    entity_type = "job",
  ) {
    let token = await this.getToken();
    const formData = new FormData();
    formData.append("files", file);
    formData.append("titles", title);

    const url = `${API_BASE_URL}/attachments/?job_id=${jobId}&entity_type=${entity_type}`;

    return axios.post(url, formData, {
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total || 1;
        const progress = (progressEvent.loaded / total) * 100;
        onProgress(progress);
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async uploadAttachments(
    formData: FormData,
    job_id: number,
    entity_type: string = "job",
  ) {
    let token = await this.getToken();
    return axios({
      method: "post",
      url:
        API_BASE_URL +
        `/attachments/?job_id=${job_id}&entity_type=${entity_type}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async requestLocationUpdate(
    phone: string,
    direction: "D" | "P",
    job_id: number,
  ) {
    return axios.post(
      API_BASE + "/location/request/",
      {
        phone: phone,
        direction: direction,
        job_id: job_id,
      },
      await this.getHeaderConfigDefault(),
    );
  }

  async generatePdf(id: number, title: string, contents: string) {
    return axios.post(
      API_BASE_URL + "/generate/attachments/pdf/",
      {
        id: id,
        title: title,
        contents: contents,
      },
      await this.getHeaderConfigDefault(),
    );
  }

  async getShiftReport(
    date_from: string,
    date_to: string,
    shift_start: string,
    shift_end: string,
    user_id: number,
  ) {
    if (user_id === 0) return null;
    return axios.get(
      API_BASE_URL +
        "/generate/reports/shift/?date_from=" +
        date_from +
        "&date_to=" +
        date_to +
        "&shift_start=" +
        shift_start +
        "&shift_end=" +
        shift_end +
        "&user_id=" +
        user_id,
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchMyStats(timeframe: string) {
    return axios.get(
      API_BASE_URL + "/generate/stats/my/" + timeframe + "/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchStats() {
    return axios.get(
      API_BASE_URL + "/generate/stats/",
      await this.getHeaderConfigDefault(),
    );
  }

  async fetchOutstandingJobs() {
    return axios.get(
      API_BASE_URL + "/jobs/outstanding/",
      await this.getHeaderConfigDefault(),
    );
  }

  async getApiCallByName(name: keyof API, ...args: any[]): Promise<any> {
    return (this as any)[name].apply(this, args);
  }
}
