import { ChargeGroup } from "./Interfaces";

export const FallbackPriceGroups = [
  {
    name: "APD Daily",
    charges: [
      {
        name: "Towing Charge",
        amount: "95.00",
      },
      {
        name: "Mileage Charge",
        amount: "56.00",
      },
      {
        name: "Gate Fee",
        amount: "25.00",
      },
      {
        name: "Fuel Charge",
        amount: "15.00",
      },
      {
        name: "Labor",
        amount: "120.00",
      },
      {
        name: "Special Equipment",
        amount: "25.00",
      },
      {
        name: "Storage",
        amount: "15.00",
      },
    ],
  },
  {
    name: "APD Nightly",
    charges: [
      {
        name: "Towing Charge",
        amount: "105.00",
      },
      {
        name: "Mileage Charge",
        amount: "56.00",
      },
      {
        name: "Gate Fee",
        amount: "25.00",
      },
      {
        name: "Fuel Charge",
        amount: "15.00",
      },
      {
        name: "Labor",
        amount: "160.00",
      },
      {
        name: "Special Equipment",
        amount: "25.00",
      },
      {
        name: "Storage",
        amount: "15.00",
      },
    ],
  },
  {
    name: "PPI",
    charges: [
      {
        name: "Impound Charge",
        amount: "250.00",
      },
      {
        name: "Gate Fee",
        amount: "25.00",
      },
      {
        name: "Storage",
        amount: "30.00",
      },
    ],
  },
] as ChargeGroup[];
