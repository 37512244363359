import jwt_decode, { JwtPayload } from "jwt-decode";
import Hashids from "hashids";
import {
  COMMON_EMAIL_PROVIDERS,
  JobType,
  statusColor,
  statusIcon,
  StatusType,
} from "./SharedTypes";
import CommonVehicles from "./values/common-vehicles.json";
import JobIcon from "../pages/protected/components/jobs/JobIcon";
import { Option } from "./components/Select2";
import Chip from "./components/Chip";
import { marked } from "marked";
import API from "./API";
import { User } from "./Interfaces";
import { CheckSquareOutlined } from "@ant-design/icons";
import { generate } from "lean-qr";
import { toSvgDataURL } from "lean-qr/extras/svg";
import React, { useEffect } from "react";
import { toast as RealToast } from "react-toastify";
import { Capacitor } from "@capacitor/core";

const AddressParser = require("parse-address");

const hashids = new Hashids(
  process.env.REACT_APP_HID_SALT,
  8,
  "ABCDEFGHIJKMNPQRSTUVWXYZ2345678",
);

export const isMobile = () => {
  return Capacitor.isNativePlatform();
};

export const missingo = (value: any, defaultValue: string) => {
  return isNA(value) ? (
    <span className={"missing"}>{defaultValue}</span>
  ) : (
    <span>{value}</span>
  );
};

// @TODO validate JWT
export const VerifyJWT = (token: string) => {
  try {
    let decoded = jwt_decode(token) as JwtPayload;

    if (decoded.exp) {
      return !(decoded.exp <= Math.floor(Date.now() / 1000));
    }
    return false;
  } catch (e) {
    console.log("[JWT]", e);
    return false;
  }
};

export const decodeJWT = (token: string) => {
  try {
    return jwt_decode(token) as JwtPayload;
  } catch (e) {
    console.log("[JWT]", e);
    return null;
  }
};

/**
 * Hash ID
 * Transforms a number into a hash id, pulls from the salt in the .env file
 * @param id
 */
export const hid = (id: string | number) => {
  return hashids.encode(typeof id === "string" ? parseInt(id) : id);
};

/**
 * Hash to integer ID
 * Transforms a hash id into an integer, pulls from the salt in the .env file
 * @param hash
 */
export const idh = (hash: string) => {
  try {
    return hashids.decode(hash)[0];
  } catch (e) {
    return null;
  }
};

export const getRealId = (hash: string | number | undefined) => {
  if (!hash) return 0;
  if (typeof hash === "number") return hash;
  return Number.isNaN(Number(hash)) ? Number(idh(hash)) : Number(hash);
};

export const getMapProviderUrl = (query: string | undefined) => {
  if (!query) return "";
  return process.env.REACT_APP_MAP_PROVIDER_URL?.replace(
    "%s",
    encodeURI(query),
  );
};

export const statusClassName = (status: number) => {
  if (status === 0) return "status-open";
  if (status === 1) return "status-in-progress";
  if (status === 2) return "status-canceled";
  if (status === 3) return "status-closed";
  if (status === 4) return "status-scheduled";
  if (status === 5) return "status-public";
};

export interface AddressShim {
  number: string | number;
  prefix: string;
  street: string;
  type: string;
  city: string;
  state: string;
  zip: string;
}

export const ParseAddress = (address: string) => {
  return AddressParser.parseLocation(address);
};

export const USPSAddressFormat = (rawAddress: string): string => {
  if (!rawAddress) return "";
  let address = ParseAddress(rawAddress) as AddressShim;
  return `${address.number || ""} ${address.prefix || ""} ${
    address.street || ""
  } ${address.type || ""}\n${address.city || ""}, ${address.state || ""} ${
    address.zip || ""
  }`;
};

export const generateAutoCompleteEmailList = (value: string) => {
  return COMMON_EMAIL_PROVIDERS.map((e) => {
    return `${value.replace("@", "")}@${e}`;
  });
};

export const Makes = CommonVehicles.map((item, _) => {
  return item.brand;
});

export const getModelsFromBrand = (brand: string) => {
  if (!brand) {
    return [];
  }
  let _brand = brand.toLowerCase();
  _brand = _brand.charAt(0).toUpperCase() + _brand.slice(1);
  let make = CommonVehicles.filter((item) => item.brand === _brand);
  if (make.length > 0) {
    return make[0].models;
  }
  return [];
};

export const CarMakes = CommonVehicles.map((item, index) => {
  return { value: item.brand, label: item.brand };
});

export const getJobTypeOptionsWithIcons = (): Option[] => {
  return JobType.map((label, index) => {
    return {
      label: (
        <JobIcon
          data-label={label}
          type={index}
          width={20}
          postfix={"  " + label}
        />
      ),
      value: index,
    };
  });
};
export const filterJobTypeOptions = (
  options: Option[],
  searchString: string,
): Option[] => {
  return options;
  /*return options.filter((option) => {
        return option.label ?  option.label.props.postfix
            .toLowerCase()
            .includes(searchString.toLowerCase());
    });*/
};

export const getStatusTypesWithChips = (includeAny?: boolean): Option[] => {
  let statusTypes = StatusType.map((label, index) => {
    return {
      label: (
        <Chip data-label={label} size="medium" type={statusColor[index]}>
          {statusIcon[index]} {label}
        </Chip>
      ),
      value: index,
    };
  });
  if (includeAny) {
    statusTypes = [
      {
        label: (
          <Chip data-label="any" size="medium" type="info">
            <CheckSquareOutlined /> Any
          </Chip>
        ),
        value: -1,
      },
      ...statusTypes,
    ];
  }
  return statusTypes;
};
export const filterOptionsByStatusTypes = (
  options: Option[],
  searchString: string,
  hasAny?: boolean,
): Option[] => {
  let statusTypes = StatusType;
  if (hasAny) {
    statusTypes = ["Any", ...StatusType];
  }

  const filteredIndexes: number[] = statusTypes
    .map((l, index) =>
      l.toLowerCase().includes(searchString.toLowerCase()) ? index : -1,
    )
    .filter((index) => index !== -1);

  return options.filter((_, index) => filteredIndexes.includes(index));
};

export const markdown = (text: string) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: marked(text, {
          mangle: false,
          headerIds: false,
          gfm: true,
          breaks: true,
        }),
      }}
    ></div>
  );
};

export const sortJob = (
  a: any,
  b: any,
  on: any,
  sort: "ASC" | "DESC",
): 1 | -1 | 0 => {
  if (sort === "ASC") {
    if ((a[on] || "") > (b[on] || "")) return 1;
    if ((a[on] || "") < (b[on] || "")) return -1;
  } else {
    if ((a[on] || "") < (b[on] || "")) return 1;
    if ((a[on] || "") > (b[on] || "")) return -1;
  }
  return 0; // Return 0 if the values are equal
};

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const getQRCodeURL = (text: string, scale: number = 1.5) => {
  const qrCode = generate(text);
  return toSvgDataURL(qrCode, { scale: scale });
};

export const useClickOutside = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => document.removeEventListener("click", handleClick, true);
  }, []);
};

const toastOptions = {
  theme: "dark",
  autoClose: 6000,
  closeOnClick: true,
  progress: undefined,
};
export const toast = (message: any, options?: any, e?: Error) => {
  const mergedOptions = { ...toastOptions, ...options };
  if (e instanceof Error) {
    mergedOptions.type = "error";
    mergedOptions.autoClose = 5000;
    mergedOptions.closeOnClick = true;
    mergedOptions.progress = undefined;
    message = (
      <p>
        {message}
        <br />
        {e.message}
      </p>
    );
  }
  mergedOptions.position = isMobile() ? "bottom-center" : "top-right";
  return RealToast(message, mergedOptions);
};

export const isNada = (value: any): boolean => {
  if (typeof value === "object") {
    return value === null || Object.keys(value).length === 0;
  }
  return value === null || value === undefined || value === "";
};

export const isNA = (value: any): boolean => {
  // check if value is set and not undefined, null, or empty
  if (typeof value === "object") {
    return value === null || Object.keys(value).length === 0;
  }
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "N" ||
    value === "NA" ||
    value === "N/A"
  );
};

export const getDisplayLabel = (
  type: "account" | "unit" | any,
  data: any,
): string => {
  if (type === "account") {
    return `${data.phone || ""} - ${data.name || ""}`;
  } else if (type === "unit") {
    return `${data.year || ""} ${data.make || ""} ${data.model || ""} ${
      data.license ? " - " + data.license : ""
    }`;
  } else {
    return data.name || "";
  }
};

export const distributeSum = (total: number, percentages: number[]) => {
  let amounts = percentages.map((pct) => (total * pct) / 100);
  let roundedAmounts = amounts.map((amount) => Math.round(amount / 5) * 5);
  let roundedTotal = roundedAmounts.reduce((a, b) => a + b, 0);

  let difference = total - roundedTotal;

  // Distribute the difference. This logic can be modified as needed
  for (let i = 0; difference !== 0 && i < roundedAmounts.length; i++) {
    let adjustment =
      difference > 0 ? Math.min(5, difference) : Math.max(-5, difference);
    roundedAmounts[i] += adjustment;
    difference -= adjustment;
  }

  // Final check to adjust for rounding errors
  let finalTotal = roundedAmounts.reduce((a, b) => a + b, 0);
  if (finalTotal !== total) {
    roundedAmounts[roundedAmounts.length - 1] += total - finalTotal;
  }

  return roundedAmounts;
};

export const formatCurrency = (rawInput: string) => {
  let input = rawInput.replace(/[^\d.-]/g, "");

  const isNegative = input.startsWith("-");
  const numericInput = input.replace(/[^\d]/g, "");

  // Convert to a number and format as cents
  let numberValue = parseInt(numericInput, 10);
  if (!isNaN(numberValue)) {
    numberValue = ((isNegative ? -1 : 1) * numberValue) / 100;
    return numberValue.toFixed(2);
  } else {
    return "";
  }
};
