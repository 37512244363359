export const Defaults = {
  borderRadius: 10,
  fontSize: 16,
  defaultPadding: 10,
  defaultMargin: 10,
};

export const Colors = {
  primary: "#fb8501",
  primaryHighlight: "#ffce98",
  secondary: "#000000",
  boxHighlight: "#271e1f",
  background: "#191722",
  boxBackgroundColor: "#21212d",
  primaryText: "#ffffff",
  alertErrorBackground: "#271721",
  alertSuccessBackground: "rgba(88, 158, 46, 0.3)",
  alertWarningBackground: "rgba(252, 191, 68, 0.3)",
  alertInfoBackground: "rgba(252, 191, 68, 0.3)",
  alertTextError: "#f32a0e",
  alertTextSuccess: "#74b83f",
  alertTextWarning: "#ffd13e",
  linkColor: "#8faded",
  placeholder: "#d3d3d3",
};

export const Theme = {
  BUTTONLARGE: {
    backgroundColor: Colors.primary,
    color: Colors.primaryText,
    borderRadius: Defaults.borderRadius,
    padding: 10,
    margin: 10,
    textAlign: "center",
  },
  BUTTON: {
    backgroundColor: Colors.primary,
    color: Colors.primaryText,
    borderRadius: Defaults.borderRadius,
    padding: 5,
    margin: 5,
    textAlign: "center",
  },
  BUTTONSMALL: {
    backgroundColor: Colors.primary,
    color: Colors.primaryText,
    borderRadius: Defaults.borderRadius,
    padding: 2,
    margin: 2,
    textAlign: "center",
  },
  BUTTONOUTLINE: {
    backgroundColor: "transparent",
    color: Colors.primary,
    borderRadius: Defaults.borderRadius,
    padding: 5,
    margin: 5,
    textAlign: "center",
    borderColor: Colors.primary,
  },
  BUTTONTEXTLARGE: {
    color: Colors.primaryText,
    textAlign: "center",
    fontSize: Defaults.fontSize + 5,
  },
  BUTTONTEXT: {
    color: Colors.primaryText,
    textAlign: "center",
    fontSize: Defaults.fontSize + 1,
  },
  BUTTONTEXTSMALL: {
    color: Colors.primaryText,
    textAlign: "center",
    fontSize: Defaults.fontSize - 2,
  },
  INPUT: {
    height: 50,
    backgroundColor: Colors.boxBackgroundColor,
    borderColor: Colors.boxBackgroundColor,
    borderWidth: 1,
    padding: 15,
    fontSize: Defaults.fontSize,
    margin: 10,
    borderRadius: Defaults.borderRadius,
    color: Colors.primaryText,
  },
  INPUTALT: {
    height: 50,
    backgroundColor: Colors.boxBackgroundColor,
    borderColor: Colors.primaryHighlight,
    borderWidth: 1,
    padding: 15,
    fontSize: Defaults.fontSize,
    margin: 10,
    borderRadius: Defaults.borderRadius,
    color: Colors.primaryText,
  },
  BOX: {
    borderColor: Colors.primary,
    borderWidth: 1,
    padding: Defaults.defaultPadding,
    margin: Defaults.defaultMargin,
    borderRadius: Defaults.borderRadius,
    backgroundColor: Colors.boxBackgroundColor,
  },
  PLACEHOLDER: {
    color: Colors.placeholder,
  },
  TEXT: {
    color: Colors.primaryText,
    fontSize: Defaults.fontSize,
  },
  TEXT_ERROR: {
    color: Colors.alertTextError,
    fontSize: Defaults.fontSize,
  },
  TEXT_SUCCESS: {
    color: Colors.alertTextSuccess,
    fontSize: Defaults.fontSize,
  },
  TEXT_WARNING: {
    color: Colors.alertTextWarning,
    fontSize: Defaults.fontSize,
  },
  ALERTBOXCONTAINER: {
    padding: Defaults.defaultPadding,
    margin: Defaults.defaultMargin,
    borderRadius: Defaults.borderRadius,
  },
  ALERTBOXCONTAINER_ERROR: {
    backgroundColor: Colors.alertErrorBackground,
  },
  ALERTBOXCONTAINER_SUCCESS: {
    backgroundColor: Colors.alertSuccessBackground,
  },
  ALERTBOXCONTAINER_WARNING: {
    backgroundColor: Colors.alertWarningBackground,
  },
  TEXTLINKTHEME: {
    color: Colors.linkColor,
    textDecorationLine: "underline",
    textDecorationColor: Colors.linkColor,
    fontSize: Defaults.fontSize,
    lineHeight: 20,
  },
  SWITCH: {},
};
