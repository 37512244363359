import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isMobile } from "../../../../shared/SharedUtils";
import {
  EditOutlined,
  EyeOutlined,
  PrinterOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Account as IAccount,
  Job as IJob,
  Payment as IPayment,
  Unit as IUnit,
} from "../../../../shared/Interfaces";
import Layout from "../../../../shared/components/Layout";
import { OverlayLoading } from "../../../../shared/components/Loading";
import {
  getQRCodeURL,
  getRealId,
  hid,
  toast,
  USPSAddressFormat,
} from "../../../../shared/SharedUtils";
import API, { API_BASE_URL, APP_BASE_URL } from "../../../../shared/API";
import { Button, Dialog, Switch } from "antd-mobile";
import moment from "moment";
import { JobType } from "../../../../shared/SharedTypes";
import mustache from "mustache";
import { Document, Documents, LoadDocument } from "./Documents";
import SignaturePad, {
  SignatureObject,
} from "../../../../shared/components/SignaturePad";
import { useAuth } from "../../../../shared/providers/AuthProvider";
import TinyMarkdown, {
  ParseMarkdown,
} from "../../../../shared/components/TinyMarkdown";
import company from "../../../../Company";
import { LogoHeader, TinyHTML } from "../../../../shared/TinyHTML";

const Tokens = {
  account: ["id", "name", "phone", "address", "address_raw"],
  unit: ["id", "year", "make", "model", "license", "vin", "color"],
  job: ["id", "date", "why", "storage_rate", "qr_code"],
  payment: ["id", "amount", "paid"],
  dates: ["add", "subtract", "today", "job_date"],
  signature: ["signature", "date", "name"],
  company: [
    "name",
    "main_phone",
    "address_line_a",
    "address_line_b",
    "main_email",
  ],
};

const DocumentsGenerator: React.FC = (props: any) => {
  const [pageTitle, setPageTitle] = useState<string>("Documents");
  const { id, action } = useParams();

  const [job, setJob] = useState<IJob>({} as IJob);
  const [account, setAccount] = useState<IAccount>({} as IAccount);
  const [unit, setUnit] = useState<IUnit>({} as IUnit);
  const [payment, setPayment] = useState<IPayment>({} as IPayment);
  const [windowHandler, setWindowHandler] = useState<Window | null>(null);

  const [loading, setLoading] = useState<{
    account: boolean;
    unit: boolean;
    payment: boolean;
    job: boolean;
  }>({
    account: false,
    unit: false,
    payment: false,
    job: false,
  });

  const [raw, setRaw] = useState<string>("");
  const [parsed, setParsed] = useState<string>("");
  const [currentAction, setCurrentAction] = useState<Document>();

  const [signaturePadVisible, setSignaturePadVisible] =
    useState<boolean>(false);
  const [signature, setSignature] = useState<SignatureObject>(
    {} as SignatureObject,
  );

  const [includeHeader, setIncludeHeader] = useState<boolean>(true);
  const [includeQR, setIncludeQR] = useState<boolean>(true);

  const { session } = useAuth();

  useEffect(() => {
    Documents.filter((item) => item.key === action).map((item) => {
      if (item.document) {
        LoadDocument(item.key).then((res) => {
          setRaw(res as string);
        });
      }
      setPageTitle(item.title);
      setCurrentAction(item);
    });
  }, [props]);

  useEffect(() => {
    if (Object.values(loading).every((value) => value === false)) {
      parseTemplate();
    }
  }, [loading]);

  useEffect(() => {
    if (raw) {
      parseTemplate();
    }
  }, [raw, signature]);

  useEffect(() => {
    const _id = getRealId(id);

    const api = API.getInstance();
    setLoading({
      account: true,
      unit: true,
      payment: true,
      job: true,
    });
    api
      .fetchJob(_id)
      .then((res) => {
        setJob(res.data);
        fetchAllEntities(
          res.data.account_id,
          res.data.unit_id,
          res.data.payment_id,
        );
      })
      .catch((e) => {
        toast(e.message, { type: "error" }, e);
      })
      .finally(() => {
        setLoading({ ...loading, job: false });
      });
  }, []);

  useEffect(() => {
    if (account.name) {
      setSignature({ ...signature, fullName: account.name });
    }
  }, [account]);

  const fetchAllEntities = (
    account_id: number,
    unit_id: number,
    payment_id: number,
  ) => {
    let api = API.getInstance();

    api
      .fetchAccount(account_id)
      .then((res) => {
        setAccount(res.data);
      })
      .catch((e) => {
        toast(e.message, { type: "error" }, e);
      })
      .finally(() => {
        setLoading({ ...loading, account: false });
      });

    api
      .fetchUnit(unit_id)
      .then((res) => {
        setUnit(res.data);
      })
      .catch((e) => {
        toast(e.message, { type: "error" }, e);
      })
      .finally(() => {
        setLoading({ ...loading, unit: false });
      });

    api
      .fetchPaymentInfo(payment_id)
      .then((res) => {
        setPayment(res.data);
      })
      .catch((e) => {
        toast(e.message, { type: "error" }, e);
      })
      .finally(() => {
        setLoading({ ...loading, payment: false });
      });
  };

  const parseTemplate = () => {
    if (!raw) return;
    let html = raw;

    html = html.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, "");

    interface AccountTemplateExtras extends IAccount {
      address_raw: string;
    }

    let _account = account as AccountTemplateExtras;
    _account.address_raw = account.address || "";
    _account.address = USPSAddressFormat(account.address || "");

    interface JobTemplateExtras extends IJob {
      todays_date: string;
      storage_rate: string;
      why: string;
      date: string;
      hid: string;
      qr_code: string;
      qr_url: string;
    }

    const url = APP_BASE_URL + `/jobs/${hid(job.id)}`;
    let _job = job as JobTemplateExtras;
    _job.why = JobType[Number(job.reason)];
    _job.todays_date = moment().format("MM/DD/YYYY");
    _job.date = moment(job.scheduled_at).format("MM/DD/YYYY");
    _job.storage_rate = Number(job.reason) === 1 ? "15.00" : "30.00";
    _job.hid = hid(job.id);
    _job.qr_code =
      "<img style='max-width: 400px;' src='" + getQRCodeURL(url) + "' />";
    _job.qr_url = getQRCodeURL(url, 3.5);

    interface PaymentTemplateExtras extends IPayment {
      paid: string;
    }

    let _payment = payment as PaymentTemplateExtras;
    _payment.paid = payment.is_paid ? "Paid" : "Unpaid";

    type SignatureTemplateExtras = Omit<SignatureObject, "date"> & {
      date: string;
      name: string;
    };

    let _signature = {} as SignatureTemplateExtras;

    if (signature.signature) {
      _signature.name = signature.fullName;
      _signature.signature =
        "<img style='max-width: 400px;' src='" + signature.signature + "' />";
      _signature.date = moment(signature.date).format("MM/DD/YYYY");
    }

    try {
      if (!account || !job || !unit || !payment) {
        return;
      }

      let template = mustache.render(html, {
        account: _account,
        unit: unit,
        job: _job,
        payment: _payment,
        signature: _signature,
        company: company,
        dates: {
          today: moment().format("L"),
          job_date: moment(job.scheduled_at).format("L"),
          add: function () {
            return function (text: any, renderer: any) {
              return moment(job.scheduled_at).add(text, "days").format("L");
            };
          },
          subtract: function () {
            return function (text: any, renderer: any) {
              return moment(job.scheduled_at)
                .subtract(text, "days")
                .format("L");
            };
          },
        },
      });

      setParsed(template);
    } catch (e) {
      console.log(`[ERR] `, e);
      // fall silently
    }
  };

  const showPDFPreview = (forcePrint?: boolean) => {
    let url =
      API_BASE_URL +
      `/documents/pdf/${currentAction?.key}/${job.id}?token=${session?.token}`;

    if (forcePrint) {
      window.open(url);
    }
    const y = window.top
      ? window.top.outerHeight / 2 + window.top.screenY - 720 / 2
      : 0;
    const x = window.top
      ? window.top.outerWidth / 2 + window.top.screenX - 1280 / 2
      : 0;

    let wh = window.open(
      url,
      "_blank",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=1280, height=720, top=${y}, left=${x}`,
    );
  };

  const showMarkdownEditor = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: 11 }}>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
            }}
          >
            <div style={{ flex: 1, margin: "5px", marginBottom: "25px" }}>
              <TinyMarkdown
                onChange={(e) => {
                  setRaw(e);
                }}
                value={raw}
                display={parsed}
              />
            </div>
          </div>
        </div>
        {!isMobile() && (
          <div style={{ flex: 1 }}>
            <div style={{ padding: "0 10px" }}>
              {[
                "account",
                "unit",
                "job",
                "payment",
                "dates",
                "signature",
                "company",
              ].map((item, index) => {
                return (
                  <div key={index}>
                    <br />
                    <code>
                      {item.toUpperCase()}: <br />
                      {Tokens[item as keyof typeof Tokens].map(
                        (token, index) => {
                          return (
                            <span key={index}>
                              {`{{`}
                              {item}.{token}
                              {`}}`} <br />
                            </span>
                          );
                        },
                      )}
                    </code>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
  const other = () => {
    return (
      <>
        <iframe
          id="pdf-preview"
          src={
            API_BASE_URL +
            `/documents/pdf/${currentAction?.key}/${job.id}?token=${session?.token}`
          }
          style={{ width: "100%", height: "90vh" }}
        ></iframe>
      </>
    );
  };
  const showPreview = (forcePrint?: boolean) => {
    let contents = "";
    if (windowHandler) {
      windowHandler.close();
    }
    if (currentAction?.key !== "V1" && currentAction?.key !== "826") {
      if (includeHeader) {
        let logo = mustache.render(LogoHeader, {
          company: company,
          job: job,
          qr: includeQR,
        });
        contents = TinyHTML.replace("%s", logo + ParseMarkdown(parsed));
      } else {
        contents = TinyHTML.replace("%s", ParseMarkdown(parsed));
      }

      const y = window.top
        ? window.top.outerHeight / 2 + window.top.screenY - 720 / 2
        : 0;
      const x = window.top
        ? window.top.outerWidth / 2 + window.top.screenX - 1280 / 2
        : 0;
      let windowHandler = window.open(
        "",
        "PrintWindow",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=1280, height=720, top=${y}, left=${x}`,
      );
      if (windowHandler) {
        setWindowHandler(windowHandler);
        windowHandler.document.write(contents);
        if (forcePrint) {
          windowHandler.print();
          windowHandler.close();
        }
      }
    } else {
      showPDFPreview(forcePrint);
    }
  };

  const attach = () => {
    let contents = "";
    if (includeHeader) {
      let logo = mustache.render(LogoHeader, {
        company: company,
        job: job,
        qr: includeQR,
      });
      contents = TinyHTML.replace("%s", logo + ParseMarkdown(parsed));
    } else {
      contents = TinyHTML.replace("%s", ParseMarkdown(parsed));
    }
    if (
      currentAction?.key === "bill-of-sale" ||
      currentAction?.key === "disposal-agreement"
    ) {
      const action =
        currentAction?.key === "bill-of-sale" ? "SOLD" : "SCRAPPED";
      Dialog.confirm({
        title: "Update Vehicle Location",
        content: `Update the vehicle location to ${action}?`,
        confirmText: "YES",
        cancelText: "NO",
      }).then((updateLocation) => {
        let api = API.getInstance();
        setLoading({ ...loading, job: true });
        let title = `${currentAction?.title} - ${moment(
          job.scheduled_at,
        ).format("MM/DD/YYYY")}`;
        api
          .generatePdf(job.id, title, contents)
          .then((res) => {
            if (updateLocation) {
              api
                .patchUnit(job.unit_id, { current_location: action })
                .then((res) => {})
                .catch((e) => {
                  toast(e.message, { type: "error" }, e);
                });
            }
            window.open(res.data.file_url, "_blank");
          })
          .catch((e) => {
            toast(e.message, { type: "error" });
          })
          .finally(() => {
            setLoading({ ...loading, job: false });
          });
      });
    } else {
      let api = API.getInstance();
      setLoading({ ...loading, job: true });
      let title = `${currentAction?.title} - ${moment(job.scheduled_at).format(
        "MM/DD/YYYY",
      )}`;
      api
        .generatePdf(job.id, title, contents)
        .then((res) => {
          window.open(res.data.file_url, "_blank");
        })
        .catch((e) => {
          toast(e.message, { type: "error" });
        })
        .finally(() => {
          setLoading({ ...loading, job: false });
        });
    }
  };
  return (
    <Layout title={pageTitle}>
      {currentAction?.document ? showMarkdownEditor() : other()}
      {currentAction?.hasHeader && (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ flex: 1 }}>Include header?</div>
            <div style={{ flex: 1 }}>
              <Switch checked={includeHeader} onChange={setIncludeHeader} />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
            <div style={{ flex: 1 }}>Include QR Code</div>
            <div style={{ flex: 1 }}>
              <Switch checked={includeQR} onChange={setIncludeQR} />
            </div>
          </div>
        </>
      )}
      {Object.values(loading).some((value) => value === true) ? (
        <OverlayLoading />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          flexDirection: isMobile() ? "column" : "row",
          marginTop: "15px",
          gap: "10px",
        }}
      >
        {currentAction?.key !== "826" && currentAction?.key !== "V1" && (
          <>
            <div style={{ flex: 1, marginRight: !isMobile() ? "5px" : "" }}>
              <Button
                block
                color="primary"
                onClick={() => {
                  showPreview();
                }}
              >
                Preview <EyeOutlined />
              </Button>
            </div>

            <div style={{ flex: 1, marginRight: !isMobile() ? "5px" : "" }}>
              <Button
                block
                color="primary"
                onClick={() => {
                  showPreview(true);
                }}
              >
                Print <PrinterOutlined />
              </Button>
            </div>
            <div style={{ flex: 1, marginRight: !isMobile() ? "5px" : "" }}>
              <Button
                block
                color="default"
                onClick={() => {
                  attach();
                }}
              >
                Attach <UploadOutlined />
              </Button>
            </div>
          </>
        )}

        {currentAction?.signable && (
          <div style={{ flex: 1 }}>
            <Button
              block
              color="danger"
              onClick={(e) => {
                setSignaturePadVisible(true);
              }}
            >
              Sign <EditOutlined />
            </Button>
          </div>
        )}
      </div>
      <SignaturePad
        onClose={() => {
          setSignaturePadVisible(false);
        }}
        visible={signaturePadVisible}
        onSign={(signature) => {
          setSignature(signature);
          setSignaturePadVisible(false);
        }}
        onChange={(signature) => {
          setSignature(signature);
        }}
        signature={signature}
      />
    </Layout>
  );
};

export default DocumentsGenerator;
