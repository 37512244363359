import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  ButtonProps,
  Input,
  InputProps,
  Switch,
  SwitchProps,
} from "antd-mobile";
import AutoComplete, { AutoCompleteProps } from "./AutoComplete";
import Select2, { Option, SelectProps } from "./Select2";
import TinyMarkdown, { TinyMarkdownProps } from "./TinyMarkdown";
import EntitySelector, { EntitySelectorProps } from "./EntitySelector2";

import EntitySelector3, {
  EntitySelector3Props,
  EntitySelectorType,
} from "./EntitySelector3";
import { User } from "../Interfaces";

/**
 * Form is a form component that handles form state and validation.
 */
type FormContextType<T> = {
  data: T;
  setData: React.Dispatch<React.SetStateAction<T>>;
  updateField: (fieldName: string) => any;
  errors: { [key: string]: string };
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  validateField: (name: string, rules: any, value: any) => void;
  hasError: (fieldName: string) => boolean;
  getErrorMessage: (fieldName: string) => string;
};

const FormContext = createContext<FormContextType<any> | undefined>(undefined);

interface FormProps<T> {
  initialData: T;
  onSubmit: (data: T) => void;
  onChanged?: (data: T) => void;
  children: React.ReactNode;
  hideButtons?: boolean;
  ref?: React.Ref<HTMLFormElement>;
  onCancel?: () => void;
  loading?: boolean;
  externalData?: T;
}

export function Form<T extends Record<string, any>>({
  initialData,
  onSubmit,
  onChanged,
  children,
  ref,
  onCancel,
  loading,
  hideButtons,
  externalData,
}: FormProps<T>) {
  const [data, setData] = useState<T>(initialData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [hasFormErrors, setHasFormErrors] = useState<boolean>(true);

  const isValid = Object.keys(errors).length === 0;

  useEffect(() => {
    setHasFormErrors(!isValid);
  }, [errors]);

  const updateField = (fieldName: string) => {
    return data[fieldName];
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  useEffect(() => {
    if (onChanged) {
      onChanged(data);
    }
  }, [data, onChanged]);

  useEffect(() => {
    if (externalData) {
      setData((prevState) => ({ ...prevState, ...externalData }));
    }
  }, [externalData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isValid) {
      onSubmit(data);
    }
  };

  const validateField = (name: string, rules: any, value: any) => {
    const validationError = validate(rules, value);

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (validationError) {
        updatedErrors[name] = validationError;
      } else {
        delete updatedErrors[name];
      }
      return updatedErrors;
    });
  };

  const hasError = (fieldName: string): boolean => {
    return Boolean(errors[fieldName]);
  };

  const getErrorMessage = (fieldName: string): string => {
    return errors[fieldName] || "";
  };

  const contextValue: FormContextType<T> = {
    data,
    updateField,
    setData,
    errors,
    setErrors,
    validateField,
    hasError,
    getErrorMessage,
  };

  return (
    <FormContext.Provider value={contextValue}>
      <form onSubmit={handleSubmit} className={"form"} ref={ref}>
        {children}
        {!hideButtons && (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button block type="button" onClick={onCancel} disabled={loading}>
              Cancel
            </Button>
            <Button
              disabled={hasFormErrors}
              block
              type="submit"
              color="primary"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        )}
      </form>
    </FormContext.Provider>
  );
}

export function useFormContext<T>(): FormContextType<T> {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
}

type ValidatorFunction = (value: any) => string | undefined;
/**
 * Simple Validation Function Helpers
 */
type ValidationType = {
  required?: boolean;
  min?: number;
  max?: number;
  pattern?: RegExp;
  validate?: ValidatorFunction;
};

const validate = (config: ValidationType, value: any): string => {
  if (!value) value = "";
  if (config.required && value === "") {
    return "This field is required";
  }

  if (config.min !== undefined && value.length < config.min) {
    // If the string is empty don't show a min length error
    if (value.length === 0) return "";
    return `Minimum length is ${config.min} characters`;
  }

  if (config.max !== undefined && value.length > config.max) {
    // If there's no value don't show a max length error
    if (value.length === 0) return "";
    return `Maximum length is ${config.max} characters`;
  }

  if (config.pattern && !config.pattern.test(value)) {
    // If there's no value don't show a pattern error
    if (value.length === 0) return "";
    return "Invalid format";
  }

  if (config.validate) {
    const customError = config.validate(value);
    if (customError) {
      return customError;
    }
  }

  return "";
};

interface ErrorProps {
  errors: Record<string, string>;
  name?: string;
}

interface FormCommonProps {
  name: string;
  transform?: (value: any) => any;
  rules?: ValidationType;
  uppercase?: boolean;
}

/**
 * FormInput is a wrapper around the Input component that handles form state and validation.
 */
interface FormInputProps extends Omit<InputProps, "name">, FormCommonProps {}

export const FormInput: React.FC<FormInputProps> = (props) => {
  const { validateField, hasError, getErrorMessage, updateField, setData } =
    useFormContext();

  const [value, setValue] = useState<string>(updateField(props.name));

  const transformValue = (str: string | undefined) => {
    if (!str) return str;
    let result = props.transform ? props.transform(value) : value;
    if (props.uppercase) {
      result = result.toUpperCase();
    }
    return result;
  };

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, transformValue(value));
  }, [value]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, transformValue(value));
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      const transformedValue = props.uppercase
        ? (props.transform ? props.transform(value) : value).toUpperCase()
        : props.transform
        ? props.transform(value)
        : value;

      setData((prevData: any) => ({
        ...prevData,
        [props.name]: transformedValue,
      }));
      setValue(transformedValue);
      props.onChange && props.onChange(transformedValue);
    },
    [props.transform, props.name, setData],
  );

  return (
    <div
      className={`${hasError(props.name) ? "has-errors" : ""}`}
      style={props.style}
    >
      <Input
        {...props}
        onChange={handleChange}
        value={transformValue(value) || ""}
      />
      {hasError(props.name) && (
        <span className="form-error-message shown">
          {getErrorMessage(props.name)}
        </span>
      )}
    </div>
  );
};

/**
 * FormAutoComplete is a wrapper around the AutoComplete component that handles form state and validation.
 */
interface FormAutoCompleteProps extends AutoCompleteProps, FormCommonProps {
  type?: string;
}

export const FormAutoComplete: React.FC<FormAutoCompleteProps> = (props) => {
  const { validateField, hasError, getErrorMessage, updateField, setData } =
    useFormContext();

  const [value, setValue] = useState<string>(updateField(props.name));

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, [value]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      const transformedValue = props.uppercase
        ? (props.transform ? props.transform(value) : value).toUpperCase()
        : props.transform
        ? props.transform(value)
        : value;

      setData((prevData: any) => ({
        ...prevData,
        [props.name]: transformedValue,
      }));
      setValue(transformedValue);
      props.onChange && props.onChange(transformedValue);
    },
    [props.transform, props.name, setData],
  );

  return (
    <div
      className={`${hasError(props.name) ? "has-errors" : ""}`}
      style={props.style}
    >
      <AutoComplete
        {...props}
        value={value || ""}
        onChange={handleChange}
        onSelect={handleChange}
        inputType={props.type}
      />

      <span
        className={`form-error-message ${hasError(props.name) ? "shown" : ""}`}
      >
        {getErrorMessage(props.name)}
      </span>
    </div>
  );
};

/**
 * FormSwitch is a wrapper around the Switch component that handles form state and validation.
 */
interface FormSwitchProps extends SwitchProps, FormCommonProps {
  value?: boolean;
  label?: string;
}
export const FormSwitch: React.FC<FormSwitchProps> = (props) => {
  const { setData, updateField } = useFormContext();

  const [value, setValue] = useState<boolean>(updateField(props.name));

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  const handleChange = useCallback(
    (value: any) => {
      setData((prevData: any) => ({
        ...prevData,
        [props.name]: value,
      }));
      setValue(value);
      props.onChange && props.onChange(value);
    },
    [props.transform, props.name, setData],
  );

  return (
    <div style={props.style}>
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1 }}>
          <p
            onClick={() => {
              handleChange(!value);
            }}
            className={"hoverable"}
            style={{ fontWeight: "bold" }}
          >
            {props.label}
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <Switch {...props} onChange={handleChange} checked={value} />
        </div>
      </div>
    </div>
  );
};

type FormSelectProps = SelectProps & FormCommonProps;

export const FormSelect: React.FC<FormSelectProps> = (props) => {
  const { validateField, hasError, getErrorMessage, updateField, setData } =
    useFormContext();

  const [value, setValue] = useState<Option | (number & Option) | undefined>(
    updateField(props.name),
  );

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, [value]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      const transformedValue = props.uppercase
        ? (props.transform ? props.transform(value) : value).toUpperCase()
        : props.transform
        ? props.transform(value)
        : value;

      setData((prevData: any) => ({
        ...prevData,
        [props.name]: transformedValue,
      }));
      setValue(transformedValue);
    },
    [props.transform, props.name, setData],
  );

  return (
    <div
      className={`${hasError(props.name) ? "has-errors" : ""}`}
      style={props.style}
    >
      <Select2
        {...props}
        value={value}
        onChange={(value: any) => {
          handleChange(value);
          props.onChange && props.onChange(value);
        }}
      />

      <span
        className={`form-error-message ${hasError(props.name) ? "shown" : ""}`}
      >
        {getErrorMessage(props.name)}
      </span>
    </div>
  );
};

/**
 * FormTextarea is a wrapper around the TinyMarkdown component that handles form state and validation.
 */
interface FormTextAreaProps extends TinyMarkdownProps, FormCommonProps {
  style?: any;
}

export const FormTextArea: React.FC<FormTextAreaProps> = (props) => {
  const { validateField, hasError, getErrorMessage, updateField, setData } =
    useFormContext();

  const [value, setValue] = useState<string | undefined>(
    updateField(props.name),
  );

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, [value]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      const transformedValue = props.uppercase
        ? (props.transform ? props.transform(value) : value).toUpperCase()
        : props.transform
        ? props.transform(value)
        : value;

      setData((prevData: any) => ({
        ...prevData,
        [props.name]: transformedValue,
      }));
      setValue(transformedValue);
      props.onChange && props.onChange(transformedValue);
    },
    [props.transform, props.name, setData],
  );

  return (
    <div
      className={`${hasError(props.name) ? "has-errors" : ""}`}
      style={props.style}
    >
      <TinyMarkdown {...props} onChange={handleChange} value={value} />
      {hasError(props.name) && (
        <span className="form-error-message shown">
          {getErrorMessage(props.name)}
        </span>
      )}
    </div>
  );
};

/**
 * FormEntitySelector is a wrapper around the EntitySelector component that handles form state and validation.
 */

interface FormEntitySelectorProps extends EntitySelectorProps, FormCommonProps {
  onChange?: (value: any) => void;
  style?: any;
}
export const FormEntitySelector: React.FC<FormEntitySelectorProps> = (
  props,
) => {
  const { validateField, hasError, getErrorMessage, updateField, setData } =
    useFormContext();

  const [value, setValue] = useState<string | null>(updateField(props.name));

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, [value]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      if (value === null) {
        value = {
          id: 0,
        };
      }
      setData((prevData: any) => ({
        ...prevData,
        [props.name]: value.id,
        [props.name + "_data"]: value,
        [props.name + "_name"]: value.name,
      }));

      setValue(value.id);

      props.onChange && props.onChange(value.id);
      console.log("triggered");
    },
    [props.transform, props.name, setData],
  );

  return (
    <div
      className={`${hasError(props.name) ? "has-errors" : ""}`}
      style={props.style}
    >
      <EntitySelector
        {...props}
        displayValue={props.displayValue}
        onSelected={handleChange}
        onCleared={() => {
          handleChange(null);
        }}
      />
      {hasError(props.name) && (
        <span className="form-error-message shown">
          {getErrorMessage(props.name)}
        </span>
      )}
    </div>
  );
};

/**
 * Form Button adds a way to manage state within the form itself if there's a need for buttons
 */

interface FormButtonProps extends ButtonProps {
  name: string; // The name of the form field you want to update
  value: any; // The value you want to set when the button is clicked
  onChange?: (value: any) => void;
}

export const FormButton: React.FC<FormButtonProps> = (props) => {
  const { setData } = useFormContext();

  const handleChange = () => {
    setData((prevData: any) => ({
      ...prevData,
      [props.name]: props.value,
    }));
    props.onChange && props.onChange(props.value);
  };

  return (
    <div style={props.style}>
      <Button {...props} onClick={handleChange} />
    </div>
  );
};

interface FormEntitySelectorProps3
  extends EntitySelector3Props,
    FormCommonProps {
  style?: any;
}

export const FormEntitySelector3: React.FC<FormEntitySelectorProps3> = (
  props,
) => {
  const {
    validateField,
    hasError,
    getErrorMessage,
    updateField,
    data,
    setData,
  } = useFormContext();

  const [value, setValue] = useState<EntitySelectorType>(
    updateField(props.name),
  );

  const [mockValue, setMockValue] = useState<User>({ id: 0 } as User);

  useEffect(() => {
    console.log(props.name, value);
  }, []);

  useEffect(() => {
    setValue(updateField(props.name));
  }, [updateField, props.name]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
    if (value)
      setMockValue({ ...mockValue, id: value.id, name: value.name || "" });
  }, [value]);

  useEffect(() => {
    validateField(props.name, props.rules || {}, value);
  }, []);

  const handleChange = useCallback(
    (value: any) => {
      setData((prevData: any) => {
        return {
          ...prevData,
          [props.name]: value ? value.id : 0,
          [props.name + "_name"]: value ? value.name : 0,
        };
      });

      setValue(value);
    },
    [props.transform, props.name, setData],
  );

  return (
    <div
      className={`${hasError(props.name) ? "has-errors" : ""}`}
      style={props.style}
    >
      <EntitySelector3
        {...props}
        value={
          {
            id: value || 0,
            name: (data as any)[props.name + "_name"],
          } as User
        }
        onChange={(value: EntitySelectorType) => {
          handleChange(value);
          props.onChange && props.onChange(value);
        }}
      />
      <span
        className={`form-error-message ${hasError(props.name) ? "shown" : ""}`}
      >
        {getErrorMessage(props.name)}
      </span>
    </div>
  );
};

Form.Input = FormInput;
Form.AutoComplete = FormAutoComplete;
Form.Switch = FormSwitch;
Form.Select = FormSelect;
Form.TextArea = FormTextArea;
Form.EntitySelector3 = FormEntitySelector3;
Form.Button = FormButton;

export default Form;
