import {
    AlertOutlined, CarOutlined,
    ClockCircleOutlined,
    FileDoneOutlined,
    FileExclamationOutlined, HighlightOutlined, KeyOutlined,
    LockOutlined,
    WarningOutlined
} from "@ant-design/icons";

const NotificationOfImpoundTemplate = require("../../../../shared/document-templates/NotificationOfImpound.md");
const IntentToSellTemplate = require("../../../../shared/document-templates/IntentToSell.md");
const BillOfSaleTemplate = require("../../../../shared/document-templates/BillOfSale.md");
const BillOfLadingTemplate = require("../../../../shared/document-templates/BillOfLading.md");
const DamageWaiver = require("../../../../shared/document-templates/DamageWaiver.md");
const DisposalAgreement = require("../../../../shared/document-templates/DisposalAgreement.md");
const VehicleRelease = require("../../../../shared/document-templates/VehicleRelease.md");


export interface Document {
    key: string;
    title: string;
    icon: any;
    color: string;
    signable: boolean;
    hasHeader: boolean;
    document: string | boolean;
}


export const Documents: Document[] = [
    {
        key: "notification-of-impound",
        title: "Notification of Impound",
        icon: <ClockCircleOutlined/>,
        color: "default",
        signable: false,
        hasHeader: true,
        document: NotificationOfImpoundTemplate
    },
    {
        key: "intent-to-sell",
        title: "Intent to Sell",
        icon: <WarningOutlined/>,
        color: "default",
        signable: false,
        hasHeader: true,
        document: IntentToSellTemplate
    },
    {
        key:"826",
        title: "Claim of Ownership - 826",
        icon: <LockOutlined/>,
        color: "default",
        signable: false,
        hasHeader: false,
        document: false
    },
    {
        key:"damage-waiver",
        title: "Damage Waiver",
        icon: <AlertOutlined/>,
        color: "danger",
        signable: true,
        hasHeader: true,
        document: DamageWaiver
    },
    {
        key: "bill-of-sale",
        title: "Bill of Sale",
        icon: <FileDoneOutlined/>,
        color: "default",
        signable: true,
        hasHeader: true,
        document: BillOfSaleTemplate
    },
    {
        key: "bill-of-lading",
        title: "Bill of Lading",
        icon: <FileExclamationOutlined/>,
        color: "default",
        signable: true,
        hasHeader: true,
        document: BillOfLadingTemplate
    },
    {
        key: "V1",
        title: "Vehicle Transaction Application - V1",
        icon: <HighlightOutlined/>,
        color: "default",
        signable: false,
        hasHeader: false,
        document: false
    },
    {
        key: "disposal-agreement",
        title: "Disposal Agreement",
        icon: <CarOutlined/>,
        color: "default",
        signable: true,
        hasHeader: true,
        document: DisposalAgreement
    },
    {
        key: "vehicle-release",
        title: "Vehicle Release",
        icon: <KeyOutlined />,
        color: "default",
        signable: true,
        hasHeader: true,
        document: VehicleRelease
    }
];


export const LoadDocument = async (key: string): Promise<string | null> => {
    const document = Documents.find((d) => d.key === key);

    if (!document) {
        return null; // or throw an error if a missing document is considered exceptional
    }

    try {
        const res = await fetch(document.document as string);
        if (!res.ok) {
            throw new Error('Network response was not ok');
        }
        return await res.text();
    } catch (error) {
        console.error('Fetch Error:', error);
        return null; // or re-throw the error if you want to handle it elsewhere
    }
};
