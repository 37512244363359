import { BrowserRouter, Link, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./providers/AuthProvider";
import LoginPage from "./../pages/public/LoginPage";
import SignupPage from "./../pages/public/SignupPage";
import ForgotPage from "./../pages/public/ForgotPage";
import Listing from "../pages/protected/Listing";
import Edit from "../pages/protected/Edit";
import JobListing from "../pages/protected/components/jobs/JobListing";
import JobView from "../pages/protected/components/jobs/JobView";
import { Result } from "antd-mobile";
import Search from "../pages/protected/Search";
import HomePage from "../pages/protected/HomePage";
import DocumentsGenerator from "../pages/protected/components/documents/DocumentsGenerator";
import Reports from "../pages/protected/Reports";
import JobCreate from "../pages/protected/components/jobs/JobCreate";
import Create from "../pages/protected/Create";
import JobQuickCreate from "../pages/protected/components/jobs/JobQuickCreate";
import Settings from "../pages/protected/Settings";
import EntityListing from "../pages/protected/components/jobs/listing/EntityListing";
import { OverlayLoading } from "./components/Loading";
import StepAccount from "../pages/protected/components/jobs/create/StepAccount";
import StepUnit from "../pages/protected/components/jobs/create/StepUnit";
import StepPayment from "../pages/protected/components/jobs/create/StepPayment";
import StepJob from "../pages/protected/components/jobs/create/StepJob";
import StepDispatch from "../pages/protected/components/jobs/create/StepDispatch";

const NotFound = () => (
  <div>
    <Result
      style={{ height: "100%" }}
      title={"404"}
      description="Page not found"
      status="error"
    />
    <div style={{ textAlign: "center", marginTop: "25px" }}>
      <Link style={{ textAlign: "center" }} to={"/"}>
        Go back home
      </Link>
    </div>
  </div>
);

export default function Navigator() {
  const { session, isLoading } = useAuth();
  if (isLoading) return <OverlayLoading />;
  return (
    <BrowserRouter>
      <Routes>
        {session !== null && (
          <>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/accounts/" element={<Listing type="Account" />} />
            <Route
              path="/accounts/:id/edit"
              element={<Edit type="Account" />}
            />
            <Route
              path="/accounts/:id/jobs"
              element={<EntityListing type="Account" />}
            />
            <Route path="/units/" element={<Listing type="Unit" />} />
            <Route
              path="/units/:id/jobs"
              element={<EntityListing type="Unit" />}
            />
            <Route path="/units/:id/edit" element={<Edit type="Unit" />} />
            <Route path="/fleetunits/" element={<Listing type="FleetUnit" />} />
            <Route
              path="/fleetunits/:id/edit"
              element={<Edit type="FleetUnit" />}
            />
            <Route
              path="/fleetunits/create"
              element={<Create type="FleetUnit" />}
            />
            <Route path="/users/" element={<Listing type="User" />} />
            <Route path="/users/create" element={<Create type="User" />} />
            <Route
              path="/users/:id/jobs"
              element={<EntityListing type="User" />}
            />
            <Route path="/users/:id/edit" element={<Edit type="User" />} />
            <Route path="/impounds/" element={<Listing type="Impound" />} />
            <Route
              path="/impounds/:id/edit"
              element={<Edit type="Impound" />}
            />
            <Route
              path="/impounds/create"
              element={<Create type="Impound" />}
            />
            <Route path="/jobs/" element={<JobListing type="all" />} />
            <Route path="/jobs/my" element={<JobListing type="my" />} />
            <Route
              path="/jobs/scheduled"
              element={<JobListing type="scheduled" />}
            />
            <Route path="/jobs/public" element={<JobListing type="public" />} />
            <Route path="/jobs/quick-create" element={<JobQuickCreate />} />
            <Route path="/jobs/create" element={<JobCreate />}>
              <Route index element={<Navigate to="step-account" replace />} />
              <Route path="step-account" element={<StepAccount />} />
              <Route path="step-unit" element={<StepUnit />} />
              <Route path="step-payment" element={<StepPayment />} />
              <Route path="step-job" element={<StepJob />} />
              <Route path="step-dispatch" element={<StepDispatch />} />
            </Route>
            <Route path="/jobs/:id" element={<JobView />} />
            <Route path="/jobs/:id/edit" element={<Edit type="Job" />} />
            <Route
              path="/documents/:id/:action"
              element={<DocumentsGenerator />}
            />
            <Route path="/reports" element={<Reports />} />
            <Route
              path="/payments/:id/edit"
              element={<Edit type="Payment" />}
            ></Route>
            <Route path="/search" element={<Search />} />
            <Route path="/settings" element={<Settings />} />

            <Route path="/login" element={<Navigate replace to="/" />} />
            <Route path="/signup" element={<Navigate replace to="/" />} />
            <Route path="/forgot" element={<Navigate replace to="/" />} />
            <Route path="*" element={<NotFound />} />
          </>
        )}
        {session == null && (
          <>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/sign-up" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPage />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}
