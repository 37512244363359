import React, { useEffect, useState } from "react";
import { AuthPerson, Impound as IImpound } from "../../../../shared/Interfaces";

import { Button, Divider, Input } from "antd-mobile";
import { PhoneMask } from "../../../../shared/InputMask";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { COMMON_EMAIL_PROVIDERS } from "../../../../shared/SharedTypes";
import { generateAutoCompleteEmailList } from "../../../../shared/SharedUtils";
import Form from "../../../../shared/components/Form2";

import { isMobile } from "../../../../shared/SharedUtils";

interface FormImpoundProps {
  loading: boolean;
  impound: IImpound;
  onChange: (impound: IImpound) => void;
  onCancel: () => void;
  onSave: (impound: IImpound) => void;
}

interface AddressEditableProps {
  value: string;
  index: number;
  onChange: (e: any, index: number) => void;
  onDelete: (index: number) => void;
}

const AddressEditable = (props: AddressEditableProps) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1 }}>
        <Input
          placeholder="Address"
          value={props.value}
          onChange={(e) => props.onChange(e, props.index)}
        />
      </div>
      <div style={{ marginLeft: "auto", marginTop: "15px", flex: 0 }}>
        <Button
          style={{}}
          fill="none"
          size="small"
          onClick={() => props.onDelete(props.index)}
        >
          <CloseOutlined />
        </Button>
      </div>
    </div>
  );
};

interface AuthPersonEditableProps {
  value: AuthPerson;
  index: number;
  onChange: (e: any, index: number) => void;
  onDelete: (index: number) => void;
}

const AuthorizedEditable = (props: AuthPersonEditableProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile() ? "column" : "row",
        gap: "5px",
      }}
    >
      <div style={{ flex: 1 }}>
        <Input
          placeholder="Name"
          value={props.value.name}
          onChange={(e) =>
            props.onChange({ label: "name", value: e }, props.index)
          }
        />
      </div>
      <div style={{ flex: 1 }}>
        <Input
          placeholder="E-mail"
          value={props.value.email}
          onChange={(e) =>
            props.onChange({ label: "email", value: e }, props.index)
          }
          type={"email"}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Input
          placeholder="Phone"
          value={props.value.phone}
          onChange={(e) =>
            props.onChange({ label: "phone", value: e }, props.index)
          }
          type={"tel"}
        />
      </div>
      <div style={{ marginLeft: "auto", marginTop: "15px", flex: 0 }}>
        <Button
          style={{}}
          fill="none"
          size="small"
          onClick={() => props.onDelete(props.index)}
        >
          <CloseOutlined />
        </Button>
      </div>
    </div>
  );
};

const FormImpound: React.FC<FormImpoundProps> = (props: FormImpoundProps) => {
  const [impound, setImpound] = useState<IImpound>({} as IImpound);
  const [pickerVisible, setPickerVisible] = useState<boolean>(false);

  const [authorized, setAuthorized] = React.useState<AuthPerson[]>([]);
  const [addresses, setAddresses] = React.useState<string[]>([]);

  const [emailAutoComplete, setEmailAutoComplete] = useState(
    COMMON_EMAIL_PROVIDERS,
  );

  useEffect(() => {
    setAuthorized(props.impound.authorized || []);
    setAddresses(props.impound.addresses || []);
    setImpound(props.impound);
  }, [props.impound]);

  const handleSave = (impound: IImpound) => {
    impound.addresses = addresses;
    impound.authorized = authorized;
    props.onSave && props.onSave(impound);
  };

  return (
    <Form
      initialData={impound}
      onSubmit={handleSave}
      loading={props.loading}
      onCancel={props.onCancel}
    >
      <Form.Input placeholder="Name" name="name" />
      <Form.Input
        placeholder="Phone"
        name="phone"
        rules={{
          min: 12,
          max: 12,
          required: true,
        }}
        transform={PhoneMask}
        type={"tel"}
      />
      <Form.AutoComplete
        placeholder="E-mail"
        name="email"
        minLength={4}
        options={emailAutoComplete}
        onChange={(e) => {
          setEmailAutoComplete(generateAutoCompleteEmailList(e));
        }}
        type={"email"}
      />
      <Form.Input placeholder="Address" name="address" />
      <Form.TextArea label="Notes" name="meta" />
      <Divider
        style={{
          color: "#fb8501",
          borderColor: "#fb8501",
          borderStyle: "dashed",
        }}
      >
        Addresses{" "}
        <Button
          onClick={() => {
            addresses.push("");
            setAddresses([...addresses]);
          }}
          fill="none"
        >
          <PlusOutlined />
        </Button>
      </Divider>
      {addresses.map((address, index) => {
        return (
          <AddressEditable
            key={index}
            index={index}
            value={address}
            onChange={(e, index) => {
              addresses[index] = e;
              setAddresses([...addresses]);
              //handleChange(addresses, "addresses");
            }}
            onDelete={(index: number) => {
              addresses.splice(index, 1);
              setAddresses([...addresses]);
              //handleChange(addresses, "addresses");
            }}
          />
        );
      })}
      <Divider
        style={{
          color: "#fb8501",
          borderColor: "#fb8501",
          borderStyle: "dashed",
        }}
      >
        Authorized{" "}
        <Button
          onClick={() => {
            authorized.push({ name: "", phone: "", email: "" });
            setAuthorized([...authorized]);
          }}
          fill="none"
        >
          <PlusOutlined />
        </Button>
      </Divider>
      {authorized.map((auth, index) => {
        return (
          <AuthorizedEditable
            key={index}
            value={auth}
            index={index}
            onChange={(e, index) => {
              let value = e.label === "phone" ? PhoneMask(e.value) : e.value;
              authorized[index] = { ...authorized[index], [e.label]: value };
              setAuthorized([...authorized]);
            }}
            onDelete={(index: number) => {
              authorized.splice(index, 1);
              setAuthorized([...authorized]);
            }}
          />
        );
      })}
    </Form>
  );
};

export default FormImpound;
